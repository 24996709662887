import React from 'react';
import { useTranslation } from 'react-i18next';

import { QAContainer, TitleBlock, Seperator, SubTitleBlock } from './styles';
import { UserElement, RouteSwitch, SegmentName, UserResponse } from '../../types/segment';
import { TextIfExists } from '../TextIfExists';

import {
    setRaceResponseText,
    setMultiResponseRaceResponseText,
} from '../../helpers/response-button-helpers';

interface IProps {
    userElement: UserElement;
    segmentName: SegmentName;
    routeSwitch?: RouteSwitch | null;
    seperator: boolean;
}

export const RaceResponsesBlock: React.FC<IProps> = ({
    segmentName,
    userElement,
    routeSwitch,
    seperator,
}) => {
    const { t } = useTranslation();
    const routeSwitchPath = routeSwitch ?? null;

    const routeTranslationKey = routeSwitch ? `.${routeSwitch}` : '';

    const responsesByQuestion: UserResponse[][] = [];
    userElement.responses?.forEach((response) => {
        if (
            responsesByQuestion[responsesByQuestion.length - 1] &&
            responsesByQuestion[responsesByQuestion.length - 1][0].questionNumber ===
                response.questionNumber
        ) {
            responsesByQuestion[responsesByQuestion.length - 1].push(response);
        } else {
            responsesByQuestion.push([response]);
        }
    });

    return (
        <>
            <QAContainer>
                <TitleBlock>
                    {t(`icc.${segmentName}.${userElement.type}${routeTranslationKey}.title`)}
                </TitleBlock>
                <SubTitleBlock>{t(`icc.${segmentName}.${userElement.type}.text`)}</SubTitleBlock>
                <SubTitleBlock>
                    {t(`sessionRecaps.Marks.answer`)}
                    {responsesByQuestion.map((userResponses) => {
                        const responseText =
                            userResponses.length > 1
                                ? setMultiResponseRaceResponseText(
                                      userResponses,
                                      userElement.type,
                                      segmentName,
                                      userResponses[0].questionNumber,
                                      t,
                                      routeSwitchPath,
                                  )
                                : setRaceResponseText(
                                      userResponses[0],
                                      userElement.type,
                                      segmentName,
                                      userResponses[0].questionNumber,
                                      t,
                                      routeSwitchPath,
                                  );
                        return (
                            <>
                                <TextIfExists
                                    textString={`icc.${segmentName}.${userElement.type}${routeTranslationKey}.question${userResponses[0].questionNumber}.questionStart`}
                                    qBlock={false}
                                    noStyling={true}
                                />
                                {responseText}
                                <TextIfExists
                                    textString={`icc.${segmentName}.${userElement.type}${routeTranslationKey}.question${userResponses[0].questionNumber}.questionEnd`}
                                    qBlock={false}
                                    noStyling={true}
                                />
                            </>
                        );
                    })}
                </SubTitleBlock>
            </QAContainer>
            {seperator && <Seperator />}
        </>
    );
};
