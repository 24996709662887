import styled from 'styled-components';

import { colours } from '../../../constants/colours';
import { modalShadow } from '../../../styles/common';

export const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${colours.modalBackground};
`;

export const Modal = styled.div`
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-height: 100%;
    background: ${colours.white};
    box-shadow: ${modalShadow};
    border-radius: 10px;
`;

export const CloseButton = styled.div`
    margin-right: 2rem;
    align-self: flex-end;
    cursor: pointer;
`;
