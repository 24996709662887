import React from 'react';

import { SessionInfoContainer, SessionKey, SessionValue } from './styles';
import { colours } from '../../constants/colours';

export interface IProps {
    sessionKey: string;
    value: string;
    previousVersion?: boolean;
}

const SessionInfo: React.FC<IProps> = (props: IProps) => {
    const { sessionKey, value, previousVersion } = props;

    return (
        <SessionInfoContainer>
            <SessionKey>{sessionKey}</SessionKey>
            <SessionValue style={{ color: previousVersion ? colours.textGrey : colours.black }}>
                {value}
            </SessionValue>
        </SessionInfoContainer>
    );
};

export default SessionInfo;
