import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ProfileContainer,
    ProfileFirstBlock,
    ProfileTitle,
    ProfileDetailsContainer,
} from './styles';
import ProfileDetail from 'components/ProfileDetail';
import { IntervenantDetailsContext } from 'icc/constants/context';
import { authenticationService } from 'helpers/authenticationService';
import ROLES from 'constants/roles';

interface IProps {
    children?: JSX.Element;
}

export const IntervenantProfile: React.FC<IProps> = ({ children }) => {
    const { t } = useTranslation();

    const authUser = authenticationService.currentUserValue;

    return (
        <IntervenantDetailsContext.Consumer>
            {(intervenant) => (
                <ProfileContainer>
                    <ProfileFirstBlock>
                        <ProfileTitle>{t('pages.ICC.IntervenantProfile.title')}</ProfileTitle>
                    </ProfileFirstBlock>
                    <ProfileDetailsContainer>
                        <ProfileDetail
                            title={t('pages.UserDetail.firstName')}
                            value={intervenant?.firstName}
                        />
                        <ProfileDetail
                            title={t('pages.UserDetail.lastName')}
                            value={intervenant?.lastName}
                        />
                        {authUser?.role === ROLES.SUPER_ADMIN ? (
                            <ProfileDetail
                                title={t('global.admin')}
                                value={`${intervenant?.admin?.firstName} ${intervenant?.admin?.lastName}`}
                            />
                        ) : (
                            <ProfileDetail title={''} value={''} />
                        )}
                        <ProfileDetail
                            title={t('pages.UserDetail.email')}
                            value={intervenant?.email}
                        />
                        <ProfileDetail
                            title={t('pages.UserDetail.phone')}
                            value={intervenant?.phone}
                        />
                    </ProfileDetailsContainer>
                    {children}
                </ProfileContainer>
            )}
        </IntervenantDetailsContext.Consumer>
    );
};
