import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Table } from '@osedea/reactor';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';

import { RootState } from 'store';
import { ROUTES } from 'constants/routes';
import { User } from 'icc/types/user';
import { authenticationService } from 'helpers/authenticationService';
import { ERoleType } from 'types';

interface IProps {}

export const IntervenantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const authUser = authenticationService.currentUserValue;

    const intervenants = useSelector((state: RootState) => state.iccApp.intervenants.intervenants);

    const headers: Header[] = React.useMemo(() => {
        if (authUser?.role === ERoleType.SUPER_ADMIN) {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                },
                {
                    id: 'adminName',
                    label: t('global.admin'),
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    id: 'extension',
                    label: t('global.extension'),
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        } else {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    id: 'extension',
                    label: t('global.extension'),
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        }
    }, [t, authUser]);

    const navigateToUserDetails = useCallback(
        (user: User) => {
            history.push(ROUTES.ICC.INTERVENANT_DETAILS, {
                intervenantId: user.id,
            });
        },
        [history],
    );

    const rows: Row[] = useMemo(() => {
        return intervenants.map((intervenant) => {
            return {
                id: intervenant.id,
                name: `${intervenant.firstName} ${intervenant.lastName}`,
                adminName: `${intervenant.admin?.firstName} ${intervenant.admin?.lastName}`,
                email: intervenant.email,
                phone: intervenant.phone ?? '',
                extension: intervenant.extension ?? '',
                controls: (
                    <span>
                        <Button onClick={() => navigateToUserDetails(intervenant)}>
                            {t('components.ParticipantTable.detail')}
                        </Button>
                    </span>
                ),
            };
        });
    }, [intervenants, navigateToUserDetails, t]);

    return <Table headers={headers} rows={rows} emptyCellValue={'N/A'} />;
};
