import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

import { colours } from '../../constants/colours';

export const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor: colours.global.lightGrey,
});

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    margin: 2rem auto;
`;

export const FormLogin = styled.form({
    textAlign: 'center',
});

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 31.25rem;
    min-width: 22rem;
    > * {
        margin-bottom: 1.25rem;
    }
`;

export const Title = styled.h2``;

export const Text = styled.h5``;

export const TextLink = styled.a({
    color: `${colours.black}`,
    fontSize: '.8rem',
});
interface LogoProps {
    alt: string;
    src: string;
}

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 30px;
    margin-top: 30px;
    object-fit: cover;
    alt: ${(props: LogoProps) => props.alt};
    src: ${(props: LogoProps) => props.src};
`;

export const forgotPasswordButtonStyles = {
    color: colours.black,
    textDecoration: 'underline',
};
