import React, { useState, FormEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, Button, LoadingDots } from '@osedea/reactor';

import { useDispatch, useSelector } from 'react-redux';

import { CenteredContainer } from '../../styles/common';
import { Card, Title, FormResetPassword, Logo } from './styles';

import ChumLogo from '../../assets/logo_crchum.jpg';
import { verifyResetToken, resetPassword } from '../../slices/resetPasswordSlice';
import { useQuery } from 'helpers/hooks';
import { RootState } from 'store';
import PasswordRegex from 'constants/regex';

interface IProps {}

export const ResetPassword = withRouter((props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tokenParam = useQuery().get('token');
    const resetTokenState = useSelector(
        (state: RootState) => state.resetPasswordSlice.resetTokenState,
    );
    const resetPasswordState = useSelector(
        (state: RootState) => state.resetPasswordSlice.resetPasswordState,
    );

    useEffect(() => {
        if (tokenParam) {
            dispatch(
                verifyResetToken({
                    token: tokenParam,
                    onFailure() {
                        setShouldRedirect(true);
                    },
                }),
            );
        } else {
            setShouldRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [validPassword, setValidPassword] = useState(false);

    useEffect(() => {
        if (
            password &&
            confirmPassword &&
            PasswordRegex.test(password) &&
            password === confirmPassword
        ) {
            setValidPassword(true);
        } else {
            setValidPassword(false);
        }
    }, [password, confirmPassword]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
    };

    const handleResetPassword = () => {
        if (tokenParam) {
            dispatch(
                resetPassword({
                    token: tokenParam,
                    password,
                    onSuccess() {
                        setShouldRedirect(true);
                    },
                }),
            );
        } else {
            setShouldRedirect(true);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('pages.ResetPassword.title')}</title>
            </Helmet>
            <CenteredContainer>
                {shouldRedirect && <Redirect to="/login" />}
                <Logo alt="champs logo" src={ChumLogo} />
                {resetTokenState.loading ? (
                    <LoadingDots />
                ) : (
                    <FormResetPassword onSubmit={handleSubmit}>
                        <Title>{t('pages.ResetPassword.header')}</Title>
                        <Card>
                            <TextField
                                name="password"
                                type="password"
                                label={t('pages.ResetPassword.newPassword')}
                                variant="outlined"
                                onChange={(e: React.FormEvent) =>
                                    setPassword((e.target as HTMLInputElement).value)
                                }
                                value={password}
                            />
                            <TextField
                                name="confirmPassword"
                                type="password"
                                label={t('pages.ResetPassword.confirmNewPassword')}
                                variant="outlined"
                                onChange={(e: React.FormEvent) =>
                                    setConfirmPassword((e.target as HTMLInputElement).value)
                                }
                                value={confirmPassword}
                            />

                            <p style={{ margin: 0 }}> Minimum of 8 characters and at least:</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>1 Lower case letter</li>
                                <li>1 Upper case letter</li>
                                <li>1 Digit</li>
                                <li>1 of these symbols (@$!%*?&)</li>
                            </ul>

                            {resetPasswordState.loading ? (
                                <LoadingDots />
                            ) : (
                                <Button onClick={handleResetPassword} disabled={!validPassword}>
                                    {t('pages.ResetPassword.reset')}
                                </Button>
                            )}
                        </Card>
                    </FormResetPassword>
                )}
            </CenteredContainer>
        </React.Fragment>
    );
});
