import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const SessionKey = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.textGrey};
    margin: 0;
`;

export const SessionValue = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding-left: 5px;
`;

export const SessionInfoContainer = styled.div`
    display: flex;
    margin-right: 60px;
    margin-top: 10px;
    &:last-child {
        margin-right: 0px;
    }
`;
