import styled from 'styled-components';

import { colours } from 'constants/colours';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 2rem 3rem;
    background-color: ${colours.global.lightGrey};
`;
