import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { Intervenant } from 'icc/types/user';
import { BACKEND_ROUTES } from '../constants/api_routes';

export interface IIntervenantsState {
    intervenants: Intervenant[];
    status: API_STATUS;
    error: string | null;
}

export const initialState: IIntervenantsState = {
    intervenants: [],
    status: API_STATUS.IDLE,
    error: null,
};

export interface IntervenantsResponseSuccess {
    intervenants: Intervenant[];
}

export const getIntervenants = createAsyncThunk('icc/get/intervenants', (_, { dispatch }) => {
    dispatch(setLoadingAction());

    return axios
        .get<IntervenantsResponseSuccess>(BACKEND_ROUTES.INTERVENANTS, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch(setIntervenantsAction(response.data));
        })
        .catch((e: Error) => {
            console.log('intervenantsSlice error', e);
            dispatch(setErrorAction(e.message));
        });
});

export const slice = createSlice({
    name: 'icc-intervenants',
    initialState,
    reducers: {
        setIntervenantsAction(state, action: PayloadAction<IntervenantsResponseSuccess>) {
            state.intervenants = action.payload.intervenants;
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
    },
});

export const { setIntervenantsAction, setErrorAction, setLoadingAction } = slice.actions;

export default slice.reducer;
