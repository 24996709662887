import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { IUser } from '../types/users/index';

import { AppType, IError } from '../types/index';

import { BACKEND_ROUTES as CHAMPS_BACKEND_ROUTES } from '../champs/constants/api_routes';
import { BACKEND_ROUTES as ICC_BACKEND_ROUTES } from '../icc/constants/api_routes';

export interface IAuthState {
    user: IUser | null;
    errors: IError | string | null;
    loading: boolean;
}

export interface IExportDbResponseSuccess {
    data: string;
}

export const exportDatabase = createAsyncThunk(
    'database/export-db',
    (params: { database: AppType }) => {
        axios
            .get(
                params.database === AppType.CHAMPS
                    ? CHAMPS_BACKEND_ROUTES.EXPORT_DB
                    : ICC_BACKEND_ROUTES.EXPORT_DB,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('api_token')}`,
                    },
                },
            )
            .then((response: IExportDbResponseSuccess) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${params.database.toLowerCase()}-${new Date().toISOString()}.sql`,
                ); // or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                console.log(e);
            });
    },
);
export const exportCSV = createAsyncThunk(
    'database/export-csv',
    (params: { database: AppType }) => {
        axios
            .get(
                params.database === AppType.CHAMPS
                    ? CHAMPS_BACKEND_ROUTES.EXPORT_CSV
                    : ICC_BACKEND_ROUTES.EXPORT_CSV,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('api_token')}`,
                    },
                },
            )
            .then((response: IExportDbResponseSuccess) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${params.database.toLowerCase()}-${new Date().toISOString()}.csv`,
                ); // or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                console.log(e);
            });
    },
);
