import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Table } from '@osedea/reactor';
import { createStyles, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';

import { fetchIntervenantsByApp } from 'user_management/slices/intervenantsSlice';
import { RootState } from 'store';
import { AppName } from 'types';
import SelectableIntervenantButton from '../SelectableIntervenantButton';
import { SelectedIntervenant } from 'types/users';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '100%',
            maxWidth: 600,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

export interface IProps {
    id: string;
    appName: AppName;
    targetAdminId?: number;
    open: boolean;
    preselectedIntervenants: SelectedIntervenant[];
    onClose: (appName: AppName, intervenantsId?: SelectedIntervenant[]) => void;
}

const ParticipantLinkerDialog: React.FC<IProps> = ({
    onClose,
    open,
    appName,
    targetAdminId,
    preselectedIntervenants = [],
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const rawIntervenants = useSelector(
        (state: RootState) => state.userManagementApp.interveants.intervenantsByApp,
    );

    useEffect(() => {
        if (open) {
            dispatch(fetchIntervenantsByApp({ appName, targetAdminId }));
        }
    }, [dispatch, targetAdminId, open, appName]);

    const handleOnEnter = () => {
        setSelectedIntervenants(preselectedIntervenants);
    };

    const handleCancel = () => {
        onClose(appName);
    };

    const handleOk = () => {
        onClose(appName, selectedIntervenants);
    };

    const headers: Header[] = [
        {
            id: 'firstName',
            label: t('global.firstName'),
        },
        {
            id: 'lastName',
            label: t('global.lastName'),
        },
        {
            id: 'participantsCount',
            label: '# Participants',
        },
        {
            id: 'controls',
            label: '',
        },
    ];

    const [selectedIntervenants, setSelectedIntervenants] = useState<SelectedIntervenant[]>(
        preselectedIntervenants,
    );

    const handleIntervenantClicked = useCallback(
        (intervenant: SelectedIntervenant) => {
            const copy = [...selectedIntervenants];

            const index = copy.map((e) => e.id).indexOf(intervenant.id);

            if (index === -1) {
                // Add
                const length = copy.push(intervenant);
                intervenant.selectedIndex = length;
            } else {
                // Remove
                intervenant.selectedIndex = null;
                copy.splice(index, 1);
                copy.forEach((element, index) => {
                    element.selectedIndex = index + 1;
                });
            }

            setSelectedIntervenants(copy);
        },
        [selectedIntervenants, setSelectedIntervenants],
    );

    const getSelectedOrder = useCallback(
        (intervenantId: number): number | null => {
            const index = selectedIntervenants.map((e) => e.id).indexOf(intervenantId);
            return index === -1 ? null : index + 1;
        },
        [selectedIntervenants],
    );

    const intervenantRows: Row[] = useMemo(() => {
        const sortable = rawIntervenants.map((intervenant) => {
            const selectedIndex = getSelectedOrder(intervenant.id);
            return {
                ...intervenant,
                selectedIndex,
                controls: (
                    <SelectableIntervenantButton
                        intervenant={{
                            ...intervenant,
                            selectedIndex,
                        }}
                        onClick={handleIntervenantClicked}
                    />
                ),
            };
        });

        const sortedIntervenants = sortable.sort(
            (a, b) => (b.selectedIndex ?? 0) - (a.selectedIndex ?? 0),
        );

        const rows: Row[] = sortedIntervenants.map((intervenant) => {
            const row = {
                id: intervenant.id,
                firstName: intervenant.firstName,
                lastName: intervenant.lastName,
                participantsCount: `${intervenant.participantsCount}`,
                controls: intervenant.controls,
            };
            return row;
        });
        return rows;
    }, [rawIntervenants, handleIntervenantClicked, getSelectedOrder]);

    return (
        <Dialog
            classes={classes}
            disableBackdropClick
            disableEscapeKeyDown
            onEnter={handleOnEnter}
            maxWidth="lg"
            open={open}
        >
            <DialogTitle id="link-participant-dialog">
                {t('pages.UserManagement.linkParticipant')}
            </DialogTitle>
            <DialogContent dividers>
                <Table headers={headers} rows={intervenantRows} emptyCellValue={'N/A'} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t('global.cancel')}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {t('global.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ParticipantLinkerDialog;
