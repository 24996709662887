import React from 'react';
import { useTranslation } from 'react-i18next';

import { QAContainer, TitleBlock, Seperator } from './styles';
import {
    UserElement,
    RouteSwitch,
    SegmentName,
    UserResponse,
    ElementType,
} from '../../types/segment';

import { QuizUserResponsesBlock } from '../QuizUserResponsesBlock';

interface IProps {
    userElement: UserElement;
    segmentName: SegmentName;
    routeSwitch?: RouteSwitch | null;
    seperator: boolean;
}

export const QuizMultiQuestionBlock: React.FC<IProps> = ({
    segmentName,
    userElement,
    routeSwitch,
    seperator,
}) => {
    const { t } = useTranslation();
    const isForm = userElement.type === ElementType.FORMS_TO_FILL;
    const responsesByQuestion: UserResponse[][] = [];
    userElement.responses?.forEach((response) => {
        if (
            responsesByQuestion[responsesByQuestion.length - 1] &&
            responsesByQuestion[responsesByQuestion.length - 1][0].questionNumber ===
                response.questionNumber
        ) {
            responsesByQuestion[responsesByQuestion.length - 1].push(response);
        } else {
            responsesByQuestion.push([response]);
        }
    });
    return (
        <>
            <QAContainer>
                <TitleBlock>{t(`icc.${segmentName}.${userElement.type}.title`)}</TitleBlock>
                {responsesByQuestion.map((userResponses) => {
                    return (
                        <QuizUserResponsesBlock
                            segmentName={segmentName}
                            userElementType={userElement.type}
                            userResponses={userResponses}
                            routeSwitch={routeSwitch}
                            activeQuestion={userResponses[0].questionNumber}
                            isForm={isForm}
                        />
                    );
                })}
            </QAContainer>
            {seperator && <Seperator />}
        </>
    );
};
