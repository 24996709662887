import React from 'react';
import { useTranslation } from 'react-i18next';

import SessionInfo from '../SessionInfo';
import {
    SessionContainer,
    SessionHeader,
    SessionBody,
    SessionIcon,
    SessionTitle,
    SessionBlock,
    ColumnOne,
    ColumnTwo,
    QuizTitle,
} from './styles';

import CompleteIcon from '../../assets/session-complete-icon.svg';
import InProgressIcon from '../../assets/session-in-progress-icon.svg';
import BookedIcon from '../../assets/session-booked-icon.svg';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
    IBoosterBRecap,
} from '../../types/sessionsRecaps';

import { QABlock } from '../QABlock';

import { SessionFourRecap } from '../SessionFourRecap';
import { SessionFiveRecap } from '../SessionFiveRecap';
import { FeedbackBlock } from '../FeedbackBlock';

interface IProps {
    sequence: number | undefined;
    sessionId: number | undefined;

    startedAt: string | undefined;
    completedAt: string | undefined;
    totalTime: number | undefined;
    corruptData: boolean | undefined;
    sessionRecap?:
        | ISessionOneRecap
        | ISessionTwoRecap
        | ISessionSixRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | IBoosterARecap;
    shareData: boolean | undefined;
}

export const ParticipantSessionsCard: React.FC<IProps> = ({
    sequence,
    sessionId,
    startedAt,
    completedAt,
    sessionRecap,
    shareData,
    totalTime,
    corruptData,
}) => {
    const { t } = useTranslation();

    const session = sequence ? sequence.toString() : '';

    const getStatus = () => {
        let status;
        if (!completedAt && !startedAt) {
            status = t('components.SessionCard.notStartedTitle');
        } else if (!completedAt && startedAt) {
            status = t('components.SessionCard.inProgressTitle');
        } else if (completedAt) {
            status = t('components.SessionCard.completedTitle');
        }
        return status;
    };

    const getDuration = () => {
        let duration = '-';

        if (totalTime) {
            const minutes = Math.floor(totalTime / 60000);
            const milliSeconds = totalTime - minutes * 60000;
            const seconds = Math.floor(milliSeconds / 1000);
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes - hours * 60;
            const secondsString = seconds > 9 ? seconds.toString() : `0${seconds}`;
            const remainingMinutesString =
                remainingMinutes > 9 ? remainingMinutes.toString() : `0${remainingMinutes}`;
            duration = t('components.SessionCard.HHmmSS', {
                hours,
                minutes: remainingMinutesString,
                seconds: secondsString,
            });
        }
        return duration;
    };

    const sessionTitle = () => {
        let title;
        switch (sequence) {
            case 7:
                title = t('components.SessionCard.titleBoosterA', { status: getStatus() });
                break;
            case 8:
                title = t('components.SessionCard.titleBoosterB', { status: getStatus() });
                break;
            default:
                title = t('components.SessionCard.title', { session, status: getStatus() });
        }
        return title;
    };

    return (
        <SessionContainer>
            <SessionHeader>
                <ColumnOne>
                    <SessionTitle>{sessionTitle()}</SessionTitle>
                    <SessionBlock>
                        <SessionInfo
                            sessionKey={t('components.SessionCard.startDate')}
                            value={startedAt ? new Date(startedAt).toLocaleString('en-gb') : '-'}
                        />
                        <SessionInfo
                            sessionKey={t('components.SessionCard.endDate')}
                            value={
                                completedAt ? new Date(completedAt).toLocaleString('en-gb') : '-'
                            }
                        />
                        {startedAt && (
                            <SessionInfo
                                sessionKey={t('components.SessionCard.duration')}
                                value={getDuration()}
                            />
                        )}
                    </SessionBlock>
                </ColumnOne>
                <ColumnTwo>
                    {!completedAt && !startedAt && (
                        <SessionIcon alt="In Progress Icon" src={BookedIcon} />
                    )}
                    {!completedAt && startedAt && (
                        <SessionIcon alt="In Progress Icon" src={InProgressIcon} />
                    )}
                    {completedAt && <SessionIcon alt="Complete Icon" src={CompleteIcon} />}
                </ColumnTwo>
            </SessionHeader>
            {shareData && (
                <SessionBody>
                    {!completedAt && !startedAt && t('components.SessionCard.notStarted')}
                    {!completedAt && startedAt && t('components.SessionCard.inProgress')}
                    {completedAt && sessionId === 1 && (
                        <FeedbackBlock sessionRecap={sessionRecap} />
                    )}
                    {completedAt && sessionId === 2 && (
                        <QABlock sessionRecap={sessionRecap} sessionId={sessionId} />
                    )}
                    {completedAt && sessionId === 3 && (
                        <QABlock sessionRecap={sessionRecap} sessionId={sessionId} />
                    )}
                    {completedAt && sessionId === 4 && (
                        <SessionFourRecap sessionRecap={sessionRecap} sessionId={sessionId} />
                    )}
                    {completedAt && sessionId === 5 && (
                        <SessionFiveRecap sessionRecap={sessionRecap} sessionId={sessionId} />
                    )}
                    {completedAt && sessionId === 6 && (
                        <>
                            <QABlock
                                sessionRecap={(sessionRecap as ISessionSixRecap).smartFeedback}
                                sessionId={sessionId}
                            />
                            <QABlock
                                sessionRecap={(sessionRecap as ISessionSixRecap).smartWrapper}
                                sessionId={sessionId}
                            />
                            <QuizTitle>{t('sessionRecaps.Titles.quizAnswers')}</QuizTitle>
                            <FeedbackBlock sessionRecap={(sessionRecap as ISessionSixRecap).quiz} />
                        </>
                    )}
                    {completedAt && sessionId === 7 && (
                        <>
                            <QABlock
                                sessionRecap={sessionRecap as IBoosterARecap}
                                sessionId={sessionId}
                            />
                        </>
                    )}
                    {completedAt && sessionId === 8 && (
                        <>
                            <QABlock
                                sessionRecap={sessionRecap as IBoosterBRecap}
                                sessionId={sessionId}
                            />
                        </>
                    )}
                </SessionBody>
            )}
            {!shareData && (
                <SessionBody>
                    {!completedAt && !startedAt && t('components.SessionCard.notStarted')}
                    {!completedAt && startedAt && t('components.SessionCard.inProgress')}
                    {completedAt && (
                        <QuizTitle>{t('components.SessionCard.shareSensitiveDataFalse')}</QuizTitle>
                    )}
                </SessionBody>
            )}
        </SessionContainer>
    );
};
