import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { SectionContainer, SectionContent, SectionLine, SectionTitle } from 'icc/styles/common';

interface IProps {
    title: string;
    children: JSX.Element | null;
}

export const CollapsibleSection: React.FC<IProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <>
            <SectionContainer>
                <SectionLine />
                <SectionContent>
                    <div style={{ flex: 1 }}>
                        <SectionTitle>{title}</SectionTitle>
                    </div>
                    {isOpen ? (
                        <IconButton aria-label="expand-less" onClick={() => setIsOpen(false)}>
                            <ExpandLessIcon fontSize="small" />
                        </IconButton>
                    ) : (
                        <IconButton aria-label="expand-more" onClick={() => setIsOpen(true)}>
                            <ExpandMoreIcon fontSize="small" />
                        </IconButton>
                    )}
                </SectionContent>
                <SectionLine />
            </SectionContainer>
            <Collapse isOpened={isOpen}>{children}</Collapse>
        </>
    );
};
