import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button } from '@osedea/reactor';

import { colours } from '../../constants/colours';
import { dropShadow } from '../../styles/common';

export const BackButton = styled(NavLink)`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: ${colours.black};
`;

export const NewBackButton = styled(Button)`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    background-color: transparent;
    color: ${colours.black};
`;

export const BackArrow = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 13px;
`;

export const ProfileContainer = styled.div`
    background-color: ${colours.white};
    box-shadow: ${dropShadow};
    border-radius: 10px;
    width: 100%;
    margin-top: 35px;
    padding: 35px 35px;
`;

export const ProfileFirstBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ProfileTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
`;

export const ProfileDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const NoRelationshipsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 180px;
`;
