import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getDashboardStats } from 'icc/slices/dashboardStatsSlice';
import { RootState } from 'store';
import { Container, Item } from './styles';
import StatusCard from '../StatusCard';
import { API_STATUS } from 'helpers/request';

interface IProps {}

export const DashboardStats: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.iccApp.dashboardStats.status);
    const dashboardStats = useSelector(
        (state: RootState) => state.iccApp.dashboardStats.dashboardStats,
    );

    useEffect(() => {
        dispatch(getDashboardStats());
    }, [dispatch]);

    return (
        <Container>
            <Item>
                <StatusCard
                    icon={'participant'}
                    number={dashboardStats.participants}
                    title={t('pages.Dashboard.participantsCard')}
                    isLoading={isLoading === API_STATUS.LOADING}
                />
            </Item>
            <Item>
                <StatusCard
                    icon={'participant'}
                    number={dashboardStats.intervenants}
                    title={t('pages.Dashboard.intervenantsCard')}
                    isLoading={isLoading === API_STATUS.LOADING}
                />
            </Item>
        </Container>
    );
};
