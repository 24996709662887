import styled from 'styled-components';

export const QBlock = styled.div`
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 24px;
`;

export const ABlock = styled.div`
    line-height: 24px;
`;
