import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';
import { Table } from '@osedea/reactor';

import { Container, SubTitle } from './styles';

import { ParticipantDetailsContext } from 'icc/constants/context';
import { CollapsibleSection } from '../CollapsibleSection';

interface IProps {
    shareData: boolean;
}

export const ParticipantWeeklyJournals: React.FC<IProps> = ({ shareData }) => {
    const { t } = useTranslation();

    const participant = useContext(ParticipantDetailsContext);

    const rows: Row[] = useMemo(() => {
        const weeklyJournals = participant?.weeklyJournals;
        if (weeklyJournals) {
            return Object.keys(weeklyJournals).map((key: unknown) => {
                const week = weeklyJournals[key as number];

                const filledDayExists = week.dailyJournals.some((journal) => journal.filled);

                const id = week.id;
                const weekNumber = `${week.weekNumber}`;
                const totalQuantity =
                    week.weekNumber === 0 || filledDayExists ? `${week.totalQuantity}` : 0;
                const totalFrequency =
                    week.weekNumber === 0 || filledDayExists ? `${week.totalFrequency}` : 0;
                const totalCost =
                    week.weekNumber === 0 || filledDayExists ? `${week.totalCost}` : 0;

                return {
                    id,
                    weekNumber,
                    totalQuantity,
                    totalFrequency,
                    totalCost,
                };
            });
        }
        return [];
    }, [participant]);

    const headers: Header[] = [
        {
            label: t('components.ParticipantWeeklyJournals.headers.weekNumber'),
            id: 'weekNumber',
        },
        {
            label: t('components.ParticipantWeeklyJournals.headers.totalQuantity'),
            id: 'totalQuantity',
        },
        {
            label: t('components.ParticipantWeeklyJournals.headers.totalFrequency'),
            id: 'totalFrequency',
        },
        {
            label: t('components.ParticipantWeeklyJournals.headers.totalCost'),
            id: 'totalCost',
        },
    ];

    return (
        <>
            <CollapsibleSection title={t('components.ParticipantWeeklyJournals.title')}>
                <>
                    <Container>
                        <SubTitle>
                            {' '}
                            {t('components.ParticipantWeeklyJournals.subTitle')}
                            {participant?.numberOfFilledDailyJournals}
                        </SubTitle>

                        {rows.length > 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ width: '60%' }}>
                                    {shareData && (
                                        <Table headers={headers} rows={rows} emptyCellValue={'-'} />
                                    )}
                                </div>
                            </div>
                        )}
                    </Container>
                </>
            </CollapsibleSection>
        </>
    );
};
