import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { modalShadow } from '../../styles/common';

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 580px;
    min-height: 223px;
    background: ${colours.white};
    box-shadow: ${modalShadow};
    border-radius: 10px;
    padding-bottom: 43px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
`;

export const IconContainer = styled.div`
    display: grid;
    place-items: center;
    margin-right: 25px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
`;
