import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Table } from '@osedea/reactor';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';

import { ROUTES } from 'constants/routes';
import { IntervenantDetailsContext } from 'icc/constants/context';

interface IProps {}

export const ParticipantWithOrderTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const intervenant = useContext(IntervenantDetailsContext);

    const headers: Header[] = [
        {
            id: 'order',
            label: t('global.order'),
        },
        {
            id: 'firstName',
            label: t('global.firstName'),
        },
        {
            id: 'lastName',
            label: t('global.lastName'),
        },
        {
            id: 'email',
            label: t('global.email'),
        },
        {
            id: 'phone',
            label: t('global.phone'),
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const navigateToUserDetails = useCallback(
        (userId: number) => {
            history.push(ROUTES.ICC.PARTICIPANT_DETAILS, {
                participantId: userId,
            });
        },
        [history],
    );

    const rows: Row[] = useMemo(() => {
        return (
            intervenant?.participants.map((participant) => {
                return {
                    id: participant.id,
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                    order: participant.order,
                    phone: participant.phone ?? '',
                    controls: (
                        <span>
                            <Button onClick={() => navigateToUserDetails(participant.id)}>
                                {t('components.ParticipantTable.detail')}
                            </Button>
                        </span>
                    ),
                };
            }) ?? []
        );
    }, [intervenant, navigateToUserDetails, t]);

    return <Table headers={headers} rows={rows} emptyCellValue={'N/A'} />;
};
