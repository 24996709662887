import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from '@osedea/reactor';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { ButtonStyles, DisabledButtonStyles, TableRefiner } from '../../styles/common';

import { ParticipantType } from '../../slices/participantsSlice';
import Icon from '../Icon';

export interface ParticipantsSelectionObject {
    participantId: number;
    selected: boolean;
}
interface IProps {
    participants: ParticipantType[];
    sorting: SortOptions;
    onSelect: (intervenantId: number) => void;
    unassignedParticipants: ParticipantsSelectionObject[] | [];
    participantsSelection: ParticipantsSelectionObject[] | [];
}

export const ManageParticipantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const {
        sorting,
        onSelect,
        participants,
        unassignedParticipants,
        participantsSelection,
    } = props;

    const [reducedParticpantList, setReducedParticpantList] = useState<ParticipantType[] | []>([]);

    const removeAssignedElsewhereParticpants = useCallback(() => {
        const unassignedParticipantsIds: number[] = [];
        unassignedParticipants.forEach((particpant: ParticipantsSelectionObject) =>
            unassignedParticipantsIds.push(particpant.participantId),
        );
        const participantsSelectionIds: number[] = [];
        participantsSelection.forEach((particpant: ParticipantsSelectionObject) =>
            participantsSelectionIds.push(particpant.participantId),
        );
        // Order particpants so that 1st are assigned to intervenant
        const orderedParticipants: ParticipantType[] = [];
        (participants as ParticipantType[]).forEach((participant) => {
            if (participantsSelectionIds.find((id) => id === participant.id)) {
                orderedParticipants.unshift(participant);
            } else {
                orderedParticipants.push(participant);
            }
        });
        // Filter so only particpants that assigned to intervenant or unassigned show
        const remainingParticipants: ParticipantType[] = (orderedParticipants as ParticipantType[]).filter(
            (participant) => {
                const participantId = participant.id;
                return (
                    unassignedParticipantsIds.find((id) => id === participantId) ??
                    participantsSelectionIds.find((id) => id === participantId)
                );
            },
        );
        setReducedParticpantList(remainingParticipants);
    }, [participants, participantsSelection, unassignedParticipants]);
    useEffect(() => {
        removeAssignedElsewhereParticpants();
    }, [
        participants,
        unassignedParticipants,
        participantsSelection,
        removeAssignedElsewhereParticpants,
    ]);

    const headers = [
        {
            label: t('components.IntervenantTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        {
            label: t('components.IntervenantTable.headers.participants'),
            id: 'participants',
            sortable: false,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const handleOnClick = (participantId: number) => {
        onSelect(participantId);
    };

    const selectedStatus = (participantId: number) => {
        const participant = participantsSelection.find(
            (participantObject) => participantObject.participantId === participantId,
        );
        return participant?.selected;
    };

    const participantRows = (reducedParticpantList as ParticipantType[]).map((participant) => ({
        id: participant.id,
        name: `${participant.firstName} ${participant.lastName}`,
        controls: (
            <Button
                variant="circular"
                onClick={() => handleOnClick(participant.id)}
                style={selectedStatus(participant.id) ? DisabledButtonStyles : ButtonStyles}
            >
                {selectedStatus(participant.id) ? '--' : <Icon icon={'plus'} />}
            </Button>
        ),
    }));

    return (
        <React.Fragment>
            <TableRefiner
                style={{
                    ...ButtonStyles,
                    maxHeight: '650px',
                }}
            >
                <Table headers={headers} rows={participantRows} sortOptions={sorting} />
            </TableRefiner>
        </React.Fragment>
    );
};
