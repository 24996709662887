import React, { useState, FormEvent } from 'react';
import { TextField, Button } from '@osedea/reactor';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { ButtonStyles } from '../../styles/common';

import { CloseButton, Title, FormContainer, Form, IntervenantTableContainer } from './styles';

import ROLES from '../../constants/roles';

import Icon from '../Icon';
import { AssignIntervenantTable } from '../AssignIntervenantTable';
import { addUser } from '../../slices/addUserSlice';
import { RootState } from '../../store';
import { TableTitle } from '../AssignIntervenantModal/styles';
import { AlternateModal } from '../Common/AlternateModal';

export interface IProps {
    show: boolean;
    onClose: () => void;
    role: string;
}

export interface IntervenantsSelectionObject {
    intervenantId: number;
    order: number;
}

export const AddUserModal: React.FC<IProps> = ({ show, onClose, role }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [extension, setExtension] = useState<string | undefined>(undefined);
    const [extensionError, setExtensionError] = useState<boolean>(false);
    const [sortingOptions, setSortingOptions] = useState<SortOptions>({
        sortBy: 'id',
        sortOrder: 'ASC',
    });
    const [intervenantsSelection, setIntervenantsSelection] = useState<
        IntervenantsSelectionObject[] | []
    >([]);
    const intervenants = useSelector((state: RootState) => state.intervenants.intervenants);

    // Trim whitespaces
    const canFormSubmit = () => firstName.trim() && lastName.trim() && phone.trim() && email.trim();

    // Reset state
    const resetState = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setPhone('');
        setExtension(undefined);
        setExtensionError(false);
    };

    // Dispatches the form to the backend and clean the state
    const addUserHandler = (e: FormEvent) => {
        e.preventDefault();
        if (canFormSubmit()) {
            dispatch(
                addUser({
                    firstName,
                    lastName,
                    email,
                    phone,
                    extension,
                    role,
                    intervenantsSelection,
                }),
            );
            resetState();
            onClose();
        }
    };

    // Clean state before closing the modal
    const handleClose = () => {
        resetState();
        onClose();
    };

    const onPhoneChange = (e: React.FormEvent) => {
        if (phone.length > 9) {
            if ((e.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
                setPhoneError(false);
                const updatedPhone = phone.substring(0, phone.length - 1);
                setPhone(updatedPhone);
            } else {
                setPhoneError(true);
            }
        } else {
            if (Number.isNaN(Number((e.target as HTMLInputElement).value))) {
                setPhoneError(true);
            } else {
                setPhoneError(false);
                setPhone((e.target as HTMLInputElement).value);
            }
        }
    };

    const phoneAssistiveText = phoneError ? t('components.AddUserLegacy.phoneError') : undefined;

    const onExtensionChange = (e: React.FormEvent) => {
        if (Number.isNaN(Number((e.target as HTMLInputElement).value))) {
            setExtensionError(true);
        } else {
            setExtensionError(false);
            setExtension((e.target as HTMLInputElement).value);
        }
    };

    // Assistive text for the extension field
    const extensionAssistiveText = extensionError
        ? t('components.AddUserLegacy.extensionError')
        : undefined;

    const handleOnSelect = (intervenantId: number) => {
        const intervenantsSelectionCopy = [...intervenantsSelection];

        if (
            intervenantsSelectionCopy.some(
                (intervenantsSelectionObject) =>
                    intervenantsSelectionObject.intervenantId === intervenantId,
            )
        ) {
            const intervenantsSelectionFilter = intervenantsSelectionCopy.filter(
                (intervenantsSelectionObject) =>
                    intervenantsSelectionObject.intervenantId !== intervenantId,
            );
            intervenantsSelectionFilter.forEach((object, index) => (object.order = index + 1));
            setIntervenantsSelection(intervenantsSelectionFilter);
        } else {
            intervenantsSelectionCopy.push({
                intervenantId,
                order: intervenantsSelectionCopy.length + 1,
            });
            setIntervenantsSelection(intervenantsSelectionCopy);
        }
    };

    return (
        <AlternateModal show={show}>
            <CloseButton role="button" tabIndex={0} onClick={handleClose}>
                <Icon icon={'closeModal'} />
            </CloseButton>
            <Title>
                {t('components.AddUserLegacy.title', {
                    role: t(`Roles.${role}`),
                })}
            </Title>
            <FormContainer isIntervenant={role === ROLES.INTERVENANT}>
                <Form>
                    <TextField
                        name="firstName"
                        type="text"
                        label={t('components.AddUserLegacy.firstName')}
                        variant="outlined"
                        onChange={(e: React.FormEvent) =>
                            setFirstName((e.target as HTMLInputElement).value)
                        }
                        value={firstName}
                        required={true}
                    />
                    <TextField
                        name="lastName"
                        type="text"
                        label={t('components.AddUserLegacy.lastName')}
                        variant="outlined"
                        onChange={(e: React.FormEvent) =>
                            setLastName((e.target as HTMLInputElement).value)
                        }
                        value={lastName}
                        required={true}
                    />
                    <TextField
                        name="email"
                        type="email"
                        label={t('components.AddUserLegacy.email')}
                        variant="outlined"
                        onChange={(e: React.FormEvent) =>
                            setEmail((e.target as HTMLInputElement).value)
                        }
                        value={email}
                        required={true}
                    />
                    <TextField
                        name="phone"
                        type="text"
                        label={t('components.AddUserLegacy.phone')}
                        variant="outlined"
                        hasError={phoneError}
                        assistiveText={phoneAssistiveText}
                        onChange={onPhoneChange}
                        value={phone.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3')}
                        required={true}
                    />
                    {role === ROLES.INTERVENANT && (
                        <TextField
                            name="extension"
                            type="text"
                            label={t('components.AddUserLegacy.extension')}
                            variant="outlined"
                            hasError={extensionError}
                            assistiveText={extensionAssistiveText}
                            onChange={onExtensionChange}
                            value={extension}
                        />
                    )}
                </Form>
            </FormContainer>
            {role === ROLES.PARTICIPANT && (
                <IntervenantTableContainer>
                    <TableTitle>{t('components.AddUserLegacy.intervenantsList')}</TableTitle>
                    <AssignIntervenantTable
                        intervenants={intervenants}
                        onChangeSort={setSortingOptions}
                        sorting={sortingOptions}
                        onSelect={handleOnSelect}
                        intervenantsSelection={intervenantsSelection}
                    />
                </IntervenantTableContainer>
            )}
            <Button
                onClick={addUserHandler}
                style={{
                    ...ButtonStyles,
                    margin: '0 auto',
                }}
            >
                {t('components.AddUserLegacy.submit')}
            </Button>
        </AlternateModal>
    );
};
