export const colours = {
    primary: '#3a529c',
    secondary: '#1ca9f0',
    white: '#FFFFFF',
    black: '#000000',
    grey: '#ededf7',
    offWhite: '#f4f5f9',
    navy: '#495576',
    grey100: '#F6F8FA',
    lightBlue: '#A0E7FA',
    activeButton: '#00D6C0',
    roleBlue: '#329BB7',
    dropShadow: '#EFEFEF',
    darkGrey: '#7A8289',
    elementBackground: '#E5E5E5',
    background: '#F4F5F8',
    numberGrey: '#414549',
    textGrey: '#5e6469',
    pendingYellow: '#FFFF00',
    inProgressOrange: '#FFAF36',
    completedGreen: '#8CCD6D',
    modalBackground: '#41454999',
    modalShadow: '#4A4A4A',
    deleteButtonPink: '#F6E9EC',
    error: '#8B001A',
    greyButton: '#E7EAED',
    feedbackBarBlue: '#E4F9FF',

    // Generic - Akufen
    global: {
        lightGrey: '#f6f8f9',
        greyVariant: '#75858F',
        blue: '#0b4170',
        blue1: '#104170',
        lightBlue: '#28a6e1',
        blueGrey200: '#b0bec5',
        blueGrey500: '#607d8b',
    },
};
