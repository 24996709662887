import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { IUser, IUserStats } from 'types/users';

import { BACKEND_ROUTES } from 'user_management/constants/api_routes';

export interface IUsersResponse {
    users: IUser[];
    stats: Partial<IUserStats>;
}
export interface IApiError {
    message: string | null;
}

export interface IUsersState {
    users: IUser[];
    stats: IUserStats;
    loading: boolean;
    errors: string | null;
}

const initialState: IUsersState = {
    users: [],
    stats: {
        admin: 0,
        intervenants: 0,
        participants: 0,
    },
    loading: false,
    errors: null,
};

export const fetchUsers = createAsyncThunk(
    'users/fetchAllUsers',
    async (params: { sortOptions?: SortOptions } | undefined, { dispatch }) => {
        dispatch(setLoadingAction());

        let endpoint = BACKEND_ROUTES.USERS;

        if (params?.sortOptions) {
            endpoint += `?sortBy=${
                params.sortOptions.sortBy
            }&sortOrder=${params.sortOptions.sortOrder.toLowerCase()}`;
        }

        await axios
            .get<IUsersResponse>(endpoint, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            })
            .then((response) => {
                dispatch(setFetchUsersAction(response.data));
            })
            .catch((error) => {
                let message: string | null = null;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.isAxiosError) {
                    message = (error as AxiosError<IApiError>).response?.data.message ?? null;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    message = error.message;
                }

                dispatch(setErrorsAction(message));
            });
    },
);

export const deleteUserById = createAsyncThunk(
    'users/deleteUserById',
    async (
        params: { userId: number; sortOptions?: SortOptions },
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoadingAction());

        const { userId, sortOptions } = params;

        try {
            const response = await axios.delete<IUsersResponse>(
                `${BACKEND_ROUTES.USERS}/${userId}`,
                {
                    params: {
                        sortBy: sortOptions?.sortBy,
                        sortOrder: sortOptions?.sortOrder.toLowerCase(),
                    },
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('api_token')}`,
                    },
                },
            );
            dispatch(setFetchUsersAction(response.data));
            return response.data;
        } catch (error) {
            let message: string | null = null;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.isAxiosError) {
                message = (error as AxiosError<IApiError>).response?.data.message ?? null;
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                message = error.message;
            }

            dispatch(setErrorsAction(message));
            return rejectWithValue(error);
        }
    },
);

const slice = createSlice({
    name: 'user_management/users',
    initialState,
    reducers: {
        setFetchUsersAction(state, action: PayloadAction<IUsersResponse>) {
            state.users = action.payload.users;

            const remoteStats = action.payload.stats;
            const stats = {
                admin: remoteStats.admin ?? 0,
                intervenants: remoteStats.intervenants ?? 0,
                participants: remoteStats.participants ?? 0,
            };

            state.stats = stats;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<string | null>) {
            state.loading = false;
            state.errors = action.payload;
        },
        setLoadingAction(state) {
            state.loading = true;
        },
    },
});

const { setFetchUsersAction, setErrorsAction, setLoadingAction } = slice.actions;

export default slice.reducer;
