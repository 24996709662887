import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { modalShadow } from '../../styles/common';

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${colours.white};
    box-shadow: ${modalShadow};
    border-radius: 10px;
    padding-bottom: 43px;
`;

export const TableTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 26px;
`;
