import React, { Suspense } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Routes from './Routes';
import Header from './components/Header';
import Loader from './components/Loader';
import { theme } from './constants/theme';
import { generateUUID } from './helpers/uuid';
import { colours } from 'constants/colours';

const Layout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colours.global.lightGrey};
`;

export default function App(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Suspense fallback={<Loader />}>
                    <Header key={generateUUID()} />
                    <Routes />
                </Suspense>
            </Layout>
        </ThemeProvider>
    );
}
