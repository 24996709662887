import styled from 'styled-components';

import { colours } from 'constants/colours';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 2rem 0;
    padding: 0 5rem;
`;

export const SectionLine = styled.div`
    flex: 2;
    height: 1px;
    background-color: ${colours.global.blueGrey200};
`;

export const SectionContent = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
`;

export const SectionTitle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;
`;
