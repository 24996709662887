export enum SectionName {
    SECTION_ONE = 'sectionOne',
    SECTION_TWO = 'sectionTwo',
    FINAL_MODULE = 'finalModule',
    BOOSTER = 'booster',
    OBJECTIVE_REVIEW = 'objectiveReview',
}
export enum SectionOneSegment {
    SEGMENT_1 = 'segment1',
    SEGMENT_2 = 'segment2',
    SEGMENT_3 = 'segment3',
    SEGMENT_4 = 'segment4',
    SEGMENT_5 = 'segment5',
    SEGMENT_6 = 'segment6',
    SEGMENT_7 = 'segment7',
    SEGMENT_8 = 'segment8',
    SEGMENT_9 = 'segment9',
    SEGMENT_10 = 'segment10',
}
export enum SectionTwoSegment {
    SEGMENT2_1 = '2segment1',
    SEGMENT2_2 = '2segment2',
    SEGMENT2_3 = '2segment3',
    SEGMENT2_4 = '2segment4',
    SEGMENT2_5 = '2segment5',
    SEGMENT2_6 = '2segment6',
    SEGMENT2_7 = '2segment7',
    SEGMENT2_8 = '2segment8',
}

export enum SpecialSegments {
    FINAL_MODULE = 'finalModule',
    BOOSTER_1 = 'booster1',
    BOOSTER_2 = 'booster2',
    BOOSTER_3 = 'booster3',
    OBJECTIVE_REVIEW = 'objectiveReview',
    OBJECTIVE_REVIEW_2 = 'objectiveReview2',
}

export const SectionTwoSegmentValues = Object.values(SectionTwoSegment);

export type SegmentName = SectionOneSegment | SectionTwoSegment | SpecialSegments;
export interface Segment {
    id: number;
    name: SegmentName;
    section: SectionName; // section name
    path?: RouteSwitch | null;
    createdAt: string;
    completedAt?: string;
    unlockedAt?: string;
    version: number;
    time?: SegmentTime;
    userElements: UserElement[];
    previousVersions?: UserSegment[] | null;
}
export interface SegmentTime {
    attempts: number;
    corruptData: boolean;
    id: number;
    pauseTime: string | null;
    resumeTime: string | null;
    startTime: string | null;
    totalTime: number;
    userSegmentId: number;
}
export interface UserElement {
    id: number;
    userSegmentId: number;
    type: ElementType;
    sequence: number; // Order it should appear in the segment
    createdAt: string;
    startedAt?: string | null;
    completedAt?: string | null;
    responses?: UserResponse[]; // if sharedSensitiveData is set to false and its an intervenant who is accessing the info, no userResponses are returned from the backend
}

export enum ElementType {
    INTRO = 'Introduction',
    QUIZ = 'Quiz',
    QUIZ_RECAP = 'QuizRecap',
    SCORED_QUIZ = 'ScoredQuiz',
    SCORED_QUIZ_RECAP = 'ScoredQuizRecap',
    QUIZ_INSTRUCT = 'QuizInstruct',
    QUIZ_INSTRUCT_2 = 'QuizInstruct2',
    DRUG_USE_STATS = 'DrugUseStats',
    WATCH_VIDEO = 'WatchVideo',
    END_SEGMENT = 'EndSegment',
    INFO_CAROUSEL = 'InfoCarousel',
    THUMBS_UP_DOWN_QUIZ = 'ThumbsUpDownQuiz',
    DEPENDENCY_SCORE = 'DependencyScore',
    DEPENDENCY_SCORE_GUIDE = 'DependencyScoreGuide',
    YES_NO_QUIZ = 'YesNoQuiz',
    YES_NO_QUIZ_RECAP = 'YesNoQuizRecap',
    MESSAGE = 'Message',
    FIXED_NUMBER_ANSWERS_QUIZ = 'FixedNumberAnswersQuiz',
    SIMPLE_RESPONSE_RECAP = 'SimpleResponseRecap',
    EMOJI_QUIZ_INSTRUCT = 'EmojiQuizInstruct',
    MOST_IMPORTANT_FIXED_NUMBER_ANSWERS_QUIZ = 'MostImportantFixedNumberAnswersQuiz',
    STRATEGIES = 'Strategies',
    MOST_IMPORTANT_FIXED_NUMBER_ANSWERS_RECAP = 'MostImportantFixedNumberAnswersRecap',
    SEVRAGES_SYMPTOMES = 'SevrageSymptoms',
    EMOJI_QUIZ = 'EmojiQuiz',
    EMOJI_QUIZ_RECAP = 'EmojiQuizRecap',
    GENERIC_LIST = 'GenericList',
    PSYCHOTIC_SYMPTOMS_QUIZ = 'PsychoticSymptomsQuiz',
    DRUG_COMPARISON = 'DrugComparison',
    FORMS_TO_FILL = 'FormsToFill',
    WHAT_IF = 'WhatIf',
    PATH_CHOICE = 'PathChoice',
    PATH_CHOICE_2 = 'PathChoice2',
    PATH_MULTI_Q_QUIZ = 'PathMultiQQuiz',
    PATH_MULTI_Q_RECAP = 'PathMultiQRecap',
    MULTI_QUIZ_RECAP = 'MultiQuizRecap',
    MULTI_QUIZ_RECAP_2 = 'MultiQuizRecap2',
    MULTI_QUIZ_RECAP_3 = 'MultiQuizRecap3',
    INFO_WITH_CARD = 'InfoWithCard',
    SCENARIOS_INTRO = 'ScenariosIntro',
    SCENARIOS_CARDS = 'ScenariosCards',
    SCENARIOS_QUIZ = 'ScenariosQuiz',
    SCENARIOS_RECAP = 'ScenariosRecap',
    SCENARIOS_SOLUTION = 'ScenariosSolution',
    A_OR_B_QUIZ_CATEGORIES = 'AorBQuizCategories',
    A_OR_B_RECAP_CATEGORIES = 'AorBRecapCategories',
    A_OR_B_QUIZ = 'AorBQuiz',
    A_OR_B_RECAP = 'AorBRecap',
    PATH_RACE = 'PathRace',
    MULTIPLE_INFO_WITH_CARD = 'MultipleInfoWithCard',
    PRE_QUIZ = 'PreQuiz',
    PATH_MULTI_QUIZ_RECAP = 'PathMultiQuizRecap',
    QUIZ_CARD_RECAP = 'QuizCardRecap',
    MESSAGE_2 = 'Message2',
    OBJECTIVE_REVIEW_INTRO = 'ObjectiveReviewIntro',
    MODIFIED_QUIZ = 'ModifiedQuiz',
    DREAMS_REVEIW = 'DreamsReview',
    PSYCHOTIC_SYMPTOMS_QUIZ_RECAP = 'PsychoticSymptomsQuizRecap',
    UPDATE_ANSWERS_QUIZ = 'UpdateAnswersQuiz',
}

export interface UserResponse {
    id?: number;
    questionUid: string; // Formatted as `${section}_${segment}_${ElementType}_question${activeQuestion}`,
    questionNumber: number;
    userElementId?: number;
    order: number;
    input?: string;
    responseKey: string; // The text key in translation file
    responseType: ResponseType;
    createdAt?: string;
    selectedOrder?: number;
}

export enum RouteSwitch {
    DEFAULT = 'default',
    AMBIVALENT = 'ambivalent',
    REDUCE = 'reduce',
    STOP = 'stop',
    FOOD = 'food',
    SLEEP = 'sleep',
    HYGIENE = 'hygiene',
    PHYSICAL_ACTIVITY = 'physicalActivity',
    MENTAL_HEALTH = 'mentalHealth',
    ABSTINENT = 'abstinent',
    REDUCED = 'reduced',
    SAME = 'same',
    ATTAINED_OBJECTIVE = 'attainedObjective',
    MORE_OR_LESS_OBJECTIVE = 'moreOrLessObjective',
    CANNABIS_STILL_PRESENT = 'cannabisStillPresent',
    CANNABIS_NOT_PRESENT = 'cannabisNotPresent',
    CANNABIS_STILL_PRESENT_AND_SATISFIED = 'cannabisStillPresentAndSatisfied',
    CANNABIS_STILL_PRESENT_AND_NOT_SATISFIED = 'cannabisStillPresentAndNotSatisfied',
    ACCOMPLISHED = 'accomplished',
    MORE_OR_LESS_ACCOMPLISHED = 'moreOrLessAccomplished',
}
export enum ResponseType {
    SELECT = 'select', // A user must select a predefined response dictated by the associated responseKey
    INPUT = 'input', // A user can input a custom response
    KEY_INPUT = 'keyInput', // A user can input a custom response but that response should be placed within prefined text dictated by the associated responseKey
    SELECT_ONLY_THIS = 'selectOnlyThis', // A user must select a predefined response dictated by the associated responseKey and if selected other responses to question are deselected
}
export interface Response {
    textkey: string; // The text key in translation file
    type: ResponseType;
    order?: number; // Order in which the list of responses should appear
}
export interface UserSegment {
    id: number;
    name: SegmentName;
    createdAt: string;
    version: number;
    unlockedAt?: string | null;
    completedAt?: string | null;
    userElements: UserElement[] | [];
    path?: RouteSwitch;
    section: SectionName;
    previousVersions?: UserSegment[];
    userSegmentTime?: UserSegmentTime[];
}
export interface UserSegmentTime {
    id: number;
    userSegmentId: number;
    startTime: string | null;
    resumeTime: string | null;
    pauseTime: string | null;
    totalTime: number;
    corruptData: boolean;
}

export enum QuizType {
    FIXED_NUMBER_RESPONSE = 'FixedNumberResponse',
    MULTI_RESPONSE = 'multiResponse', // A user may give multiple responses to a single question
    SINGLE_RESPONSE = 'singleResponse', // A user may a single responses to a question
}
export interface Question {
    textkey: string;
    type: QuizType;
    fixedResponseNumber?: number;
    responses: Response[];
}
