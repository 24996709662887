import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from '@osedea/reactor';
import { useHistory } from 'react-router-dom';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { useSelector } from 'react-redux';

import { ButtonStyles, DisabledButtonStyles, TableRefiner } from '../../styles/common';

import { ParticipantIntervenantType } from '../../slices/participantDetailsSlice';
import { ROUTES } from '../../constants/routes';
import { RootState } from '../../store';
import ROLES from '../../constants/roles';

interface IProps {
    intervenants: ParticipantIntervenantType[];
    onChangeSort: (sortingOption: SortOptions) => void;
    sorting: SortOptions;
}

export const ParticipantDetailIntervenantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { intervenants, onChangeSort, sorting } = props;
    const userRole = useSelector((state: RootState) => state.auth.user?.role);

    const headers = [
        {
            label: t('components.IntervenantTable.headers.order'),
            id: 'order',
            sortable: true,
        },
        {
            label: t('components.IntervenantTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        {
            label: t('components.IntervenantTable.headers.email'),
            id: 'email',
            sortable: false,
        },
        {
            label: t('components.IntervenantTable.headers.phone'),
            id: 'phone',
            sortable: false,
        },
        {
            label: t('components.IntervenantTable.headers.extension'),
            id: 'extension',
            sortable: false,
        },
        {
            label: t('components.IntervenantTable.headers.participants'),
            id: 'participants',
            sortable: false,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const intervenantRows = intervenants.map((intervenant) => ({
        id: intervenant.id,
        order: intervenant.order,
        name: `${intervenant.firstName} ${intervenant.lastName}`,
        email: intervenant.email,
        phone: intervenant.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? '',
        extension: intervenant.extension ?? '',
        participants: intervenant.participantsCount,
        controls: (
            <Button
                onClick={() => {
                    history.push(ROUTES.CHAMPS.INTERVENANT, { intervenantId: intervenant.id });
                }}
                style={userRole === ROLES.INTERVENANT ? DisabledButtonStyles : ButtonStyles}
                disabled={userRole === ROLES.INTERVENANT}
            >
                {t('components.ParticipantTable.detail')}
            </Button>
        ),
    }));

    const orderHandler = (currentSortOrder: SortOptions) => {
        const inactiveSortOrder = currentSortOrder.sortOrder === 'INACTIVE';
        const sortBy = inactiveSortOrder ? 'id' : currentSortOrder.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortOrder.sortOrder;

        onChangeSort({ sortBy, sortOrder });
    };

    return (
        <TableRefiner>
            <Table
                headers={headers}
                rows={intervenantRows}
                onSort={orderHandler}
                sortOptions={sorting}
            />
        </TableRefiner>
    );
};
