import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { AppName } from 'types';
import { IntervenantType } from 'types/users';

import { BACKEND_ROUTES } from 'user_management/constants/api_routes';

export interface IIntervenantsByAppResponse {
    users: IntervenantType[];
}

export interface IApiError {
    message: string | null;
}

export interface IUsersState {
    intervenantsByApp: IntervenantType[];
    loading: boolean;
    errors: string | null;
}

const initialState: IUsersState = {
    intervenantsByApp: [],
    loading: false,
    errors: null,
};

export const fetchIntervenantsByApp = createAsyncThunk(
    'user_management/fetchAllIntervenantsByApp',
    async (params: { appName: AppName; targetAdminId?: number }, { dispatch }) => {
        dispatch(setLoadingAction());

        const { appName, targetAdminId } = params;

        await axios
            .get<IIntervenantsByAppResponse>(BACKEND_ROUTES.INTERVENANTS_BY_APP, {
                params: {
                    appName,
                    targetAdminId,
                },
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            })
            .then((response) => {
                dispatch(setFetchIntervenantsByAppAction(response.data));
            })
            .catch((error) => {
                let message: string | null = null;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.isAxiosError) {
                    message = (error as AxiosError<IApiError>).response?.data.message ?? null;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    message = error.message;
                }

                dispatch(setErrorsAction(message));
            });
    },
);

const slice = createSlice({
    name: 'user_management/intervenants',
    initialState,
    reducers: {
        setFetchIntervenantsByAppAction(state, action: PayloadAction<IIntervenantsByAppResponse>) {
            state.intervenantsByApp = action.payload.users;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<string | null>) {
            state.intervenantsByApp = [];
            state.loading = false;
            state.errors = action.payload;
        },
        setLoadingAction(state) {
            state.loading = true;
            state.intervenantsByApp = [];
        },
    },
});

const { setFetchIntervenantsByAppAction, setErrorsAction, setLoadingAction } = slice.actions;

export default slice.reducer;
