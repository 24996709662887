import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 31.25rem;
    min-width: 21.875rem;
    > * {
        margin-bottom: 1.25rem;
    }
`;

export const FormResetPassword = styled.form({
    textAlign: 'center',
    backgroundColor: 'white',
    padding: '35px 126px',
    borderRadius: '10px',
    border: `solid 2px ${colours.black}`,
});

export const Title = styled.h2``;

interface LogoProps {
    alt: string;
    src: string;
}

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 30px;
    margin-top: 30px;
    object-fit: cover;
    alt: ${(props: LogoProps) => props.alt};
    src: ${(props: LogoProps) => props.src};
`;

export interface resetButtonProps {
    readonly disabled?: boolean;
}

export const ResetButton = styled.button`
    backgroundColor: ${(props: resetButtonProps) =>
        props.disabled ? colours.error : colours.activeButton},
    color: ${colours.black},
    border: solid 2px ${colours.black},
    margin: '0 auto',
`;
