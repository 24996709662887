import { SSO_API_URL } from '../../env';

export const BACKEND_ROUTES = {
    // SSO
    FORGOT_PASSWORD: `${SSO_API_URL}${'/users/forgot-password'}`,
    RESET_PASSWORD: `${SSO_API_URL}${'/users/reset-password'}`,
    VERIFY_RESET_TOKEN: `${SSO_API_URL}${'/users/verify-reset-token'}`,

    // User management
    USERS: `${SSO_API_URL}${'/users'}`,
    ADMINS: `${SSO_API_URL}${'/users/admins'}`,
    INTERVENANTS_BY_APP: `${SSO_API_URL}${'/users/app/intervenants'}`,
};
