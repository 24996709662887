import React, { ReactText, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Tabs } from '@osedea/reactor';
import { useDispatch } from 'react-redux';

import { ParticipantTable } from 'icc/components/ParticipantTable';
import { Container, TableContainer, ContainerPaper, Greeting, Welcome } from './styles';
import { IntervenantTable } from 'icc/components/IntervenantTable';
import { authenticationService } from 'helpers/authenticationService';
import { DashboardStats } from 'icc/components/DashboardStats';
import { getIntervenants } from 'icc/slices/intervenantsSlice';

interface IProps {}

enum TablesTab {
    INTERVENANTS = 'INTERVENANTS',
    PARTICIPANTS = 'PARTICIPANTS',
}

export const Dashboard: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = React.useState(TablesTab.PARTICIPANTS);

    const currentTable = React.useMemo(() => {
        if (currentTab === TablesTab.PARTICIPANTS) {
            return <ParticipantTable />;
        } else {
            return <IntervenantTable />;
        }
    }, [currentTab]);

    const dashboardStats = React.useMemo(() => {
        return <DashboardStats />;
    }, []);

    const handleTabsValueOnChange = (_event: any, newValue: ReactText) => {
        setCurrentTab((newValue.toString() as unknown) as TablesTab);
    };

    useEffect(() => {
        dispatch(getIntervenants());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>{t('pages.ICC.Dashboard.title')}</title>
            </Helmet>
            <Container>
                <Greeting>
                    {t('pages.ICC.Dashboard.greeting', {
                        firstName: authenticationService.currentUserValue?.firstName,
                    })}
                </Greeting>
                <Welcome>{t('pages.ICC.Dashboard.welcome')}</Welcome>
                {dashboardStats}
                <ContainerPaper>
                    <Tabs
                        options={[
                            {
                                label: t('pages.Participant.title'),
                                value: TablesTab.PARTICIPANTS,
                            },
                            {
                                label: t('pages.Intervenant.title'),
                                value: TablesTab.INTERVENANTS,
                            },
                        ]}
                        value={currentTab}
                        onChange={handleTabsValueOnChange}
                    />
                    <TableContainer>{currentTable}</TableContainer>
                </ContainerPaper>
            </Container>
        </>
    );
};
