import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@osedea/reactor';
import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { Breadcrumbs, Link } from '@material-ui/core';

import ProfileDetail from '../../components/ProfileDetail';

import { ParticipantDetailIntervenantTable } from '../../components/ParticipantDetailIntervenantTable';

import {
    ProfileContainer,
    ProfileFirstBlock,
    ProfileTitle,
    ProfileDetailsContainer,
    NoRelationshipsContainer,
} from './styles';
import { Container, Title, ButtonStyles } from '../../styles/common';

import {
    ParticipantSessionsType,
    getParticipantDetails,
} from '../../slices/participantDetailsSlice';

import { RootState } from '../../store';

import Icon from '../../components/Icon';
import ROLES from '../../constants/roles';
import { AssignIntervenantModal } from '../../components/AssignIntervenantModal';
import { DeleteUserModal } from '../../components/DeleteUserModal';
import { ProfileDetailContainer } from '../../components/ProfileDetail/styles';

import { getSessionsList } from '../../slices/sessionsListSlice';
import { ParticipantSessionsCard } from '../../components/ParticipantSessionsCard';
import { useToggle } from '../../helpers/hooks';

interface IState {
    participantId: number | null;
}

interface IProps {}

export const ParticipantDetail: React.FC<IProps> = () => {
    const [sortingOptions, setSortingOptions] = useState<SortOptions>({
        sortBy: 'id',
        sortOrder: 'ASC',
    });

    const [showAssignIntervenantModal, toggleAssignIntervenantModal] = useToggle();
    const [showDeleteUserModal, toggleDeleteUserModal] = useToggle();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation<IState>();
    const history = useHistory();

    const participantId = location.state.participantId;

    useEffect(() => {
        dispatch(getParticipantDetails(participantId));
    }, [dispatch, participantId]);

    useEffect(() => {
        dispatch(getSessionsList([]));
    }, [dispatch]);

    const participant = useSelector((state: RootState) => state.participantDetails.participant);
    const sessionsList = useSelector((state: RootState) => state.sessionsList.sessions);

    const user = useSelector((state: RootState) => state.auth.user);
    const isAdminOrSuperAdmin = user ? [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(user.role) : false;

    const mountedSessions: ParticipantSessionsType[] = React.useMemo(() => {
        const sessions: ParticipantSessionsType[] = [];

        if (participant) {
            sessionsList.forEach((session) => {
                const thisSession = participant.sessions.find(
                    (participantSession: ParticipantSessionsType) =>
                        participantSession.sequence === session.sequence,
                );
                if (thisSession) {
                    sessions.push({
                        id: thisSession.sessionId,
                        userId: thisSession.userId,
                        sequence: thisSession.sequence,
                        sessionId: thisSession.sessionId,
                        startedAt: thisSession.startedAt,
                        completedAt: thisSession.completedAt,
                        startTime: thisSession.startTime,
                        totalTime: thisSession.totalTime,
                        corruptData: thisSession.corruptData,
                        sessionRecap: thisSession.sessionRecap,
                    });
                } else {
                    sessions.push({
                        id: session.id,
                        userId: undefined,
                        sequence: session.sequence,
                        sessionId: session.id,
                        startedAt: undefined,
                        completedAt: undefined,
                        totalTime: undefined,
                        startTime: undefined,
                        corruptData: false,
                        sessionRecap: undefined,
                    });
                }
            });
            const hasBoosterA = participant.sessions.some((session) => session.sequence === 7);
            if (hasBoosterA) {
                const session8Index = sessions.findIndex((session) => session.sequence === 8);
                sessions.splice(session8Index, 1);
            } else {
                const session7Index = sessions.findIndex((session) => session.sequence === 7);
                sessions.splice(session7Index, 1);
            }
        }
        return sessions;
    }, [participant, sessionsList]);

    const capitalize = (string: string) => {
        const raw = string.toLowerCase();
        return raw[0].toUpperCase() + raw.slice(1);
    };
    const shareData = React.useMemo(() => {
        if (isAdminOrSuperAdmin) {
            return true;
        } else if (user?.role === ROLES.INTERVENANT && participant?.shareSensitiveData) {
            return true;
        }
        return false;
    }, [user, isAdminOrSuperAdmin, participant]);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('pages.UserDetail.title', {
                        role: t(`Roles.${ROLES.PARTICIPANT}`),
                    })}
                    : {participant ? `${participant.firstName} ${participant.lastName}` : ''}
                </title>
            </Helmet>
            <>
                <Container>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" onClick={history.goBack}>
                            {'\u2190 '}
                            {t('global.back')}
                        </Link>
                    </Breadcrumbs>
                    {participant && (
                        <>
                            <ProfileContainer>
                                <ProfileFirstBlock>
                                    <ProfileTitle>
                                        {t('pages.UserDetail.title', {
                                            role: t(`Roles.${ROLES.PARTICIPANT}`),
                                        })}
                                    </ProfileTitle>
                                </ProfileFirstBlock>
                                <ProfileDetailsContainer>
                                    <ProfileDetail
                                        title={t('pages.UserDetail.firstName')}
                                        value={participant.firstName}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.lastName')}
                                        value={participant.lastName}
                                    />
                                    {user?.role === ROLES.SUPER_ADMIN ? (
                                        <ProfileDetail
                                            title={t('pages.UserDetail.lastName')}
                                            value={`${participant.admin?.firstName} ${participant.admin?.lastName}`}
                                        />
                                    ) : (
                                        <ProfileDetail title={''} value={''} />
                                    )}
                                    <ProfileDetail
                                        title={t('pages.UserDetail.email')}
                                        value={participant.email}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.phone')}
                                        value={participant.phone}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.notification')}
                                        value={
                                            participant.notifications
                                                ? t('pages.UserDetail.notificationOn')
                                                : t('pages.UserDetail.notificationOff')
                                        }
                                    />
                                </ProfileDetailsContainer>
                                <ProfileDetailContainer
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <ProfileTitle>
                                        {t('pages.UserDetail.TableTitle', {
                                            role1: capitalize(t(`Roles.${ROLES.INTERVENANT}`)),
                                            role2: t(`Roles.${ROLES.PARTICIPANT}`),
                                        })}
                                    </ProfileTitle>
                                    {isAdminOrSuperAdmin && (
                                        <Button
                                            onClick={toggleAssignIntervenantModal}
                                            style={{
                                                ...ButtonStyles,
                                                marginRight: '20px',
                                            }}
                                        >
                                            {t('pages.UserDetail.manageRelationship', {
                                                role: t(`Roles.${ROLES.INTERVENANT}`),
                                            })}
                                        </Button>
                                    )}
                                </ProfileDetailContainer>

                                {participant.intervenants.length > 0 && (
                                    <ParticipantDetailIntervenantTable
                                        intervenants={participant.intervenants}
                                        onChangeSort={setSortingOptions}
                                        sorting={sortingOptions}
                                    />
                                )}
                                {!participant.intervenants.length && (
                                    <NoRelationshipsContainer>
                                        <Icon icon={'noRelationship'}></Icon>
                                        <ProfileTitle>
                                            {t('pages.UserDetail.noRelationship', {
                                                role: t(`Roles.${ROLES.INTERVENANT}`),
                                            })}
                                        </ProfileTitle>
                                        <Button
                                            onClick={toggleAssignIntervenantModal}
                                            style={{
                                                ...ButtonStyles,
                                                margin: '0 auto',
                                            }}
                                        >
                                            {t('pages.UserDetail.addRelationship', {
                                                role: t(`Roles.${ROLES.INTERVENANT}`),
                                            })}
                                        </Button>
                                    </NoRelationshipsContainer>
                                )}
                            </ProfileContainer>
                            <ProfileContainer>
                                <ProfileTitle>{t('pages.UserDetail.journey')}</ProfileTitle>
                                {mountedSessions.map((session) => {
                                    return (
                                        <ParticipantSessionsCard
                                            sequence={session.sequence}
                                            sessionId={session.sessionId}
                                            startedAt={session.startedAt}
                                            completedAt={session.completedAt}
                                            totalTime={session.totalTime}
                                            corruptData={session.corruptData}
                                            sessionRecap={session.sessionRecap}
                                            key={session.sessionId}
                                            shareData={shareData}
                                        />
                                    );
                                })}
                            </ProfileContainer>
                        </>
                    )}
                    {!participant && (
                        <ProfileContainer>
                            <Title>{t('pages.UserDetail.error')}</Title>
                        </ProfileContainer>
                    )}
                    {showAssignIntervenantModal && participant && (
                        <AssignIntervenantModal
                            open={showAssignIntervenantModal}
                            participant={participant}
                            onClose={toggleAssignIntervenantModal}
                        />
                    )}
                    {participant && showDeleteUserModal && (
                        <DeleteUserModal
                            open={showDeleteUserModal}
                            onClose={toggleDeleteUserModal}
                            role={ROLES.PARTICIPANT}
                            userId={participant.id}
                            userName={`${participant.firstName} ${participant.lastName}`}
                        />
                    )}
                </Container>
            </>
        </React.Fragment>
    );
};
