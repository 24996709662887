import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { IntervenantType } from 'types/users';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface IIntervenantsState {
    intervenants: IntervenantType[];
    error: string | null;
    loading: boolean;
}

export const initialState: IIntervenantsState = {
    intervenants: [],
    loading: false,
    error: null,
};

export const getIntervenants = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IntervenantType[],
    unknown,
    { rejectValue: string }
>('get/intervenants', async (sortingOptions, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: IntervenantType[] }>(BACKEND_ROUTES.INTERVENANTS, {
            params: sortingOptions,
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        });
        return response.data.data;
    } catch (e) {
        console.log('intervenantsSlice error', e);
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const slice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getIntervenants.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getIntervenants.fulfilled, (state, { payload }) => {
            state.intervenants = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getIntervenants.rejected, (state, { payload }) => {
            if (payload) {
                state.intervenants = [];
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
