import { configureStore, Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { useDispatch } from 'react-redux';

import authReducer from './slices/authSlice';
import intervenantsReducer from './slices/intervenantsSlice';
import participantsReducer from './slices/participantsSlice';
import dashboardStatsReducer from './slices/dashboardStatsSlice';
import participantDetailsReducer from './slices/participantDetailsSlice';
import intervenantDetailsReducer from './slices/intervenantDetailsSlice';
import sessionsListReducer from './slices/sessionsListSlice';
import deleteUserReducer from './slices/deleteUserSlice';
import resetPasswordReducer from './slices/resetPasswordSlice';
import userManagementAppReducer from 'user_management/slices/rootReducer';
import iccAppReducer from 'icc/slices/iccRootReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    intervenants: intervenantsReducer,
    participants: participantsReducer,
    dashboardStats: dashboardStatsReducer,
    participantDetails: participantDetailsReducer,
    intervenantDetails: intervenantDetailsReducer,
    sessionsList: sessionsListReducer,
    deleteUser: deleteUserReducer,
    resetPasswordSlice: resetPasswordReducer,
    userManagementApp: userManagementAppReducer,
    iccApp: iccAppReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
