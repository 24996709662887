import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import { colours } from '../../constants/colours';
import Icon from '../Icon';

export const RightNavContainer = styled.div`
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;
    color: inherit;
    letter-spacing: 1px;
`;

export const Container = styled(Paper)`
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 3rem;
`;

interface LogoProps {
    alt: string;
    src: string;
}

export const MainLogo = styled.img`
    height: 64px;
    alt: ${(props: LogoProps) => props.alt};
    src: ${(props: LogoProps) => props.src};
`;

export const HeaderIcon = styled(Icon)`
    size: 32px;
`;

export const ExitButton = styled.a`
    pointer-events: all;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const ExportDatabaseButton = styled.a`
    display: flex;
    align-items: center;

    margin: 0px 10px;
    padding: 0px 3px 0 10px;
    border-radius: 32px;
    border: 1px solid ${colours.global.blue};

    color: ${colours.global.blue};

    pointer-events: all;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    ${HeaderIcon} {
        margin: 0 0 0 3px;
    }
`;
export const LanguageButton = styled.a`
    display: flex;
    align-items: center;

    margin: 0px 10px;
    padding: 0px 10px 0 10px;
    border-radius: 32px;
    border: 1px solid ${colours.global.blue};

    color: ${colours.global.blue};

    pointer-events: all;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    ${HeaderIcon} {
        margin: 0 0 0 3px;
    }
`;

export const UserIcon = styled(Icon)`
    display: flex;
    justify-content: center;
    size: 35px;
    background-color: white;
    border-radius: 100%;
`;

export const UserName = styled.div`
    color: ${colours.global.blue};
    font-size: medium;
    line-height: 22px;
`;

export const Role = styled.div`
    color: ${colours.global.lightBlue};
    font-size: smaller;
    line-height: 10px;
`;
