import styled from 'styled-components';

export const QAContainer = styled.div`
    margin-bottom: 24px;
`;

export const TitleBlock = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
    text-decoration: underline;
`;

export const SubTitleBlock = styled.div`
    line-height: 24px;
    margin-bottom: 24px;
`;
