import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { AppType } from 'types';

import { RootState } from 'store';
import { ROUTES } from 'constants/routes';

import { CenteredContainer } from 'styles/common';
import ROLES from 'constants/roles';
import AppCard from 'components/AppCard';

import { Body, Row, AdminRow } from './styles';
import { FEATURE_FLAGS } from 'env';

interface IProps {}

export const Home: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const user = useSelector((state: RootState) => state.auth.user);
    const isAdminOrSuperAdmin = user?.role
        ? [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(user.role)
        : false;

    const handleClickChamps = (e: React.MouseEvent) => {
        if (user) {
            if (user.role === ROLES.SUPER_ADMIN) {
                history.push(ROUTES.CHAMPS.DASHBOARD, { app: AppType.CHAMPS });
            } else if (user.role === ROLES.ADMIN) {
                history.push(ROUTES.CHAMPS.DASHBOARD);
            } else {
                history.push(ROUTES.CHAMPS.INTERVENANT, {
                    intervenantId: user.id,
                });
            }
        }
    };

    const handleClickIcc = (e: React.MouseEvent) => {
        if (user) {
            if (user.role === ROLES.SUPER_ADMIN) {
                history.push(ROUTES.ICC.DASHBOARD, { app: AppType.ICC });
            } else if (user.role === ROLES.ADMIN) {
                history.push(ROUTES.ICC.DASHBOARD);
            } else {
                history.push(ROUTES.ICC.INTERVENANT_DETAILS, { intervenantId: user.id });
            }
        }
    };

    const handleUserManagement = (e: React.MouseEvent) => {
        history.push(ROUTES.USER_MANAGEMENT.DASHBOARD);
    };

    const renderMenu = () => {
        return (
            <Body>
                <Row>
                    {FEATURE_FLAGS.APPS.CHAMPS && (isAdminOrSuperAdmin || user?.champs) && (
                        <AppCard app={'champs'} onClick={handleClickChamps} />
                    )}
                    {FEATURE_FLAGS.APPS.ICC && (isAdminOrSuperAdmin || user?.icc) && (
                        <AppCard app={'icc'} onClick={handleClickIcc} />
                    )}
                </Row>
                {isAdminOrSuperAdmin && (
                    <AdminRow>
                        <AppCard app={'user-management'} onClick={handleUserManagement} />
                    </AdminRow>
                )}
            </Body>
        );
    };

    return (
        <>
            <Helmet>
                <title>{t('pages.Login.title')}</title>
            </Helmet>
            <CenteredContainer>{renderMenu()}</CenteredContainer>
        </>
    );
};
