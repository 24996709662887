import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Table } from '@osedea/reactor';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { useSelector } from 'react-redux';

import { ButtonStyles, DisabledButtonStyles, TableRefiner } from '../../styles/common';
import { IntervenantType } from 'types/users';

import { ROUTES } from '../../constants/routes';
import { RootState } from '../../store';
import ROLES from '../../constants/roles';
import { ERoleType } from 'types';

interface IProps {
    intervenants: IntervenantType[];
    onChangeSort: (sortingOption: SortOptions) => void;
    sorting: SortOptions;
    authUserRole?: ERoleType;
}

export const IntervenantTable: React.FC<IProps> = ({
    intervenants,
    onChangeSort,
    sorting,
    authUserRole,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    const userRole = useSelector((state: RootState) => state.auth.user?.role);

    const headers = React.useMemo(() => {
        if (authUserRole === ERoleType.SUPER_ADMIN) {
            return [
                {
                    label: t('components.IntervenantTable.headers.name'),
                    id: 'name',
                    sortable: true,
                },
                {
                    label: t('components.IntervenantTable.headers.admin'),
                    id: 'admin',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.email'),
                    id: 'email',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.phone'),
                    id: 'phone',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.extension'),
                    id: 'extension',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.participants'),
                    id: 'participants',
                    sortable: false,
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        } else {
            return [
                {
                    label: t('components.IntervenantTable.headers.name'),
                    id: 'name',
                    sortable: true,
                },
                {
                    label: t('components.IntervenantTable.headers.email'),
                    id: 'email',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.phone'),
                    id: 'phone',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.extension'),
                    id: 'extension',
                    sortable: false,
                },
                {
                    label: t('components.IntervenantTable.headers.participants'),
                    id: 'participants',
                    sortable: false,
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        }
    }, [t, authUserRole]);

    const intervenantRows = intervenants.map((intervenant) => ({
        id: intervenant.id,
        admin: `${intervenant.admin?.firstName} ${intervenant.admin?.lastName}`,
        name: `${intervenant.firstName} ${intervenant.lastName}`,
        email: intervenant.email,
        phone: intervenant.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? '',
        extension: intervenant.extension ?? '',
        participants: intervenant.participantsCount,
        controls: (
            <Button
                onClick={() => {
                    history.push(ROUTES.CHAMPS.INTERVENANT, { intervenantId: intervenant.id });
                }}
                style={userRole === ROLES.INTERVENANT ? DisabledButtonStyles : ButtonStyles}
                disabled={userRole === ROLES.INTERVENANT}
            >
                {t('components.ParticipantTable.detail')}
            </Button>
        ),
    }));

    const orderHandler = (currentSortOrder: SortOptions) => {
        const inactiveSortOrder = currentSortOrder.sortOrder === 'INACTIVE';
        const sortBy = inactiveSortOrder ? 'id' : currentSortOrder.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortOrder.sortOrder;

        onChangeSort({ sortBy, sortOrder });
    };

    return (
        <React.Fragment>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={intervenantRows}
                    onSort={orderHandler}
                    sortOptions={sorting}
                />
            </TableRefiner>
        </React.Fragment>
    );
};
