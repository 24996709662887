import { combineReducers } from 'redux';

import participantReducer from 'icc/slices/participantSlice';
import participantsReducer from 'icc/slices/participantsSlice';
import intervenantReducer from 'icc/slices/intervenantSlice';
import intervenantsReducer from 'icc/slices/intervenantsSlice';
import assignIntervenantsReducer from 'icc/slices/assignIntervenantSlice';
import dashboardStatsReducer from 'icc/slices/dashboardStatsSlice';

const iccRootReducer = combineReducers({
    participant: participantReducer,
    participants: participantsReducer,
    intervenant: intervenantReducer,
    intervenants: intervenantsReducer,
    assignIntervenants: assignIntervenantsReducer,
    dashboardStats: dashboardStatsReducer,
});

export default iccRootReducer;
