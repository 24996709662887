/* eslint-disable @typescript-eslint/no-floating-promises */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { IntervenantsSelectionObject } from '../components/AddUserModal';

import ROLES from '../constants/roles';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';
import { addRelationship } from './addRelationshipSlice';
import { getDashboardStats } from './dashboardStatsSlice';
import { getIntervenants } from './intervenantsSlice';
import { getParticipants } from './participantsSlice';

export interface UserType {
    email: string;
    role: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    extension?: string;
    profile: {
        hasCompletedIntro: boolean;
    };
}

export interface EmailType {
    email: string;
}

export interface IAddUserSuccessResponse {
    email: string;
    role: string;
    profile: UserType['profile'];
}

export interface IAddUserTypeState {
    user: IAddUserSuccessResponse | null;
    error: string | null | unknown;
    loading: boolean;
}

export const initialState: IAddUserTypeState = {
    user: null,
    loading: false,
    error: null,
};

export const addUser = createAsyncThunk(
    'add/user',
    async (
        params: {
            firstName: string;
            lastName: string;
            email: string;
            phone: string;
            extension: string | undefined;
            role: string;
            intervenantsSelection: IntervenantsSelectionObject[] | [];
        },
        { dispatch, rejectWithValue },
    ) => {
        try {
            const userToAdd: UserType = {
                email: params.email,
                role: params.role,
                password: 'Test123!',
                firstName: params.firstName,
                lastName: params.lastName,
                phone: params.phone,
                extension: params.extension,
                profile: {
                    hasCompletedIntro: false,
                },
            };
            await axios
                .post<IAddUserSuccessResponse>(BACKEND_ROUTES.USERS, userToAdd, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('api_token')}`,
                    },
                })
                .then((_) => {
                    if (params.role === ROLES.PARTICIPANT) {
                        dispatch(
                            addRelationship({
                                email: params.email,
                                role: params.role,
                                intervenantsSelection: params.intervenantsSelection,
                            }),
                        );
                    }
                });
            if (params.role === ROLES.INTERVENANT) {
                dispatch(
                    getIntervenants({
                        sortBy: 'id',
                        sortOrder: 'DESC',
                    }),
                );
            } else {
                dispatch(
                    getParticipants({
                        sortBy: 'id',
                        sortOrder: 'DESC',
                    }),
                );
            }
            dispatch(getDashboardStats([]));
            return userToAdd;
        } catch (e) {
            console.log('addUserSlice error', e);
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const slice = createSlice({
    name: 'addUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(addUser.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(addUser.fulfilled, (state, { payload }) => {
            state.user = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(addUser.rejected, (state, { payload }) => {
            if (payload) {
                state.user = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
