import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    CloseButton,
    Title,
    Container,
    TitleContainer,
    QuestionsContainer,
    Questions,
} from './styles';
import Icon from '../Icon';

export interface IProps {
    onCloseButton: (close: boolean) => void;
    answer: string;
    questions: string[];
}

export const FeedbackDetail: React.FC<IProps> = ({ onCloseButton, answer, questions }) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onCloseButton(false);
    };

    return (
        <Container>
            <TitleContainer>
                <Title>{t(`sessionRecaps.Answer.${answer}`)}</Title>
                <CloseButton role="button" tabIndex={0} onClick={handleClose}>
                    <Icon icon={'closeModal'} />
                </CloseButton>
            </TitleContainer>
            <QuestionsContainer>
                {questions.map((question) => (
                    <Questions>{t(`sessionRecaps.Question.${question}.title`)}</Questions>
                ))}
            </QuestionsContainer>
        </Container>
    );
};
