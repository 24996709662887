import styled from 'styled-components';

export const Title = styled.h2``;

export const Body = styled.div`
    width: 50%;
    margin 0 auto;
    flex-direction: column;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const AdminRow = styled(Row)`
    margin-top: 3rem;
    width: 100%;
`;
