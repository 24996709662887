import React from 'react';

import { ModalContainer, Modal } from './styles';

export interface IProps {
    show: boolean;
}

export const AlternateModal: React.FC<IProps> = ({ show, ...rest }) => {
    if (show) {
        return (
            <ModalContainer>
                <Modal>{rest.children}</Modal>
            </ModalContainer>
        );
    } else {
        return null;
    }
};
