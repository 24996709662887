import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const ProfileDetailContainer = styled.div`
    margin: 20px;
    box-sizing: border-box;
`;

export const ProfileDetailTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${colours.textGrey};
`;

export const ProfileDetailValue = styled.div`
    font-size: 16px;
    line-height: 24px;
`;
