import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    justify-items: stretch;
    align-items: stretch;
`;

export const FeedbackContainer = styled.div`
    overflow: hidden;
    margin: 20px 0px;
`;

export const ResponseBlock = styled.button<{ percentage: number }>`
    position: relative;
    overflow: hidden;
    margin: 16px;
    border: solid 2px ${colours.black};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: ${colours.white};
    ::after {
        content: '';
        background-color: ${colours.feedbackBarBlue};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: ${(props) => props.percentage * 100}%;
        z-index: 0;
    }
    > * {
        position: relative;
        z-index: 1;
    }
`;

export const Answer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 21px;
`;

export const Count = styled.div`
    display: flex;
    flex-direction: column;
    padding: 21px;
`;
