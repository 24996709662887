import styled from 'styled-components';

import { colours } from 'constants/colours';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 3rem 3rem;
    background-color: ${colours.global.lightGrey};
`;

export const TableTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
    flex: 1;
    margin: 2rem 0;
`;
