import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Avatar from '@material-ui/core/Avatar';
import {
    Backdrop,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Snackbar,
    TextField,
} from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { AppName } from 'types';

import LogoChamps from 'assets/logo-champs-round.png';
import LogoIcc from 'assets/logo-icc-round.png';

import { colours } from 'constants/colours';
import {
    Title,
    BodyContainer,
    Form,
    TextFieldWrapper,
    FormSection,
    PhoneWrapper,
    AppLogoWrapper,
} from './styles';
import ROLES from 'constants/roles';
import ParticipantLinkerDialog from '../ParticipantLinkerDialog';
import { FEATURE_FLAGS } from 'env';
import SelectedIntervenantsTable from '../SelectedIntervenantsTable';
import { ICreateUser, SelectedIntervenant, IIntervenantRelationships } from 'types/users';
import { createUser, fetchAdmins, selectAdmins } from 'user_management/slices/createUserSlice';
import { RootState } from 'store';
import { authenticationService } from 'helpers/authenticationService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            background: colours.global.blue,
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        selectAdminFormControl: {
            minWidth: 500,
        },
    }),
);

const PrimaryButton = withStyles((theme: Theme) => ({
    root: {
        width: '80%',
        marginTop: '3rem',
        color: colours.white,
        backgroundColor: colours.global.blue,
        '&:hover': {
            backgroundColor: colours.global.lightBlue,
        },
    },
}))(Button);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IFormInput {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    extension: string;
    role: string;
    adminId?: number;
}

interface IProps {
    handleClose: () => void;
    open: boolean;
}

const AddUserDialog: React.FC<IProps> = ({ handleClose, open = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userIsSuperAdmin = authenticationService.currentUserValue?.role === ROLES.SUPER_ADMIN;

    const { getValues, register, handleSubmit, errors, control, formState, reset } = useForm<
        IFormInput
    >({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const onSubmit = (data: IFormInput) => {
        const basicInformation = { ...data, extension: null };
        const appsPermission = {
            champs: appChampsSelected,
            icc: appIccSelected,
        };

        const champsRelationships = champsSelectedIntervenants.map((intervenant) => {
            return {
                id: intervenant.id,
                order: intervenant.selectedIndex ?? 0,
            };
        });
        const iccRelationships = iccSelectedIntervenants.map((intervenant) => {
            return {
                id: intervenant.id,
                order: intervenant.selectedIndex ?? 0,
            };
        });
        const relationships: IIntervenantRelationships = {
            champs: champsRelationships,
            icc: iccRelationships,
        };

        let adminId: number | undefined;

        if (userIsSuperAdmin) {
            adminId = selectedAdmin;
        }

        const userData: ICreateUser = { basicInformation, appsPermission, relationships, adminId };
        dispatch(createUser({ userData }));
    };

    const selectedRole = useWatch({
        control,
        name: 'role',
        defaultValue: ROLES.PARTICIPANT,
    });

    const selectedAdmin = useWatch({
        control,
        name: 'select-admin',
        defaultValue: undefined,
    });

    useEffect(() => {
        setIsFormCompleted(formState.isValid);
    }, [formState]);

    useEffect(() => {
        if (selectedRole === ROLES.ADMIN) {
            setAppChampsSelected(false);
            setAppChampsSelected(false);
        }
        reset({ ...getValues(), adminId: undefined }, { dirtyFields: false });
    }, [selectedRole, getValues, reset]);

    useEffect(() => {
        setChampsSelectedIntervenants([]);
        setIccSelectedIntervenants([]);
    }, [selectedRole, selectedAdmin]);

    const [appChampsSelected, setAppChampsSelected] = useState(false);
    const [appIccSelected, setAppIccSelected] = useState(false);

    const [isLinkerOpen, setLinkerOpen] = useState(false);
    const [targetAppName, setTargetAppName] = useState<AppName>('champs');
    const [champsSelectedIntervenants, setChampsSelectedIntervenants] = useState<
        SelectedIntervenant[]
    >([]);
    const [iccSelectedIntervenants, setIccSelectedIntervenants] = useState<SelectedIntervenant[]>(
        [],
    );

    const [isFormCompleted, setIsFormCompleted] = useState(false);

    const isAddingUser = useSelector(
        (state: RootState) => state.userManagementApp.createUser.loading,
    );
    const successfullyCreated = useSelector(
        (state: RootState) => state.userManagementApp.createUser.user,
    );
    const errorCreatingUser = useSelector(
        (state: RootState) => state.userManagementApp.createUser.errors,
    );

    const listOfAdmins = useSelector(selectAdmins);

    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (!successfullyCreated.hasBeenHandled && successfullyCreated.content) {
            handleClose();
        }
    }, [successfullyCreated, handleClose]);

    useEffect(() => {
        if (errorCreatingUser) {
            setShowSnackbar(true);
        }
    }, [errorCreatingUser]);

    const handleCloseLinker = (appName: AppName, intervenants?: SelectedIntervenant[]) => {
        setLinkerOpen(false);
        if (intervenants) {
            switch (appName) {
                case 'champs':
                    setChampsSelectedIntervenants(intervenants);
                    break;
                case 'icc':
                    setIccSelectedIntervenants(intervenants);
                    break;
                default:
                    break;
            }
        }
    };

    const getIntervenantsForApp = (appName: AppName): SelectedIntervenant[] => {
        switch (appName) {
            case 'champs':
                return champsSelectedIntervenants;
            case 'icc':
                return iccSelectedIntervenants;
            default:
                return [];
        }
    };

    const handleAppSelectedClicked = (appName: AppName) => {
        switch (appName) {
            case 'champs':
                setAppChampsSelected((prev) => !prev);
                setChampsSelectedIntervenants([]);
                break;
            case 'icc':
                setAppIccSelected((prev) => !prev);
                setIccSelectedIntervenants([]);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (userIsSuperAdmin) {
            dispatch(fetchAdmins());
        }
    }, [dispatch, userIsSuperAdmin]);

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Title>{t('components.AddUser.title')}</Title>
                    </Toolbar>
                </AppBar>
                <BodyContainer>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* 1. Basic Information */}
                        <FormSection>{t('components.AddUser.stepBasicInformation')}</FormSection>
                        <Divider style={{ marginBottom: '2rem' }} />
                        <TextFieldWrapper style={TextInputWrapperStyle}>
                            <TextField
                                name="firstName"
                                label={t('components.AddUser.firstName')}
                                inputRef={register({ required: true })}
                                variant={'outlined'}
                                required
                                style={TextInputStyle}
                            />
                            <TextField
                                name="lastName"
                                variant={'outlined'}
                                label={t('components.AddUser.lastName')}
                                inputRef={register({ required: true })}
                                required
                                style={TextInputStyle}
                            />
                        </TextFieldWrapper>
                        <TextFieldWrapper style={TextInputWrapperStyle}>
                            <TextField
                                name="email"
                                variant={'outlined'}
                                label={t('components.AddUser.email')}
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Enter a valid e-mail address',
                                    },
                                })}
                                type={'email'}
                                required
                                style={TextInputStyle}
                            />
                            <PhoneWrapper style={TextInputStyle}>
                                <TextField
                                    name="phone"
                                    variant={'outlined'}
                                    label={t('components.AddUser.phone')}
                                    inputRef={register({
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10,
                                    })}
                                    helperText={
                                        errors.phone ? t('components.AddUser.errorPhone') : ''
                                    }
                                    error={Boolean(errors.phone)}
                                    required
                                    style={{ flex: 1, flexGrow: 0.6 }}
                                />
                                <TextField
                                    variant={'outlined'}
                                    name="extension"
                                    label={t('components.AddUser.extension')}
                                    inputRef={register}
                                    style={{ flex: 1, flexGrow: 0.35 }}
                                />
                            </PhoneWrapper>
                        </TextFieldWrapper>

                        {/* 2. Role */}
                        <FormSection>{t('components.AddUser.stepRole')}</FormSection>
                        <Divider style={{ marginBottom: '2rem' }} />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t('components.AddUser.role')}</FormLabel>
                            <Controller
                                name="role"
                                rules={{ required: true }}
                                control={control}
                                defaultValue={ROLES.PARTICIPANT}
                                as={
                                    <RadioGroup row>
                                        {userIsSuperAdmin && (
                                            <FormControlLabel
                                                value={ROLES.ADMIN}
                                                control={<Radio />}
                                                label="Admin"
                                            />
                                        )}
                                        <FormControlLabel
                                            value={ROLES.INTERVENANT}
                                            control={<Radio />}
                                            label="Intervenant"
                                        />
                                        <FormControlLabel
                                            value={ROLES.PARTICIPANT}
                                            control={<Radio />}
                                            label="Participant"
                                        />
                                    </RadioGroup>
                                }
                            />
                        </FormControl>
                        {userIsSuperAdmin && selectedRole !== ROLES.ADMIN && (
                            <div style={{ display: 'block', width: '100%', marginTop: '1rem' }}>
                                <FormControl className={classes.selectAdminFormControl}>
                                    <FormLabel component="legend">
                                        {t('components.AddUser.selectAdmin')}
                                    </FormLabel>
                                    <Controller
                                        as={
                                            <Select
                                                style={{ marginTop: '.5rem' }}
                                                variant={'outlined'}
                                            >
                                                {listOfAdmins.map((admin) => {
                                                    return (
                                                        <MenuItem
                                                            key={admin.id}
                                                            value={admin.id}
                                                        >{`${admin.firstName} ${admin.lastName}`}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        }
                                        rules={{ required: true }}
                                        name={'select-admin'}
                                        control={control}
                                        defaultValue={''}
                                    />
                                </FormControl>
                            </div>
                        )}

                        {/* 3. Access */}
                        {[ROLES.INTERVENANT, ROLES.PARTICIPANT].includes(selectedRole) && (
                            <>
                                <FormSection> {t('components.AddUser.stepAccess')}</FormSection>
                                <Divider style={{ marginBottom: '2rem' }} />
                                <p> {t('components.AddUser.updateAppAccess')}</p>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <AppLogoWrapper
                                        style={appChampsSelected ? ActiveLogoStyle : {}}
                                        onClick={() => handleAppSelectedClicked('champs')}
                                    >
                                        <img
                                            src={LogoChamps}
                                            style={ImageLogoStyle}
                                            alt={'Round Logo Champs'}
                                        />
                                    </AppLogoWrapper>
                                    <AppLogoWrapper
                                        featureFlag={FEATURE_FLAGS.APPS.ICC}
                                        style={appIccSelected ? ActiveLogoStyle : {}}
                                        onClick={() => handleAppSelectedClicked('icc')}
                                    >
                                        <img
                                            src={LogoIcc}
                                            style={ImageLogoStyle}
                                            alt={'Round Logo iCanChange'}
                                        />
                                    </AppLogoWrapper>
                                </div>

                                {/* 4. Access */}

                                {selectedRole === ROLES.PARTICIPANT && (
                                    <>
                                        <FormSection>
                                            {t('components.AddUser.stepLinking')}
                                        </FormSection>
                                        <Divider style={{ marginBottom: '3rem' }} />
                                        {appChampsSelected && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    marginTop: '2rem',
                                                }}
                                            >
                                                <SelectedIntervenantsTable
                                                    intervenants={champsSelectedIntervenants}
                                                />

                                                <Button
                                                    startIcon={
                                                        <Avatar
                                                            src={LogoChamps}
                                                            className={classes.small}
                                                        />
                                                    }
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        setTargetAppName('champs');
                                                        setLinkerOpen(true);
                                                    }}
                                                    style={{ marginTop: '1rem' }}
                                                >
                                                    {t('components.AddUser.addIntervenantChamps')}
                                                </Button>
                                            </div>
                                        )}
                                        {appIccSelected && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    marginTop: '2rem',
                                                }}
                                            >
                                                <SelectedIntervenantsTable
                                                    intervenants={iccSelectedIntervenants}
                                                />

                                                <Button
                                                    startIcon={
                                                        <Avatar
                                                            src={LogoIcc}
                                                            className={classes.small}
                                                        />
                                                    }
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        setTargetAppName('icc');
                                                        setLinkerOpen(true);
                                                    }}
                                                    style={{ marginTop: '1rem' }}
                                                >
                                                    {t('components.AddUser.addIntervenantICC')}
                                                </Button>
                                            </div>
                                        )}
                                        <ParticipantLinkerDialog
                                            id={'participant-linker-dialog-admin'}
                                            appName={targetAppName}
                                            open={isLinkerOpen}
                                            targetAdminId={selectedAdmin}
                                            preselectedIntervenants={getIntervenantsForApp(
                                                targetAppName,
                                            )}
                                            onClose={handleCloseLinker}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        <div></div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <PrimaryButton
                                type={'submit'}
                                variant={'contained'}
                                disabled={!isFormCompleted}
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                {t('components.AddUser.submit')}
                            </PrimaryButton>
                        </div>
                    </Form>
                </BodyContainer>
                <Backdrop className={classes.backdrop} open={isAddingUser}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
                message={
                    errorCreatingUser === 'errorDuplicatedUser'
                        ? t('components.AddUser.userAlreadyExist')
                        : t('components.AddUser.addUserFail')
                }
            />
        </div>
    );
};

const TextInputWrapperStyle = { display: 'flex', justifyContent: 'space-between' };
const TextInputStyle = { flex: 1, flexGrow: 0.49 };
const ActiveLogoStyle = { filter: 'none', opacity: 1 };
const ImageLogoStyle = { width: 72, height: 72 };

export default AddUserDialog;
