import styled from 'styled-components';
import { Button } from '@osedea/reactor';

import { colours } from 'constants/colours';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 0px 3rem;
    background-color: ${colours.global.lightGrey};
`;

export const SubTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

export const SubTitle = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0;
    color: ${colours.global.greyVariant};
`;

export const SubTitleSpan = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 0.8rem;
    color: ${colours.global.greyVariant};
`;

export const TableContainer = styled.div`
    margin: 3rem 0;
    width: 100%;
`;

export const PrimaryButton = styled(Button)`
    margin: 1rem 0;
`;
