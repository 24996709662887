import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { Participant } from 'icc/types/user';
import { RootState } from 'store';
import { BACKEND_ROUTES } from '../constants/api_routes';

export interface IParticipantsState {
    participant?: Participant;
    status: API_STATUS;
    error: string | null;
}

export const initialState: IParticipantsState = {
    participant: undefined,
    status: API_STATUS.IDLE,
    error: null,
};

export interface GetAParticipantSuccess {
    participant: Participant;
}

export const getAParticipant = createAsyncThunk(
    'icc/get/participants',
    (params: { participantId: number }, { dispatch, getState }) => {
        dispatch(setLoadingAction());
        const { participantId } = params;

        const state = getState() as RootState;
        const currentId = state.iccApp.participant.participant?.id;

        if (currentId !== participantId) {
            dispatch(clearAction());
        }

        const url = `${BACKEND_ROUTES.PARTICIPANTS}/${participantId}`;

        return axios
            .get<GetAParticipantSuccess>(url, {
                headers: { ...authHeader() },
            })
            .then((response) => {
                dispatch(setParticipantAction(response.data));
            })
            .catch((e: Error) => {
                console.log('participantsSlice error', e);
                dispatch(setErrorAction(e.message));
            });
    },
);

export const resetParticipantState = createAsyncThunk('icc/get/participants', (_, { dispatch }) => {
    dispatch(resetAction());
});

export const slice = createSlice({
    name: 'icc-participants',
    initialState,
    reducers: {
        setParticipantAction(state, action: PayloadAction<GetAParticipantSuccess>) {
            state.participant = action.payload.participant;
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
        resetAction(state) {
            state.participant = undefined;
            state.status = API_STATUS.IDLE;
            state.error = null;
        },
        clearAction(state) {
            state.participant = undefined;
            state.error = null;
        },
    },
});

const {
    setParticipantAction,
    setErrorAction,
    setLoadingAction,
    resetAction,
    clearAction,
} = slice.actions;

export default slice.reducer;
