import React from 'react';

import LogoChamps from '../../assets/svg/logo_champs.svg';
import LogoIcc from '../../assets/svg/logo_icc.svg';
import ParticipantsIcon from '../../assets/icons/participants_icon.svg';
import IntervenantsIcon from '../../assets/icons/intervenants_icon.svg';
import InProgressIcon from '../../assets/icons/in-progress_icon.svg';
import CompletedIcon from '../../assets/icons/completed_icon.svg';
import AlertIcon from '../../assets/icons/alert_icon.svg';
import CloseModalIcon from '../../assets/icons/close_modal_icon.svg';
import PlusIcon from '../../assets/icons/plus_icon.svg';
import NoRelationshipIcon from '../../assets/icons/no_intervenant_icon.svg';
import DeleteIcon from '../../assets/icons/delete_icon.svg';
import User from '../../assets/icons/user.svg';
import Logout from '../../assets/icons/logout.svg';

export type IconType =
    | 'logoChamps'
    | 'logoIcc'
    | 'participant'
    | 'intervenant'
    | 'inProgress'
    | 'completed'
    | 'alert'
    | 'closeModal'
    | 'plus'
    | 'noRelationship'
    | 'logout'
    | 'user'
    | 'delete';

interface IProps {
    icon: IconType;
    size?: number;
    alt?: string;
    fill?: string;
}

const Icon: React.FC<IProps> = (props: IProps) => {
    const { icon, size, alt, ...rest } = props;

    const svgProps = {
        width: size,
        height: size,
        alt,
    };

    const Icons = {
        logoChamps: LogoChamps,
        logoIcc: LogoIcc,
        participant: ParticipantsIcon,
        intervenant: IntervenantsIcon,
        inProgress: InProgressIcon,
        completed: CompletedIcon,
        alert: AlertIcon,
        closeModal: CloseModalIcon,
        plus: PlusIcon,
        noRelationship: NoRelationshipIcon,
        delete: DeleteIcon,
        user: User,
        logout: Logout,
    };

    const IconSVG = Icons[icon];

    return <img alt={`icon ${icon}`} src={IconSVG} {...svgProps} {...rest} />;
};

export default Icon;
