import styled, { css } from 'styled-components';

import { colours } from '../../constants/colours';

export const NoIntervenant = styled.div`
    display: flex;
`;

export const IconContainer = styled.div`
    margin: 0 8px 0 0;
`;

export const StatusDot = css`
    height: 11px;
    width: 11px;
    border-radius: 100%;
    margin-right: 8px;
`;

export const InProgressDot = styled.div`
    ${StatusDot};
    background-color: ${colours.inProgressOrange};
`;
export const PendingDot = styled.div`
    ${StatusDot};
    background-color: ${colours.pendingYellow};
`;

export const CompletedDot = styled.div`
    ${StatusDot};
    background-color: ${colours.completedGreen};
`;

export const SessionStatus = styled.div`
    display: flex;
    align-items: center;
`;
