import { EQUESTION_TYPES, ERESPONSE_TYPES, IQuestion, IResponse } from '../types/sessionsRecaps';

export const isResponseTypeInput = (response: IResponse) => {
    return Boolean(response.type === ERESPONSE_TYPES.INPUT);
};
export const isResponseTypeKeyInput = (response: IResponse) => {
    const key_input = ERESPONSE_TYPES.KEY_INPUT.toString();
    return Boolean(response.type === key_input);
};
export const isResponseTypeWithCustomInput = (response: IResponse) => {
    const key_input = ERESPONSE_TYPES.KEY_INPUT.toString();
    const input = ERESPONSE_TYPES.KEY_INPUT.toString();
    return Boolean(response.type === input || response.type === key_input);
};
export const isQuestionTypeMultipleMany = (question: IQuestion) => {
    const multipleMany = EQUESTION_TYPES.MULTIPLE_MANY.toString();
    return Boolean(question.type === multipleMany);
};
export const formatManyResponsesString = (responseTextKeys: string[], joinWord: string) => {
    let responseButtonText = '';
    if (responseTextKeys.length === 2) {
        responseButtonText = `${responseTextKeys[0]} ${joinWord} ${responseTextKeys[1]}`;
    } else if (responseTextKeys.length > 2) {
        const lastItem = responseTextKeys[responseTextKeys.length - 1];
        responseTextKeys.pop();
        responseButtonText = `${responseTextKeys.join(', ')} ${joinWord} ${lastItem}`;
    } else {
        responseButtonText = responseTextKeys[0];
    }
    return responseButtonText;
};
