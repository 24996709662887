import React from 'react';
import { Switch } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import PrivateRoute from '../components/PrivateRoute';

import { Dashboard } from './pages/Dashboard';
import { ParticipantDetailsPage } from './pages/ParticipantDetailsPage';
import { IntervenantDetailsPage } from './pages/IntervenantDetailsPage';

interface IProps {}

const IccApp: React.FC<IProps> = (props) => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.ICC.DASHBOARD} Component={Dashboard} />
            <PrivateRoute
                path={ROUTES.ICC.PARTICIPANT_DETAILS}
                Component={ParticipantDetailsPage}
            />
            <PrivateRoute
                path={ROUTES.ICC.INTERVENANT_DETAILS}
                Component={IntervenantDetailsPage}
            />
        </Switch>
    );
};

export default IccApp;
