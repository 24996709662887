import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
} from '../../types/sessionsRecaps';
import { SmartRaceRecap } from '../SmartRaceRecap';
import { SmartMethodsRecap } from '../SmartMethodsRecap';
import { FeedbackTitle, GoalTitle } from '../SessionFourRecap/styles';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | undefined;
    sessionId: number;
}

export const SessionFiveRecap: React.FC<IProps> = ({ sessionRecap, sessionId }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <FeedbackTitle>{t('sessionRecaps.Titles.customFeedback')}</FeedbackTitle>
            <GoalTitle>{t('sessionRecaps.Titles.personalChallenge')}</GoalTitle>
            <SmartRaceRecap sessionRecap={sessionRecap} sessionId={sessionId} />
            <GoalTitle>{t('sessionRecaps.Titles.chosenPaths')}</GoalTitle>
            <SmartMethodsRecap sessionRecap={sessionRecap} sessionId={sessionId} />
        </React.Fragment>
    );
};
