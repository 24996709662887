import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Button } from '@osedea/reactor';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { TableRefiner, ButtonStyles } from '../../styles/common';
import { ParticipantType } from '../../slices/participantsSlice';
import { ROUTES } from '../../constants/routes';
import Icon from '../Icon';
import {
    IconContainer,
    NoIntervenant,
    InProgressDot,
    CompletedDot,
    PendingDot,
    SessionStatus,
} from './styles';
import { ERoleType } from 'types';

interface IProps {
    participants: ParticipantType[];
    onChangeSort: (sortingOption: SortOptions) => void;
    sorting: SortOptions;
    authUserRole?: ERoleType;
}

export const ParticipantTable: React.FC<IProps> = ({
    participants,
    onChangeSort,
    sorting,
    authUserRole,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const headers = React.useMemo(() => {
        if (authUserRole === ERoleType.SUPER_ADMIN) {
            return [
                {
                    label: t('components.ParticipantTable.headers.name'),
                    id: 'name',
                    sortable: true,
                },
                {
                    label: t('components.ParticipantTable.headers.admin'),
                    id: 'admin',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.email'),
                    id: 'email',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.phone'),
                    id: 'phone',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.intervenant'),
                    id: 'intervenant',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.session'),
                    id: 'session',
                    sortable: false,
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        } else {
            return [
                {
                    label: t('components.ParticipantTable.headers.name'),
                    id: 'name',
                    sortable: true,
                },
                {
                    label: t('components.ParticipantTable.headers.email'),
                    id: 'email',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.phone'),
                    id: 'phone',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.intervenant'),
                    id: 'intervenant',
                    sortable: false,
                },
                {
                    label: t('components.ParticipantTable.headers.session'),
                    id: 'session',
                    sortable: false,
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        }
    }, [t, authUserRole]);

    const intervenantName = (item: ParticipantType) => {
        if (!item.intervenant) {
            return (
                <NoIntervenant>
                    <IconContainer>
                        <Icon icon={'alert'} />
                    </IconContainer>
                    {t('components.ParticipantTable.noRelationship')}
                </NoIntervenant>
            );
        } else {
            return `${item.intervenant.firstName} ${item.intervenant.lastName}`;
        }
    };

    const renderSessionStatus = (item: ParticipantType) => {
        if (!item.latestSession) {
            return <div></div>;
        } else {
            let dot = <PendingDot></PendingDot>;
            let sessionName = `${t('components.ParticipantTable.session')} ${
                item.latestSession.sequence
            } -${' '}`;
            if (item.latestSession.sequence >= 7) {
                sessionName = `${t('components.ParticipantTable.session')} ${t(
                    'components.ParticipantTable.booster',
                )} -${' '}`;
            }
            let status = `${t('components.ParticipantTable.pending')}`;
            if (item.latestSession.completedAt) {
                status = `${t('components.ParticipantTable.completed')}`;
                dot = <CompletedDot></CompletedDot>;
            } else if (item.latestSession.startedAt) {
                status = `${t('components.ParticipantTable.inProgress')}`;
                dot = <InProgressDot></InProgressDot>;
            }

            return (
                <SessionStatus>
                    {dot}
                    {sessionName}
                    {status}
                </SessionStatus>
            );
        }
    };

    const renderRow = (item: ParticipantType) => ({
        id: item.id,
        intervenant: intervenantName(item),
        admin: `${item.admin?.firstName} ${item.admin?.lastName}`,
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        phone: item.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? '',
        session: renderSessionStatus(item),
        status: item.active
            ? t('components.ParticipantTable.statusActive')
            : t('components.ParticipantTable.statusNotActive'),
        controls: (
            <Button
                onClick={() => {
                    history.push(ROUTES.CHAMPS.PARTICIPANT, { participantId: item.id });
                }}
                style={ButtonStyles}
            >
                {t('components.ParticipantTable.detail')}
            </Button>
        ),
    });

    const preparedRows = (items: ParticipantType[]) => items.map(renderRow);

    const orderHandler = (currentSortOrder: SortOptions) => {
        const inactiveSortOrder = currentSortOrder.sortOrder === 'INACTIVE';
        const sortBy = inactiveSortOrder ? 'id' : currentSortOrder.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortOrder.sortOrder;

        onChangeSort({ sortBy, sortOrder });
    };

    return (
        <React.Fragment>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(participants)}
                    onSort={orderHandler}
                    sortOptions={sorting}
                />
            </TableRefiner>
        </React.Fragment>
    );
};
