import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface SessionType {
    id: number;
    name: string;
    sequence: number;
    expectedTime: number;
    createdAt: string;
}

export interface ISessionsState {
    sessions: SessionType[];
    error: string | null;
    loading: boolean;
}

export const initialState: ISessionsState = {
    sessions: [],
    loading: false,
    error: null,
};

export const getSessionsList = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    SessionType[],
    unknown,
    { rejectValue: string }
>('get/sessions-list', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: SessionType[] }>(BACKEND_ROUTES.SESSIONS_LIST, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        });
        return response.data.data;
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const slice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getSessionsList.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getSessionsList.fulfilled, (state, { payload }) => {
            state.sessions = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSessionsList.rejected, (state, { payload }) => {
            if (payload) {
                state.sessions = [];
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
