import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { IUser } from 'types/users';
import { RootState } from '../store';
import { IError } from '../types/index';
import * as UserManagementAPI from '../user_management/constants/api_routes';

export interface IResetTokenState {
    success: boolean;
    loading: boolean;
    error: IError | string | null;
}

export interface IResetPasswordState {
    user: Partial<IUser> | null;
    loading: boolean;
    error: IError | string | null;
}

export interface IPasswordState {
    resetTokenState: IResetTokenState;
    resetPasswordState: IResetPasswordState;
}

export interface IResetPasswordResponse {
    user: Partial<IUser>;
}

export interface IResetTokenResponse {
    data: {
        message: string;
    };
}

export const initialState: IPasswordState = {
    resetTokenState: {
        success: false,
        loading: false,
        error: null,
    },
    resetPasswordState: {
        user: null,
        loading: false,
        error: null,
    },
};

export const verifyResetToken = createAsyncThunk(
    'validate-reset-token',
    (params: { token: string; onSuccess?: () => void; onFailure?: () => void }, { dispatch }) => {
        const { token, onSuccess, onFailure } = params;

        dispatch(resetState());
        dispatch(setResetTokenLoadingAction());

        axios
            .get(`${UserManagementAPI.BACKEND_ROUTES.VERIFY_RESET_TOKEN}?token=${token}`)
            .then((response: IResetTokenResponse) => {
                dispatch(setResetTokenAction());
                onSuccess?.();
            })
            .catch(() => {
                dispatch(setResetTokenErrorAction());
                onFailure?.();
            });
    },
);

export const resetPassword = createAsyncThunk(
    'reset-password',
    (
        params: {
            token: string;
            password: string;
            onSuccess?: (user: Partial<IUser>) => void;
            onFailure?: () => void;
        },
        { dispatch },
    ) => {
        const { token, password, onSuccess, onFailure } = params;
        dispatch(setResetPasswordLoadingAction());
        axios
            .post<IResetPasswordResponse>(UserManagementAPI.BACKEND_ROUTES.RESET_PASSWORD, {
                token,
                password,
            })
            .then((response) => {
                dispatch(setResetPasswordAction(response.data));
                onSuccess?.(response.data.user);
            })
            .catch(() => {
                dispatch(setResetPasswordErrorsAction());
                onFailure?.();
            });
    },
);

export const forgotPassword = createAsyncThunk('forgot-password', (email: string, { dispatch }) => {
    dispatch(setResetPasswordLoadingAction());
    axios
        .post<IResetPasswordResponse>(UserManagementAPI.BACKEND_ROUTES.FORGOT_PASSWORD, {
            email,
        })
        .then((response) => {
            dispatch(setResetPasswordAction(response.data));
        })
        .catch(() => {
            dispatch(setResetPasswordErrorsAction());
        });
});

export const slice = createSlice({
    name: 'reset',
    initialState,
    reducers: {
        // Reset Password State
        setResetPasswordAction(state, action: PayloadAction<IResetPasswordResponse>) {
            state.resetPasswordState = {
                user: action.payload.user,
                loading: false,
                error: null,
            };
        },
        setResetPasswordErrorsAction(state) {
            state.resetPasswordState = {
                user: null,
                loading: false,
                error: 'An error ocurred',
            };
        },
        setResetPasswordLoadingAction(state) {
            state.resetPasswordState = {
                user: null,
                loading: true,
                error: null,
            };
        },
        // Reset Token State
        setResetTokenAction(state) {
            state.resetTokenState = {
                success: true,
                loading: false,
                error: null,
            };
        },
        setResetTokenErrorAction(state) {
            state.resetTokenState = {
                success: false,
                loading: false,
                error: 'An error ocurred',
            };
        },
        setResetTokenLoadingAction(state) {
            state.resetTokenState = {
                success: false,
                loading: true,
                error: null,
            };
        },
        resetState(state) {
            state.resetPasswordState = initialState.resetPasswordState;
            state.resetTokenState = initialState.resetTokenState;
        },
    },
});

export const selectUser = (state: RootState) => state.auth.user;

export const {
    setResetPasswordAction,
    setResetTokenAction,
    setResetPasswordErrorsAction,
    setResetTokenErrorAction,
    setResetTokenLoadingAction,
    setResetPasswordLoadingAction,
    resetState,
} = slice.actions;

export default slice.reducer;
