export interface IQARecap {
    [key: string]: string[] | [];
}

export interface ISmartRace {
    question: string;
    response: string;
    userResponse: string | null;
}

export interface ISessionOneRecap {
    never: string[];
    rarely: string[];
    occasionally: string[];
    sometimes: string[];
    often: string[];
    always: string[];
}

export interface ISessionTwoRecap {
    quiz: IQARecap;
    qa: IQARecap;
}

export interface ISessionFourRecap {
    qa: IQARecap[];
    smartRace: IQuestion[];
    smartMethods: string[];
}
export interface ISessionFiveRecap {
    smartRace: IQuestion[];
    smartMethods: string[];
}
export interface ISessionSixRecap {
    smartFeedback: IQARecap;
    smartWrapper: IQARecap;
    quiz: ISessionOneRecap;
}

export interface IBoosterARecap {
    qa: IQARecap[];
    smartFeedback: IQARecap[];
}

export interface IBoosterBRecap {
    qa: IQARecap[];
}
export interface IQuestion {
    flag: EFLAG_NAMES | null;
    id: number;
    textkey: string;
    answertype: string | null;
    sequence: number;
    sessionId?: number;
    moduleId?: number;
    skippable: boolean;
    type: EQUESTION_TYPES;
    created_at: string;
    response?: IResponse[] | null;
    userResponse?: IUserResponse[] | [];
}
export interface IResponse {
    id: number;
    textkey: string;
    type: ERESPONSE_TYPES;
    sequence: number;
    questionId?: number;
    input?: string;
}
export interface IUserResponse {
    id?: number;
    questionId: number;
    responseId: number;
    moduleId?: number;
    input?: string | null;
    userId?: number; // TODO: if it is passed remove from backend
    created_at?: string;
}
export enum EFLAG_NAMES {
    SMART_1 = 'smartConfidentsOnly',
    SMART_2 = 'smartReliableSource',
    SMART_3 = 'smartWaitForEffect',
    SMART_4 = 'smartAvoidMix',
    SMART_5 = 'smartAvoidSomeMethods',
    SMART_6 = 'smartAvoidWithCareGiver',
    SMART_7 = 'smartAvoidBefore',
    SMART_8 = 'smartNoPublic',
    SMART_9 = 'smartBeforeSports',
    SMART_10 = 'smartEmotionHelp',
    SMART_11 = 'smartSpecificDays',
    SMART_12 = 'smartNoHabit',
    SMART_13 = 'smartBadTrip',
    SMART_14 = 'smartBuyLess',
    SMART_15 = 'smartLimitedPuff',
    SMART_16 = 'smartOnceDaily',
    SMART_17 = 'smartLimitQuantity',
}
export enum EMODULE_TYPES {
    INTRO = 'INTRO',
    INSTRUCT = 'INSTRUCT',
    QUIZ = 'QUIZ',
    RECAP = 'RECAP',
    CONTENT = 'CONTENT',
    SWIPE = 'SWIPE',
    QA = 'QA',
    MESSAGE = 'MESSAGE',
    SMART_QA = 'SMART_QA',
    SMART_QUIZ = 'SMART_QUIZ',
    SMART_RACE = 'SMART_RACE',
    SMART_RECAP = 'SMART_RECAP',
    SMART_FEEDBACK = 'SMART_FEEDBACK',
    SMART_METHODS = 'SMART_METHODS',
    SMART_WRAPPER = 'SMART_WRAPPER',
    GENERIC_WRAPPER = 'GENERIC_WRAPPER',
    ENDCONFIRM = 'ENDCONFIRM',
}
export enum EQUESTION_TYPES {
    INPUT = 'INPUT',
    SWIPE = 'SWIPE',
    MULTIPLE_ONE = 'MULTIPLE_ONE',
    MULTIPLE_MANY = 'MULTIPLE_MANY',
    MULTIPLE_INPUT = 'MULTIPLE_INPUT',
    MULTIPLE_MANY_INPUT = 'MULTIPLE_MANY_INPUT',
    MULTIPLE_3 = 'MULTIPLE_3',
}
export enum ERESPONSE_TYPES {
    CUSTOM = 'CUSTOM',
    INPUT = 'INPUT',
    FREQUENCEY = 'FREQUENCEY',
    KEY_INPUT = 'KEY_INPUT',
}
