import { SSO_API_URL } from '../env';
import { ICC_ROUTES } from 'icc/constants/routes';

export const ROUTES = {
    HOME: '/home',
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password/',
    CREATE_PASSWORD: '/create-password/',
    PRIVACY_POLICY: '/privacy-policy/',
    CHAMPS: {
        ROOT: '/champs',
        DASHBOARD: '/champs/dashboard',
        INTERVENANT: '/champs/intervenant',
        PARTICIPANT: '/champs/participant',
    },
    ICC: ICC_ROUTES,
    USER_MANAGEMENT: {
        ROOT: '/user_management',
        DASHBOARD: '/user_management/dashboard',
        EDIT_USER: '/user_management/edit_user',
    },
};

export const BACKEND_ROUTES = {
    LOGIN: `${SSO_API_URL}${'/auth/login'}`,
};
