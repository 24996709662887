import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
} from '../types/sessionsRecaps';

export interface ParticipantIntervenantType {
    id: number;
    firstName: string;
    lastName: string;
    extension: number | null;
    phone: string | null;
    order: number;
    email: string;
    participantsCount: 13;
}
export interface ISuccessResponse {}
export interface ParticipantSessionsType {
    sequence: number | undefined;
    id: number | undefined;
    unlock?: string | undefined;
    userId: number | undefined;
    sessionId: number | undefined;
    completedAt?: string | undefined;
    startedAt?: string | undefined;
    startTime?: string | undefined;
    pauseTime?: string | undefined;
    totalTime?: number | undefined;
    corruptData?: boolean | undefined;
    sessionRecap?:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap;
}

export interface ParticipantDetailsType {
    id: number;
    active: boolean;
    firstName: string;
    lastName: string;
    phone: string;
    extension: null;
    lastSignin: string;
    createdAt: string;
    notifications: boolean | null;
    email: string;
    shareSensitiveData: boolean;
    intervenants: ParticipantIntervenantType[] | [];
    sessions: ParticipantSessionsType[];
    admin?: {
        id: number;
        firstName: string;
        lastName: string;
    };
}

export interface IParticipantDetailsState {
    participant: ParticipantDetailsType | null;
    error: string | null;
    loading: boolean;
}

export const initialState: IParticipantDetailsState = {
    participant: null,
    loading: false,
    error: null,
};
export interface IntervenantsSelectionObject {
    intervenantId: number;
    order: number;
}

export const getParticipantDetails = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ParticipantDetailsType,
    unknown,
    { rejectValue: string }
>('get/participant-details', async (participantId, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: ParticipantDetailsType }>(
            `${BACKEND_ROUTES.PARTICIPANT_DETAIL}${participantId}`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            },
        );
        return response.data.data;
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const manageParticipantRelationships = createAsyncThunk(
    'manage/relationship',
    async (
        params: {
            participantId: number;
            intervenantsSelection: IntervenantsSelectionObject[] | [];
        },
        { dispatch, rejectWithValue },
    ) => {
        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        };
        const valuesToUpdate = {
            participantId: params.participantId,
            intervenantsSelection: params.intervenantsSelection,
        };
        try {
            await axios.post<ISuccessResponse>(
                BACKEND_ROUTES.MANAGE_RELATIONSHIP_INTERVENANT,
                valuesToUpdate,
                headers,
            );
            const participantId = params.participantId;
            await dispatch(getParticipantDetails(participantId));
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);
export const slice = createSlice({
    name: 'participantDetailsData',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getParticipantDetails.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getParticipantDetails.fulfilled, (state, { payload }) => {
            state.participant = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getParticipantDetails.rejected, (state, { payload }) => {
            if (payload) {
                state.participant = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
