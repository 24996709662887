import styled from 'styled-components';

export interface IFormContainerProps {
    isIntervenant: boolean;
}

export const CloseButton = styled.div`
    margin-right: 2rem;
    align-self: flex-end;
    cursor: pointer;
`;

export const Title = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
`;

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 0 126px;
    height: ${(props: IFormContainerProps) => (props.isIntervenant ? '400px' : '300px')};
`;

export const Form = styled.form`
    width: 55%;
    flex: 1;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const IntervenantTableContainer = styled.div`
    padding: 43px;
    overflow-y: scroll;
`;
