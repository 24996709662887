import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Button } from '@osedea/reactor';

import { colours } from 'constants/colours';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 2rem 3rem;
    background-color: ${colours.global.lightGrey};
`;

export const Greeting = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
`;

export const Welcome = styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.darkGrey};
    margin: 0;
`;

export const ContainerPaper = styled(Paper)`
    margin: 2rem 0;
    width: 100%;
    padding: 2rem 3rem;
    background-color: ${colours.white};
`;

export const TableContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

export const Tab = styled(Button)`
    margin: 0 2rem;
`;

export const TableTitle = styled.div`
    margin: 1rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    flex: 1;
`;
