import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
} from '../../types/sessionsRecaps';
import { Questions } from '../FeedbackDetail/styles';
import { SmartRecapText } from '../SmartRaceRecap/styles';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | undefined;
    sessionId: number;
}

export const SmartMethodsRecap: React.FC<IProps> = ({ sessionRecap, sessionId }) => {
    const { t } = useTranslation();

    const getRecap = () => {
        let recap: ISessionFourRecap | ISessionFiveRecap | undefined;
        if (sessionId === 4) {
            recap = sessionRecap as ISessionFourRecap;
        } else if (sessionId === 5) {
            recap = sessionRecap as ISessionFiveRecap;
        }
        return recap;
    };

    const smartRaceMethods = getRecap()?.smartMethods;

    const raceArray = smartRaceMethods?.map((response: string[] | string) =>
        Array.isArray(response) ? response[0] : t(`sessionRecaps.Answer.${response}`),
    );

    return (
        <SmartRecapText>
            {raceArray?.map((resp) => (
                <Questions>{resp}</Questions>
            ))}
        </SmartRecapText>
    );
};
