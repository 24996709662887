import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { IUserStats } from 'types/users';
import { BACKEND_ROUTES } from '../constants/api_routes';

export interface IParticipantsState {
    dashboardStats: Omit<IUserStats, 'admin'>;
    status: API_STATUS;
    error: string | null;
}

export const initialState: IParticipantsState = {
    dashboardStats: {
        intervenants: 0,
        participants: 0,
    },
    status: API_STATUS.IDLE,
    error: null,
};

export interface GetDashboardStatsResponse {
    dashboardStats: Partial<IUserStats>;
}

export const getDashboardStats = createAsyncThunk('icc/get/dashboardStats', (_, { dispatch }) => {
    dispatch(setLoadingAction());

    return axios
        .get<GetDashboardStatsResponse>(BACKEND_ROUTES.DASHBOARD_STATS, {
            headers: { ...authHeader() },
        })
        .then((response) => {
            dispatch(setDasboardStatsAction(response.data));
        })
        .catch((e: Error) => {
            dispatch(setErrorAction(e.message));
        });
});

export const slice = createSlice({
    name: 'icc-dashboard-stats',
    initialState,
    reducers: {
        setDasboardStatsAction(state, action: PayloadAction<GetDashboardStatsResponse>) {
            const apiStats = action.payload.dashboardStats;
            const dashboardStats = {
                admin: apiStats.admin ?? 0,
                intervenants: apiStats.intervenants ?? 0,
                participants: apiStats.participants ?? 0,
            };
            state.dashboardStats = dashboardStats;
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
    },
});

const { setDasboardStatsAction, setErrorAction, setLoadingAction } = slice.actions;

export default slice.reducer;
