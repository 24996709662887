import React from 'react';
import { useTranslation } from 'react-i18next';

import { QBlock, ABlock } from './styles';

interface IProps {
    textString: string;
    qBlock: boolean;
    mark?: boolean;
    noStyling?: boolean;
}

export const TextIfExists: React.FC<IProps> = ({ textString, qBlock, mark, noStyling }) => {
    const { t, i18n } = useTranslation();
    const textExists = i18n.exists(textString);
    return (
        <>
            {textExists && qBlock && !noStyling && (
                <QBlock>{`${
                    (mark ? t(`sessionRecaps.Marks.question`) : '') + t(textString)
                }`}</QBlock>
            )}
            {textExists && !qBlock && !noStyling && (
                <ABlock>{`${
                    (mark ? t(`sessionRecaps.Marks.answer`) : '') + t(textString)
                }`}</ABlock>
            )}
            {textExists && noStyling && <>{t(textString)}</>}
        </>
    );
};
