import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const Title = styled.h4`
    margin-left: 1rem;
    font-weight: 500;
    font-size: 20px;
`;

export const CustomDivider = styled(Divider)`
    margin: 2rem;
`;

export const FormSection = styled.h5`
    font-weight: 500;
    font-size: 20px;
`;

export const BodyContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 2rem 5rem;
    justify-content: center;
`;

export const Form = styled.form`
    flex: 0.5;
`;

export const TextFieldWrapper = styled.div`
    margin: 1rem 0;
`;

export const PhoneWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

interface IFeatureFlag {
    featureFlag?: boolean;
}

export const AppLogoWrapper = styled.div`
    pointer-events: ${({ featureFlag = true }: IFeatureFlag) => (featureFlag ? 'auto' : 'none')};
    cursor: pointer;
    display: inline-block;
    filter: grayscale(100%);
    opacity: 0.5;

    &:hover {
        filter: none;
        opacity: 1;
    }
`;
