import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from '@osedea/reactor';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { ButtonStyles, DisabledButtonStyles, TableRefiner } from '../../styles/common';

import Icon from '../Icon';
import { IntervenantsSelectionObject } from '../AddUserModal';
import { IntervenantType } from 'types/users';

interface IProps {
    intervenants: IntervenantType[];
    onChangeSort: (sortingOption: SortOptions) => void;
    sorting: SortOptions;
    onSelect: (intervenantId: number) => void;
    intervenantsSelection: IntervenantsSelectionObject[] | [];
}

export const AssignIntervenantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const { intervenants, onChangeSort, sorting, onSelect, intervenantsSelection } = props;

    const headers = [
        {
            label: t('components.IntervenantTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        {
            label: t('components.IntervenantTable.headers.participants'),
            id: 'participants',
            sortable: false,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const handleOnClick = (intervenantId: number) => {
        onSelect(intervenantId);
    };

    const orderNumber = (intervenantId: number) => {
        const intervenant = intervenantsSelection.find(
            (intervenantObject) => intervenantObject.intervenantId === intervenantId,
        );
        return intervenant?.order;
    };

    const intervenantRows = intervenants.map((intervenant) => ({
        id: intervenant.id,
        name: `${intervenant.firstName} ${intervenant.lastName}`,
        participants: intervenant.participantsCount,
        controls: (
            <Button
                variant="circular"
                onClick={() => handleOnClick(intervenant.id)}
                style={orderNumber(intervenant.id) ? DisabledButtonStyles : ButtonStyles}
            >
                {orderNumber(intervenant.id) ? orderNumber(intervenant.id) : <Icon icon={'plus'} />}
            </Button>
        ),
    }));

    const orderHandler = (currentSortOrder: SortOptions) => {
        const inactiveSortOrder = currentSortOrder.sortOrder === 'INACTIVE';
        const sortBy = inactiveSortOrder ? 'id' : currentSortOrder.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortOrder.sortOrder;

        onChangeSort({ sortBy, sortOrder });
    };

    return (
        <React.Fragment>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={intervenantRows}
                    onSort={orderHandler}
                    sortOptions={sorting}
                />
            </TableRefiner>
        </React.Fragment>
    );
};
