import styled from 'styled-components';

import { colours } from 'constants/colours';
import { dropShadow } from 'styles/common';

export const Container = styled.div`
    background-color: ${colours.white};
    box-shadow: ${dropShadow};
    border-radius: 10px;
    width: 100%;
    padding: 35px 35px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
`;

export const SubTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    flex: 1;
`;
