import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface DashboardStatsType {
    intervenants?: number;
    participants: number;
    inProgress: number;
    completed: number;
}

export interface IDashboardStatsTypeState {
    stats: DashboardStatsType | null;
    error: string | null;
    loading: boolean;
}

export const initialState: IDashboardStatsTypeState = {
    stats: null,
    loading: false,
    error: null,
};

export const getDashboardStats = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    DashboardStatsType,
    unknown,
    { rejectValue: string }
>('get/dashboard-status', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: DashboardStatsType }>(
            BACKEND_ROUTES.DASHBOARD_STATS,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            },
        );
        return response.data.data;
    } catch (e) {
        console.log('dashboardStatsSlice error', e);
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const slice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getDashboardStats.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getDashboardStats.fulfilled, (state, { payload }) => {
            state.stats = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getDashboardStats.rejected, (state, { payload }) => {
            if (payload) {
                state.stats = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
