/* eslint-disable @typescript-eslint/no-floating-promises */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { IntervenantsSelectionObject } from '../components/AddUserModal';

import ROLES from '../constants/roles';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';
import { getDashboardStats } from './dashboardStatsSlice';
import { getIntervenants } from './intervenantsSlice';
import { getParticipants } from './participantsSlice';

export interface IAddRelationshipSuccessResponse {}

export interface ParticipantsSelectionObject {
    participantId: number;
    selected: boolean;
}
export interface IAddRelationshipTypeState {
    relationships: IAddRelationshipSuccessResponse | null;
    error: string | null | unknown;
    loading: boolean;
}

export const initialState: IAddRelationshipTypeState = {
    relationships: null,
    loading: false,
    error: null,
};

export interface EmailType {
    email: string;
}

export const addRelationship = createAsyncThunk(
    'add/relationship',
    async (
        params: {
            email: string;
            role?: string;
            intervenantsSelection: IntervenantsSelectionObject[] | [];
        },
        { dispatch, rejectWithValue },
    ) => {
        const emailOfUserToAdd: EmailType = {
            email: params.email,
        };
        try {
            const relationships: IntervenantsSelectionObject[] | [] = params.intervenantsSelection;
            if (relationships.length > 0) {
                const addRelationship = await axios.post<IAddRelationshipSuccessResponse>(
                    BACKEND_ROUTES.ADD_RELATIONSHIP_NEW_PARTICIPANT,
                    { emailOfUserToAdd, relationships },
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('api_token')}`,
                        },
                    },
                );
                return addRelationship;
            }
            if (params.role === ROLES.INTERVENANT) {
                dispatch(
                    getIntervenants({
                        sortBy: 'id',
                        sortOrder: 'DESC',
                    }),
                );
            } else {
                dispatch(
                    getParticipants({
                        sortBy: 'id',
                        sortOrder: 'DESC',
                    }),
                );
            }
            dispatch(getDashboardStats([]));
            return relationships;
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const slice = createSlice({
    name: 'addRelationship',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(addRelationship.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(addRelationship.fulfilled, (state, { payload }) => {
            state.relationships = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(addRelationship.rejected, (state, { payload }) => {
            if (payload) {
                state.relationships = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
