import { combineReducers } from 'redux';

import usersReducer from 'user_management/slices/usersSlice';
import intervenantsReducer from 'user_management/slices/intervenantsSlice';
import createUserReducer from 'user_management/slices/createUserSlice';
import editUserReducer from 'user_management/slices/editUserSlice';

const rootReducer = combineReducers({
    users: usersReducer,
    interveants: intervenantsReducer,
    createUser: createUserReducer,
    editUser: editUserReducer,
});

export default rootReducer;
