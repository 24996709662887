import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
} from '../../types/sessionsRecaps';
import { SmartRaceRecap } from '../SmartRaceRecap';
import { SmartMethodsRecap } from '../SmartMethodsRecap';

import { GoalTitle, FeedbackTitle } from './styles';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | undefined;
    sessionId: number;
}

export const SessionFourRecap: React.FC<IProps> = ({ sessionRecap, sessionId }) => {
    const { t } = useTranslation();

    const sessionFourQA = sessionRecap ? (sessionRecap as ISessionFourRecap).qa : [];

    return (
        <React.Fragment>
            {sessionFourQA.map((goal) => {
                return (
                    <>
                        <GoalTitle>
                            {t(`sessionRecaps.Question.${Object.keys(goal)[0]}.title`)}
                        </GoalTitle>
                        {(goal[Object.keys(goal)[0]] as string[]).map((answer) => (
                            <div>{t(`sessionRecaps.Answer.${answer}`)}</div>
                        ))}
                    </>
                );
            })}
            <FeedbackTitle>{t('sessionRecaps.Titles.customFeedback')}</FeedbackTitle>
            <GoalTitle>{t('sessionRecaps.Titles.personalChallenge')}</GoalTitle>
            <SmartRaceRecap sessionRecap={sessionRecap} sessionId={sessionId} />
            <GoalTitle>{t('sessionRecaps.Titles.chosenPaths')}</GoalTitle>
            <SmartMethodsRecap sessionRecap={sessionRecap} sessionId={sessionId} />
        </React.Fragment>
    );
};
