import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LogoChamps from 'assets/logo-champs-round.png';
import LogoIcc from 'assets/logo-icc-round.png';

import { sections, policySectionType, PolicyStyles } from '../../constants/policyText';

import {
    Container,
    PolicyContainer,
    Title,
    Logo,
    PaperContainer,
    Subtitle,
    IndentedContainer,
    UnderlineSubtitle,
    BoldText,
    LogoWrapper,
    AppLogoWrapper,
} from './styles';

// Assets
import ChumLogo from '../../assets/logo_crchum.jpg';

const ImageLogoStyle = { width: 72, height: 72 };
interface IProps {}

export const PrivacyPolicy = withRouter((props: IProps) => {
    const { t } = useTranslation();
    const renderSection = (section: policySectionType) => {
        switch (section.style) {
            case PolicyStyles.TITLE:
                return (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <h4>{section.title}</h4>
                        <p>{section.text}</p>
                    </div>
                );

            case PolicyStyles.TITLE_SUBTITLE:
                return (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <h4>{section.title}</h4>
                        <Subtitle>{section.subtitle}</Subtitle>
                        <p>{section.text}</p>
                    </div>
                );

            case PolicyStyles.INDENT_UNDERLINE:
                return (
                    <IndentedContainer style={{ whiteSpace: 'pre-line' }}>
                        <UnderlineSubtitle>{section.subtitle}</UnderlineSubtitle>
                        <p>{section.text}</p>
                    </IndentedContainer>
                );
            case PolicyStyles.SUBTITLE:
                return (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <Subtitle>{section.subtitle}</Subtitle>
                        <p>{section.text}</p>
                    </div>
                );
            default:
                return <></>;
        }
    };
    return (
        <>
            <Helmet>
                <title>{t('pages.PrivacyPolicy.title')}</title>
            </Helmet>
            <Container>
                <PaperContainer elevation={2}>
                    <PolicyContainer style={{ whiteSpace: 'pre-line' }}>
                        <LogoWrapper>
                            <Logo alt="Chum Logo" src={ChumLogo} />
                            <AppLogoWrapper>
                                <img
                                    src={LogoChamps}
                                    style={ImageLogoStyle}
                                    alt={'Round Logo Champs'}
                                />
                                <img
                                    src={LogoIcc}
                                    style={ImageLogoStyle}
                                    alt={'Round Logo iCanChange'}
                                />
                            </AppLogoWrapper>
                        </LogoWrapper>
                        <Title>{t('pages.PrivacyPolicy.text.title')}</Title>
                        <h4>{t('pages.PrivacyPolicy.text.lastUpdate')}</h4>
                        <Subtitle>{t('pages.PrivacyPolicy.application')}</Subtitle>
                        <p>{t('pages.PrivacyPolicy.text.intro')}</p>
                        {sections.map((section) => {
                            return renderSection(section);
                        })}
                        <Subtitle>{t('pages.PrivacyPolicy.text.contactText')}</Subtitle>
                        <p>
                            <BoldText>{t('pages.PrivacyPolicy.text.contactName')}</BoldText>
                            {t('pages.PrivacyPolicy.text.contactInfo')}
                        </p>
                        <p>{t('pages.PrivacyPolicy.text.contactEmail')}</p>
                    </PolicyContainer>
                </PaperContainer>
            </Container>
        </>
    );
});
