const BASE_URL = 'https://www.sante-mobile.com';

const CHAMPS_PREFIX = 'api/champs/v1';
const ICC_PREFIX = 'api/icc/v1';
const SSO_PREFIX = 'api/auth/v1';

export const CHAMPS_API_URL = `${BASE_URL}/${CHAMPS_PREFIX}`;
export const ICC_API_URL = `${BASE_URL}/${ICC_PREFIX}`;
export const SSO_API_URL = `${BASE_URL}/${SSO_PREFIX}`;

// Feature flags
export const FEATURE_FLAGS = {
    APPS: {
        CHAMPS: true,
        ICC: true,
        USER_MANAGEMENT: true,
    },
    ICC: {},
    USER_MANAGEMENT: {},
};

export const DOCUMENTS_URL = {
    PRIVACY_POLICY_URL: `https://www.sante-mobile.com/videoassets/resources/privacy-policy-fr.pdf`,
};
