import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { Participant } from 'icc/types/user';
import { BACKEND_ROUTES } from '../constants/api_routes';

export interface IParticipantsState {
    participants: Participant[];
    status: API_STATUS;
    error: string | null;
}

export const initialState: IParticipantsState = {
    participants: [],
    status: API_STATUS.IDLE,
    error: null,
};

export interface ParticipantsResponseSuccess {
    participants: Participant[];
}

export const getParticipants = createAsyncThunk('icc/get/participants', (_, { dispatch }) => {
    dispatch(setLoadingAction());

    return axios
        .get<ParticipantsResponseSuccess>(BACKEND_ROUTES.PARTICIPANTS, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch(setParticipantsAction(response.data));
        })
        .catch((e: Error) => {
            console.log('participantsSlice error', e);
            dispatch(setErrorAction(e.message));
        });
});

export const slice = createSlice({
    name: 'icc-participants',
    initialState,
    reducers: {
        setParticipantsAction(state, action: PayloadAction<ParticipantsResponseSuccess>) {
            state.participants = action.payload.participants;
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
    },
});

export const { setParticipantsAction, setErrorAction, setLoadingAction } = slice.actions;

export default slice.reducer;
