import styled from 'styled-components';

import { colours } from '../constants/colours';
import { sizes } from '../constants/mediaSizes';

export const GenericContainer = styled.div`
    display: flex;
    flex: 1;
`;

export const CenteredContainer = styled.div({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 3rem',
    backgroundColor: colours.global.lightGrey,
});

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 48px;
    background-color: ${colours.background};

    &.hidden-overflow {
        overflow: hidden;
    }
`;

export const Title = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
`;

export const TitleModal = styled(Title)`
    margin: 0;
    margin-bottom: 6px;
`;

export const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin-top: 8px;
`;

export const TableRefiner = styled.div`
    font-size: small;
    width: 100%;
    overflow-x: scroll;
    table {
        position: inherit;
    }
    thead {
        background-color: #f6f8fa;
    }
    tr {
        border-left: 1px solid #d6dadd;
        border-right: 1px solid #d6dadd;
    }
    th {
        vertical-align: top;
        word-break: normal;
        line-height: normal;
        font-size: small;
    }
    td {
        font-size: small;
        vertical-align: top;
        word-break: normal;
        &:first-child ${Pagination} {
            justify-content: center !important;
        }
        & table td ${Pagination} {
            justify-content: center !important;
        }
        &:first-child ${Title} {
            justify-content: space-between;
        }
    }

    @media ${sizes.M} {
        th,
        td {
            font-size: initial;
        }
    }
`;

export const BodyText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 12.5px 0px;
`;

export const ButtonStyles = {
    backgroundColor: colours.activeButton,
    color: colours.black,
    border: `solid 2px ${colours.black}`,
};

export const DeleteButtonStyles = {
    backgroundColor: colours.deleteButtonPink,
    color: colours.error,
};

export const DisabledButtonStyles = {
    backgroundColor: colours.greyButton,
    color: colours.black,
};

export const dropShadow = `0px 2px 5px ${colours.dropShadow}`;

export const modalShadow = `0px 2px 5px 0px ${colours.modalShadow}`;

export interface IModalContainerProps {
    backgroundColor?: string;
}

export const zIndexModal = 100;

export const CenteredModalContainer = styled.div<IModalContainerProps>`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: ${zIndexModal};
    background-color: ${(props) => props.backgroundColor ?? colours.modalBackground};
`;

export const ModalContent = styled.div`
    display: flex;
`;

export const DummyContainer = styled.div`
    display: flex;
`;
