import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface SessionType {
    id: number;
    unlock: string;
    userId: number;
    sessionId: number;
    completedAt: string;
    createdAt: string;
    startedAt?: string;
    sequence: number;
}

export interface PrimaryIntervenantType {
    firstName: string;
    lastName: string;
}

export interface PrimaryIntervenantType {
    firstName: string;
    lastName: string;
}
export interface ParticipantType {
    id: number;
    active: boolean;
    firstName: string;
    lastName: string;
    phone: string | null;
    email: string;
    extension: string | null;
    lastSignin: string;
    createdAt: string;
    unlock: string;
    latestSession: SessionType | null;
    intervenant: PrimaryIntervenantType | null;
    admin?: {
        id: number;
        firstName: string;
        lastName: string;
    };
}

export interface IParticipantsState {
    participants: ParticipantType[];
    error: string | null;
    loading: boolean;
}

export const initialState: IParticipantsState = {
    participants: [],
    loading: false,
    error: null,
};

export const getParticipants = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    ParticipantType[],
    unknown,
    { rejectValue: string }
>('get/participants', async (sortingOptions, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: ParticipantType[] }>(BACKEND_ROUTES.PARTICIPANTS, {
            params: sortingOptions,
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        });
        return response.data.data;
    } catch (e) {
        console.log('participantsSlice error', e);
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const slice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getParticipants.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getParticipants.fulfilled, (state, { payload }) => {
            state.participants = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getParticipants.rejected, (state, { payload }) => {
            if (payload) {
                state.participants = [];
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
