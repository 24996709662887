import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Login } from './pages/Login';
import { ResetPassword } from './pages/ResetPassword';
import { ROUTES } from './constants/routes';
import PrivateRoute from './components/PrivateRoute';
import { Home } from './pages/Home';
import ChampsApp from './champs/ChampsApp';
import IccApp from './icc/IccApp';
import UserManagementApp from './user_management/UserManagementApp';
import { CreatePasswordPage } from 'pages/CreatePassword';
import { ERoleType } from 'types';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';

const Routes: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route
                exact
                path={ROUTES.PRIVACY_POLICY}
                render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
                exact
                path={ROUTES.RESET_PASSWORD}
                render={(props) => <ResetPassword {...props} />}
            />
            <Route
                exact
                path={ROUTES.CREATE_PASSWORD}
                render={(props) => <CreatePasswordPage {...props} />}
            />
            <PrivateRoute path={ROUTES.HOME} Component={Home} />

            <PrivateRoute path={ROUTES.CHAMPS.ROOT} Component={ChampsApp} />
            <PrivateRoute path={ROUTES.ICC.ROOT} Component={IccApp} />
            <PrivateRoute
                path={ROUTES.USER_MANAGEMENT.ROOT}
                roles={[ERoleType.SUPER_ADMIN, ERoleType.ADMIN]}
                Component={UserManagementApp}
            />
            <Route exact path={ROUTES.LOGIN} render={(props) => <Login {...props} />} />
            <Route exact path="/">
                <Redirect to={ROUTES.HOME} />
            </Route>
            <Route>
                <Redirect to={ROUTES.HOME} />
            </Route>
        </Switch>
    );
};

export default Routes;
