import { CHAMPS_API_URL } from '../../env';

export const BACKEND_ROUTES = {
    INTERVENANTS: `${CHAMPS_API_URL}${'/intervenant/list'}`,
    PARTICIPANTS: `${CHAMPS_API_URL}${'/participants/list'}`,
    PARTICIPANT_DETAIL: `${CHAMPS_API_URL}${'/participants/'}`,
    INTERVENANT_DETAIL: `${CHAMPS_API_URL}${'/intervenant/'}`,
    DASHBOARD_STATS: `${CHAMPS_API_URL}${'/dashboard/stats'}`,
    USERS: `${CHAMPS_API_URL}${'/users'}`,
    ADD_RELATIONSHIP_NEW_PARTICIPANT: `${CHAMPS_API_URL}${'/intervenant-participant-assignment/assign-new-participant-intervenant'}`,
    MANAGE_RELATIONSHIP_INTERVENANT: `${CHAMPS_API_URL}${'/intervenant-participant-assignment/manage-participant-intervenants'}`,
    MANAGE_RELATIONSHIP_PARTICIPANT: `${CHAMPS_API_URL}${'/intervenant-participant-assignment/manage-intervenant-participants'}`,
    EDIT_PROFILE: `${CHAMPS_API_URL}${'/users/update'}`,
    EDIT_EMAIL: `${CHAMPS_API_URL}${'/users/update-email'}`,
    SESSIONS_LIST: `${CHAMPS_API_URL}${'/sessions/list'}`,
    EXPORT_DB: `${CHAMPS_API_URL}${'/database/export-db'}`,
    EXPORT_CSV: `${CHAMPS_API_URL}${'/database/export-csv'}`,
};
