import React from 'react';
import { useTranslation } from 'react-i18next';

import { QAContainer, ABlock, RecessedBlock, SelectedOrderBlock } from './styles';
import {
    ElementType,
    RouteSwitch,
    SegmentName,
    UserResponse,
    SectionOneSegment,
} from '../../types/segment';
import { setResponseButtonText } from '../../helpers/response-button-helpers';
import {
    isMultiQuestionTrueFalseQuiz,
    hasSelectedOrder,
    isEmojiQuiz,
} from '../../helpers/journey-helpers';

import { TextIfExists } from '../TextIfExists';

interface IProps {
    userElementType: ElementType;
    userResponses: UserResponse[];
    segmentName: SegmentName;
    routeSwitch?: RouteSwitch | null;
    activeQuestion?: number;
    isForm: boolean;
}

export const QuizUserResponsesBlock: React.FC<IProps> = ({
    segmentName,
    userElementType,
    userResponses,
    routeSwitch,
    activeQuestion,
    isForm,
}) => {
    const { t, i18n } = useTranslation();
    const questionNumber = activeQuestion ?? 1;
    const routeSwitchPath = routeSwitch ?? null;
    const routeTranslationKey = routeSwitch ? `.${routeSwitch}` : '';
    const responseText = (userResponse: UserResponse) => {
        const useRoute = i18n.exists(
            `icc.${segmentName}.${userElementType}${routeTranslationKey}.question${questionNumber}.text`,
        );
        return setResponseButtonText(
            userResponse,
            userElementType,
            segmentName,
            questionNumber,
            t,
            routeSwitchPath,
            useRoute,
        );
    };

    const sortedUserResponses = hasSelectedOrder(userElementType, userResponses)
        ? userResponses.sort(function (a, b) {
              if (a.selectedOrder !== undefined && b.selectedOrder !== undefined) {
                  return a.selectedOrder - b.selectedOrder;
              } else {
                  return a.order - b.order;
              }
          })
        : userResponses.sort(function (a, b) {
              return a.order - b.order;
          });
    return (
        <>
            <QAContainer>
                <TextIfExists
                    textString={`icc.${segmentName}.${userElementType}.${routeSwitch}.question${questionNumber}.text`}
                    qBlock={true}
                    mark={!isForm}
                />
                <TextIfExists
                    textString={`icc.${segmentName}.${userElementType}.question${questionNumber}.text`}
                    qBlock={true}
                    mark={!isForm}
                />
                <TextIfExists
                    textString={`icc.${segmentName}.${userElementType}.question${questionNumber}.subtitle`}
                    qBlock={false}
                    mark={false}
                />
                {sortedUserResponses.map((response) => {
                    const answer = responseText(response);
                    return (
                        <>
                            {isEmojiQuiz(userElementType) ? (
                                <>
                                    <ABlock>{answer}</ABlock>
                                    <RecessedBlock>
                                        {t(`sessionRecaps.Marks.answer`)}{' '}
                                        {t(
                                            `icc.${segmentName}.${userElementType}.${response.selectedOrder}_selectedOrder`,
                                        )}
                                    </RecessedBlock>
                                </>
                            ) : (
                                <>
                                    {isForm && (
                                        <TextIfExists
                                            textString={`icc.${segmentName}.${userElementType}${routeTranslationKey}.question${questionNumber}.${response.responseKey}`}
                                            qBlock={true}
                                            mark={false}
                                        />
                                    )}

                                    <ABlock>
                                        {t(`sessionRecaps.Marks.answer`)}
                                        {response.selectedOrder &&
                                        segmentName !== SectionOneSegment.SEGMENT_6
                                            ? ` #${response.selectedOrder}. `
                                            : ''}
                                        {answer}
                                        {response.selectedOrder &&
                                            segmentName === SectionOneSegment.SEGMENT_6 && (
                                                <SelectedOrderBlock>
                                                    {` - #${response.selectedOrder}   ${t(
                                                        `global.selectedOrder`,
                                                    )}`}
                                                </SelectedOrderBlock>
                                            )}
                                    </ABlock>

                                    {isMultiQuestionTrueFalseQuiz(userElementType) && (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.correctAnswer`)}
                                            {t(
                                                `icc.${segmentName}.${userElementType}${routeTranslationKey}.question${questionNumber}.answerTitle`,
                                            )}
                                        </ABlock>
                                    )}
                                </>
                            )}
                        </>
                    );
                })}
            </QAContainer>
        </>
    );
};
