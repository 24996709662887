import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Button } from '@osedea/reactor';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { TableRefiner, ButtonStyles } from '../../styles/common';

import { IntervenantParticipantType } from '../../slices/intervenantDetailsSlice';

import { ROUTES } from '../../constants/routes';

interface IProps {
    participants: IntervenantParticipantType[];
    onChangeSort: (sortingOption: SortOptions) => void;
    sorting: SortOptions;
}

export const IntervenantDetailParticipantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { participants, onChangeSort, sorting } = props;

    const headers = [
        {
            label: t('components.IntervenantTable.headers.order'),
            id: 'order',
            sortable: false,
        },
        {
            label: t('components.ParticipantTable.headers.name'),
            id: 'name',
            sortable: false,
        },
        {
            label: t('components.ParticipantTable.headers.email'),
            id: 'email',
            sortable: false,
        },
        {
            label: t('components.ParticipantTable.headers.phone'),
            id: 'phone',
            sortable: false,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const readOnlyRow = (item: IntervenantParticipantType) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        ...(item.phone && { phone: item.phone.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') }),
        order: item.order,
        controls: (
            <Button
                onClick={() => {
                    history.push(ROUTES.CHAMPS.PARTICIPANT, { participantId: item.id });
                }}
                style={ButtonStyles}
            >
                {t('components.ParticipantTable.detail')}
            </Button>
        ),
    });

    const preparedRows = (items: IntervenantParticipantType[]) => items.map(readOnlyRow);

    const orderHandler = (currentSortOrder: SortOptions) => {
        const inactiveSortOrder = currentSortOrder.sortOrder === 'INACTIVE';
        const sortBy = inactiveSortOrder ? 'id' : currentSortOrder.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortOrder.sortOrder;

        onChangeSort({ sortBy, sortOrder });
    };

    return (
        <React.Fragment>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(participants)}
                    onSort={orderHandler}
                    sortOptions={sorting}
                    emptyCellValue={'-'}
                />
            </TableRefiner>
        </React.Fragment>
    );
};
