import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Material UI
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import { IUser } from 'types/users';
import { EditUser } from 'user_management/components/EditUser';

import { Container } from './styles';
import { resetEditUser } from 'user_management/slices/editUserSlice';

interface IProps {}

type LocationWithState = { state: { user: IUser } };

export const EditUserPage: React.FC<IProps> = (prop) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.goBack();
    };

    const handleOnEditSuccess = () => {
        dispatch(resetEditUser());
        history.goBack();
    };

    const user = (location as LocationWithState).state.user;

    return (
        <Container>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" onClick={handleNavClick}>
                    {'\u2190 '}
                    {t('pages.EditUser.navUserManagement')}
                </Link>
                <Typography color="textPrimary">{t('pages.EditUser.navLocation')}</Typography>
            </Breadcrumbs>
            <EditUser user={user} onEditSuccess={handleOnEditSuccess} />
        </Container>
    );
};
