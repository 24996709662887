export interface IError {
    message: string;
}

export interface ISliceError {
    error: string | string[];
}

export type AppName = 'champs' | 'icc';

export enum ELanguage {
    EN = 'en',
    FR = 'fr',
}

export enum ERoleType {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    INTERVENANT = 'INTERVENANT',
    PARTICIPANT = 'PARTICIPANT',
}

export enum AppType {
    CHAMPS = 'champs',
    ICC = 'icc',
}

export interface SingleEvent<T> {
    content: T;
    hasBeenHandled: boolean;
}

export function consumeSingleEvent<T>(event: SingleEvent<T>): SingleEvent<T> {
    return { content: event.content, hasBeenHandled: true };
}

export function createSingleEvent<T>(content: T, hasBeenHandled = false): SingleEvent<T> {
    return { content, hasBeenHandled };
}
