import { authenticationService } from './authenticationService';

export function authHeader() {
    const currentUser = authenticationService.currentUserValue;
    const token = localStorage.getItem('api_token');
    if (currentUser && token) {
        return { authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

export enum API_STATUS {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
}
