import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { ERoleType } from 'types';
import { authenticationService } from 'helpers/authenticationService';

interface IProps {
    Component: React.FC<RouteComponentProps>;
    roles?: ERoleType[];
}

const PrivateRoute: React.FC<IProps & RouteProps> = ({ Component, roles, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                const currentUser = authenticationService.currentUserValue;

                if (!currentUser) {
                    return (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    );
                }

                if (roles && roles.indexOf(currentUser.role) === -1) {
                    return <Redirect to={{ pathname: '/' }} />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
