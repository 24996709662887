import styled from 'styled-components';

export const Container = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const Item = styled.div`
    margin-right: 2rem;
`;
