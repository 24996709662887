import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Link } from '@material-ui/core';

import { Container, TableTitle } from './styles';
import { RootState } from 'store';
import { IntervenantDetailsContext } from 'icc/constants/context';
import { getAnIntervenant } from 'icc/slices/intervenantSlice';
import { IntervenantProfile } from 'icc/components/IntervenantProfile';
import { ParticipantWithOrderTable } from 'icc/components/ParticipantWithOrderTable';

interface IProps {}
interface NavState {
    intervenantId: number;
}

export const IntervenantDetailsPage: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<NavState>();
    const intervenant = useSelector((state: RootState) => state.iccApp.intervenant.intervenant);

    useEffect(() => {
        if (!location.state.intervenantId) {
            history.goBack();
        } else {
            dispatch(getAnIntervenant({ intervenantId: location.state.intervenantId }));
        }
    }, [dispatch, history, location]);

    const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.goBack();
    };

    return (
        <IntervenantDetailsContext.Provider value={intervenant}>
            <Container>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" onClick={handleNavClick}>
                        {'\u2190 '}
                        {t('global.back')}
                    </Link>
                </Breadcrumbs>
                <IntervenantProfile>
                    <>
                        <TableTitle>
                            {t('pages.ICC.IntervenantProfile.participantTableTitle')}
                        </TableTitle>
                        <ParticipantWithOrderTable />
                    </>
                </IntervenantProfile>
            </Container>
        </IntervenantDetailsContext.Provider>
    );
};
