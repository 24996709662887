import styled from 'styled-components';

export const GoalTitle = styled.div`
    font-weight: bold;
    font-size: large;
`;

export const FeedbackTitle = styled.div`
    font-weight: bold;
    font-size: larger;
    margin: 15px 0;
`;
