import { TFunction } from 'i18next';

import {
    UserResponse,
    SegmentName,
    ElementType,
    RouteSwitch,
    ResponseType,
} from '../types/segment';

import { isMultiQuestionTrueFalseQuiz } from './journey-helpers';

export const setResponseButtonText = (
    userResponse: UserResponse,
    currentElementType: ElementType,
    segment: SegmentName,
    questionNumber: number,
    t: TFunction,
    routeSwitch?: RouteSwitch | null,
    useRoute?: boolean,
) => {
    let responseButtonText = '';
    const path = routeSwitch && useRoute ? `.${routeSwitch}` : '';
    if (userResponse.responseType === ResponseType.INPUT && userResponse.input) {
        responseButtonText = userResponse.input;
    } else if (userResponse.responseType === ResponseType.KEY_INPUT && userResponse.input) {
        responseButtonText = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponse.responseKey}_answered`,
            { response: userResponse.input },
        );
    } else if (isMultiQuestionTrueFalseQuiz(currentElementType)) {
        responseButtonText = t(`global.${userResponse.responseKey}`);
    } else {
        responseButtonText = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponse.responseKey}`,
        );
    }
    return responseButtonText;
};

export const setMultiResponseRaceResponseText = (
    userResponses: UserResponse[],
    currentElementType: ElementType,
    segment: SegmentName,
    questionNumber: number,
    t: TFunction,
    routeSwitch?: RouteSwitch | null,
) => {
    let responseButtonText = '';
    const path = routeSwitch ? `.${routeSwitch}` : '';
    if (userResponses.length === 2) {
        const response1 = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponses[0].responseKey}`,
        );

        const response2 = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponses[1].responseKey}`,
        );
        responseButtonText = `${response1} ${t(`global.and`)} ${response2}`;
    } else if (userResponses.length > 2) {
        const responses = '';
        userResponses.forEach((response) => {
            return `${t(
                `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${response.responseKey}`,
            )} `;
        });

        const endResponse = `${t(`global.and`)} ${t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${
                userResponses[userResponses.length - 1].responseKey
            }`,
        )}`;
        responseButtonText = responses + endResponse;
    } else {
        responseButtonText = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponses[0].responseKey}`,
        );
    }
    return responseButtonText;
};

export const setRaceResponseText = (
    userResponse: UserResponse,
    currentElementType: ElementType,
    segment: SegmentName,
    questionNumber: number,
    t: TFunction,
    routeSwitch?: RouteSwitch | null,
) => {
    let responseButtonText = '';
    const path = routeSwitch ? `.${routeSwitch}` : '';
    if (userResponse.responseType === ResponseType.INPUT && userResponse.input) {
        responseButtonText = userResponse.input;
    } else if (userResponse.responseType === ResponseType.KEY_INPUT && userResponse.input) {
        responseButtonText = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponse.responseKey}_answered`,
            { response: userResponse.input },
        );
    } else {
        responseButtonText = t(
            `icc.${segment}.${currentElementType}${path}.question${questionNumber}.${userResponse.responseKey}`,
        );
    }
    return responseButtonText;
};
