import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { dropShadow } from '../../styles/common';

export const StatusCardContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colours.white};
    border-radius: 10px;
    box-shadow: ${dropShadow};
    height: 85px;
`;

export const IconContainer = styled.div`
    margin: 0 20px;
`;

export const CardNumber = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: ${colours.numberGrey};
`;

export const CardText = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: ${colours.textGrey};
`;
