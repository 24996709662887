import { DesignSystem } from '@osedea/reactor';

export const theme = {
    ...DesignSystem,
    colors: {
        ...DesignSystem.colors,
        white: '#FFFFFF',
        primary: {
            light: '#F2F2F2',
            main: '#0b4170',
            hover: '#3578b3',
            focus: '#062642',
            dark: '#04192A',
            disabled: '#F2F2F2',
        },
    },
};
