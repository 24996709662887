import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserElement, RouteSwitch, SegmentName } from '../../types/segment';
import { QuizMultiQuestionBlock } from '../QuizMultiQuestionBlock';
import { RaceResponsesBlock } from '../RaceResponsesBlock';

import { TitleBlock, SubTitleBlock } from './styles';

import {
    isOneQuestionQuiz,
    isMultiQuestionQuiz,
    isRace,
    isDependencyScore,
    isModuleFinalQuiz,
} from '../../helpers/journey-helpers';

interface IProps {
    segmentRecap: UserElement[];
    segmentName: SegmentName;
    routeSwitch: RouteSwitch | null;
}

export const SegmentResponsesBlock: React.FC<IProps> = ({
    segmentRecap,
    segmentName,
    routeSwitch,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {segmentRecap.map((element, index) => {
                if (isOneQuestionQuiz(element.type) || isMultiQuestionQuiz(element.type)) {
                    let textRoute = null;
                    if (isModuleFinalQuiz(element.type, segmentName)) {
                        textRoute = RouteSwitch.ACCOMPLISHED;
                        if (
                            segmentRecap[index - 1].responses?.[0].responseKey ===
                            RouteSwitch.MORE_OR_LESS_ACCOMPLISHED.toString()
                        ) {
                            textRoute = RouteSwitch.MORE_OR_LESS_ACCOMPLISHED;
                        }
                    }
                    return (
                        <QuizMultiQuestionBlock
                            segmentName={segmentName}
                            userElement={element}
                            key={`${segmentName}_${element.type}`}
                            routeSwitch={textRoute ?? routeSwitch}
                            seperator={segmentRecap.length > 1 && index < segmentRecap.length - 1}
                        />
                    );
                } else if (isRace(element.type)) {
                    return (
                        <RaceResponsesBlock
                            segmentName={segmentName}
                            userElement={element}
                            key={`${segmentName}_${element.type}`}
                            routeSwitch={routeSwitch}
                            seperator={segmentRecap.length > 1 && index < segmentRecap.length - 1}
                        />
                    );
                } else if (isDependencyScore(element.type)) {
                    const dependencyScores = segmentRecap[index - 1].responses?.map((response) => {
                        return response.order;
                    });
                    if (dependencyScores && dependencyScores.length > 0) {
                        const dependencyScoresSum = dependencyScores.reduce((a, b) => a + b);
                        // EX: dependencyScoresSum = 10
                        const dependencyScore = dependencyScoresSum - dependencyScores.length; // Adjusted as each score is -1 of the order value (per client request)
                        // EX: dependencyScore = 5
                        return (
                            <>
                                <TitleBlock>
                                    {t(`icc.${segmentName}.${element.type}.title`)}
                                </TitleBlock>
                                <SubTitleBlock>
                                    {t(`icc.${segmentName}.${element.type}.text`, {
                                        dependencyScore,
                                        defaultValue: '6',
                                    })}
                                </SubTitleBlock>
                            </>
                        );
                    } else {
                        return <></>;
                    }
                } else {
                    return <></>;
                }
            })}
        </>
    );
};
