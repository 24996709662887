import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { IntervenantWithOrder } from 'icc/types/user';
import { BACKEND_ROUTES } from '../constants/api_routes';
import { getAParticipant } from './participantSlice';

export interface IIntervenantsState {
    status: API_STATUS;
    error: string | null;
}

export const initialState: IIntervenantsState = {
    status: API_STATUS.IDLE,
    error: null,
};

export const assignIntervenants = createAsyncThunk(
    'icc/put/assignIntervenants',
    (
        params: { participantId: number; selectedIntervenants: IntervenantWithOrder[] },
        { dispatch },
    ) => {
        dispatch(setLoadingAction());
        const { participantId, selectedIntervenants } = params;

        const axiosParams = {
            participantId,
        };

        return axios
            .post(
                BACKEND_ROUTES.PARTICIPANTS,
                {
                    selectedIntervenants,
                },
                {
                    params: axiosParams,
                    headers: authHeader(),
                },
            )
            .then(() => {
                dispatch(setSuccessAction());
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                dispatch(getAParticipant({ participantId }));
            })
            .catch((e: Error) => {
                console.log('assignIntervenantsSlice error', e);
                dispatch(setErrorAction(e.message));
            });
    },
);

export const resetAssignIntervenantsState = createAsyncThunk(
    'icc/reset-assignIntervenantsState',
    (_, { dispatch }) => {
        dispatch(resetAction());
    },
);

export const slice = createSlice({
    name: 'icc-assign-intervenants',
    initialState,
    reducers: {
        setSuccessAction(state) {
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
        resetAction(state) {
            state.status = API_STATUS.IDLE;
            state.error = null;
        },
    },
});

export const { setSuccessAction, setErrorAction, setLoadingAction, resetAction } = slice.actions;

export default slice.reducer;
