import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { RootState } from '../store';
import { IUser } from '../types/users/index';

import { IError } from '../types/index';

import { BACKEND_ROUTES } from '../constants/routes';

export interface IAuthState {
    user: IUser | null;
    errors: IError | string | null;
    loading: boolean;
}

export interface ILoginResponseSuccess {
    user: IUser;
    token: string;
}

export const initialState: IAuthState = {
    user: null,
    errors: null,
    loading: false,
};

export const loginUser = createAsyncThunk(
    'auth/login',
    (params: { email: string; password: string }, { dispatch }) => {
        axios
            .post<ILoginResponseSuccess>(BACKEND_ROUTES.LOGIN, {
                email: params.email,
                password: params.password,
            })
            .then((response) => {
                localStorage.setItem('api_token', response.data.token);
                dispatch(setUserAction(response.data));
            })
            .catch((err: AxiosError) => {
                dispatch(setErrorsAction(err.message));
            });
    },
);

// When user logs out, clear api token from local storage and set store to initial state
export const logoutUser = createAsyncThunk('auth/logout', (_, { dispatch }) => {
    localStorage.removeItem('api_token');
    dispatch(clearUserAction());
});

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserAction(state, action: PayloadAction<ILoginResponseSuccess>) {
            state.user = action.payload.user;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action) {
            state.user = null;
            state.errors = action.payload as string;
            state.loading = false;
        },
        clearUserAction(state) {
            state.user = null;
            state.loading = false;
            state.errors = null;
        },
    },
});

export const selectUser = (state: RootState) => state.auth.user;

export const { setUserAction, setErrorsAction, clearUserAction } = slice.actions;

export default slice.reducer;
