import React, { useEffect, useState } from 'react';
import { Button, TextField, withStyles, InputLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { colours } from 'constants/colours';
import { IUser } from 'types/users';
import { Container, Form, Grid, Title, AppPermissionWrapper, Apps, AppLogoWrapper } from './styles';

import LogoChamps from 'assets/logo-champs-round.png';
import LogoIcc from 'assets/logo-icc-round.png';
import { FEATURE_FLAGS } from 'env';
import { AppName, ERoleType } from 'types';
import { editUser } from 'user_management/slices/editUserSlice';
import { RootState } from 'store';
import { authenticationService } from 'helpers/authenticationService';
import ROLES from 'constants/roles';

interface IFormInput {
    firstName: string;
    lastName: string;
    phone: string;
    extension: string;
}

interface IProps {
    user: IUser;
    onEditSuccess?: () => void;
}

const unformatPhone = (phone: string | null): string | undefined => {
    if (!phone) return undefined;
    return phone.replaceAll('-', '');
};

const formatPhone = (phone: string | null): string | undefined => {
    if (!phone) return undefined;
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6)}`;
};

const getExtensionString = (extension: number | null): string | undefined => {
    if (!extension) return undefined;
    return `${extension}`;
};

const mapToNumber = (num: string): number | null => {
    const parsed = Number.parseInt(num, 10);

    if (Number.isNaN(parsed)) return null;
    return parsed;
};

const isSuperAdminOrAdmin = (role?: ERoleType) => {
    return role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN;
};

export const EditUser: React.FC<IProps> = ({ user, onEditSuccess }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const authUser = authenticationService.currentUserValue;

    const { register, handleSubmit, errors } = useForm<IFormInput>({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: unformatPhone(user.phone),
            extension: getExtensionString(user.extension),
        },
    });

    const [champsSelected, setChampsSelected] = useState(user.champs);
    const [iccSelected, setIccSelected] = useState(user.icc);

    const handleAppSelectedClicked = (appName: AppName) => {
        switch (appName) {
            case 'champs':
                setChampsSelected((prev) => !prev);
                break;
            case 'icc':
                setIccSelected((prev) => !prev);
                break;
            default:
                break;
        }
    };

    const editUserUser = useSelector((state: RootState) => state.userManagementApp.editUser.user);

    useEffect(() => {
        if (editUserUser) {
            onEditSuccess?.();
        }
    }, [editUserUser, onEditSuccess]);

    const onSubmit = (data: IFormInput) => {
        const updatedFields: { [key: string]: any } = {};

        if (data.firstName !== user.firstName) {
            updatedFields.firstName = data.firstName;
        }
        if (data.lastName !== user.lastName) {
            updatedFields.lastName = data.lastName;
        }
        if (formatPhone(data.phone) !== user.phone) {
            updatedFields.phone = formatPhone(data.phone);
        }

        if (user.extension) {
            if (`${user.extension}` !== data.extension) {
                updatedFields.extension = mapToNumber(data.extension);
            }
        } else {
            if (data.extension.length !== 0) {
                updatedFields.extension = mapToNumber(data.extension);
            }
        }

        if (user.champs !== champsSelected) {
            updatedFields.champs = champsSelected;
        }

        if (user.icc !== iccSelected) {
            updatedFields.icc = iccSelected;
        }

        if (Object.keys(updatedFields).length === 0) {
            return;
        }
        dispatch(editUser({ userId: user.id, updatedFields }));
    };

    return (
        <>
            <Container>
                <Title>{t('pages.EditUser.title')}</Title>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid>
                        <TextField
                            name="firstName"
                            label={t('components.AddUser.firstName')}
                            inputRef={register}
                            required
                        />
                        <TextField
                            name="lastName"
                            label={t('components.AddUser.lastName')}
                            inputRef={register}
                            required
                        />
                        <TextField
                            name="email"
                            label={user.email}
                            inputRef={register({
                                required: false,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Enter a valid e-mail address',
                                },
                            })}
                            type={'email'}
                            disabled={true}
                        />
                        <TextField
                            name="phone"
                            label={t('components.AddUser.phone')}
                            inputRef={register({
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                            })}
                            helperText={errors.phone ? t('components.AddUser.errorPhone') : ''}
                            error={Boolean(errors.phone)}
                            required
                        />
                        <TextField
                            name="extension"
                            label={t('components.AddUser.extension')}
                            inputRef={register}
                        />
                        <AppPermissionWrapper>
                            <InputLabel shrink>Champs - Icc</InputLabel>
                            <Apps>
                                <AppLogoWrapper
                                    style={champsSelected ? ActiveLogoStyle : {}}
                                    onClick={() => handleAppSelectedClicked('champs')}
                                >
                                    <img
                                        src={LogoChamps}
                                        style={ImageLogoStyle}
                                        alt={'Round Logo iCanChange'}
                                    />
                                </AppLogoWrapper>
                                <AppLogoWrapper
                                    style={iccSelected ? ActiveLogoStyle : {}}
                                    onClick={() => handleAppSelectedClicked('icc')}
                                    featureFlag={FEATURE_FLAGS.APPS.ICC}
                                >
                                    <img
                                        src={LogoIcc}
                                        style={ImageLogoStyle}
                                        alt={'Round Logo iCanChange'}
                                    />
                                </AppLogoWrapper>
                            </Apps>
                        </AppPermissionWrapper>
                        {!isSuperAdminOrAdmin(authUser?.role) ? (
                            <TextField
                                disabled={true}
                                name="admin"
                                label={t('global.admin')}
                                value={`${user.admin?.firstName} ${user.admin?.lastName}`}
                            />
                        ) : null}
                    </Grid>
                    <PrimaryButton
                        type={'submit'}
                        variant={'contained'}
                        style={{ alignSelf: 'center' }}
                        onClick={() => handleSubmit(onSubmit)}
                    >
                        {t('pages.UserManagement.updateUser')}
                    </PrimaryButton>
                </Form>
            </Container>
        </>
    );
};

const PrimaryButton = withStyles(() => ({
    root: {
        marginTop: '3rem',
        color: colours.white,
        backgroundColor: colours.global.blue,
        '&:hover': {
            backgroundColor: colours.global.lightBlue,
        },
    },
}))(Button);

const ImageLogoStyle = { width: 48, height: 48 };
const ActiveLogoStyle = { filter: 'none', opacity: 1 };
