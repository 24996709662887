import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const QAContainer = styled.div`
    margin-bottom: 24px;
`;

export const QBlock = styled.div`
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 24px;
`;

export const ABlock = styled.div`
    line-height: 24px;
`;

export const YesNoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 30px;
`;

export const YesContainer = styled.div`
    background-color: ${colours.completedGreen};
    padding: 25px;
    border-radius: 5px;
`;

export const NoContainer = styled.div`
    background-color: ${colours.deleteButtonPink};
    padding: 25px;
    border-radius: 5px;
`;

export const YesNoTitle = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: large;
    padding-bottom: 5px;
`;

export const YesNoItem = styled.li`
    padding: 5px 0;
`;
