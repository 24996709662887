import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface IntervenantParticipantType {
    id: number;
    firstName: string;
    lastName: string;
    phone: string | null;
    order: number;
    email: string;
}

export interface IntervenantDetailsType {
    id: number;
    active: boolean;
    firstName: string;
    lastName: string;
    phone: string | null;
    extension: number | null;
    lastSignin: string;
    createdAt: string;
    notifications: null;
    email: string;
    participants: IntervenantParticipantType[] | [];
    admin?: {
        id: number;
        firstName: string;
        lastName: string;
    };
}
export interface ISuccessResponse {}
export interface IIntervenantDetailsState {
    intervenant: IntervenantDetailsType | null;
    error: string | null;
    loading: boolean;
}

export interface ParticipantsSelectionObject {
    participantId: number;
    selected: boolean;
}
export const initialState: IIntervenantDetailsState = {
    intervenant: null,
    loading: false,
    error: null,
};

export const getIntervenantDetails = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    IntervenantDetailsType,
    unknown,
    { rejectValue: string }
>('get/intervenant-details', async (intervenantId, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data: IntervenantDetailsType }>(
            `${BACKEND_ROUTES.INTERVENANT_DETAIL}${intervenantId}`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            },
        );
        return response.data.data;
    } catch (e) {
        console.log('intervenantDetailsSlice error', e);
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const manageIntervenantRelationships = createAsyncThunk(
    'manage/intervenant-relationship',
    async (
        params: {
            intervenantId: number;
            participantsSelection: ParticipantsSelectionObject[] | [];
        },
        { dispatch, rejectWithValue },
    ) => {
        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        };
        const valuesToUpdate = {
            intervenantId: params.intervenantId,
            participantsSelection: params.participantsSelection,
        };
        try {
            await axios.post<ISuccessResponse>(
                BACKEND_ROUTES.MANAGE_RELATIONSHIP_PARTICIPANT,
                valuesToUpdate,
                headers,
            );
            const intervenantId = params.intervenantId;
            await dispatch(getIntervenantDetails(intervenantId));
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);
export const slice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getIntervenantDetails.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getIntervenantDetails.fulfilled, (state, { payload }) => {
            state.intervenant = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getIntervenantDetails.rejected, (state, { payload }) => {
            if (payload) {
                state.intervenant = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
