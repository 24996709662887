import React from 'react';

import { StatusCardContainer, IconContainer, CardNumber, CardText } from './styles';
import Icon, { IconType } from 'components/Icon';

export interface IProps {
    icon: IconType;
    number: number | undefined;
    title: string;
    isLoading: boolean;
}

const StatusCard: React.FC<IProps> = (props: IProps) => {
    const { icon, number, title, isLoading } = props;

    return (
        <StatusCardContainer>
            <IconContainer>
                <Icon icon={icon} />
            </IconContainer>
            <div>
                <CardNumber>{isLoading ? '...' : number}</CardNumber>
                <CardText>{title}</CardText>
            </div>
        </StatusCardContainer>
    );
};

export default StatusCard;
