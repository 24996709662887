import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { dropShadow } from '../../styles/common';

import { sizes } from '../../constants/mediaSizes';

export const Greeting = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
`;

export const Welcome = styled.h2`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.darkGrey};
    margin: 0;
`;

export const StatusBox = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 100%;
    margin: 35px 0;
    justify-items: stretch;
    align-items: stretch;
    gap: 20px;

    @media ${sizes.M} {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    @media ${sizes.G} {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    @media ${sizes.XG} {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }

    @media ${sizes.XXG} {
        grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    }
`;

export const TableContainer = styled.div`
    background-color: ${colours.white};
    box-shadow: ${dropShadow};
    border-radius: 10px;
    width: 100%;
    margin-top: 35px;
    padding: 22px 35px;
`;

export const TableNav = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
`;

export const TabContainer = styled.div`
    min-width: 250px;
    flex: 1;
`;

export const TableTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    flex: 1;
`;

export const EmptyElement = styled.div`
    flex: 1;
`;

export const IconContainer = styled.div`
    margin-right: 13px;
`;
