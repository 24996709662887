import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
    IQuestion,
    IUserResponse,
    IResponse,
} from '../../types/sessionsRecaps';
import { SmartRecapText } from './styles';
// Helpers
import {
    isResponseTypeKeyInput,
    isResponseTypeInput,
    isQuestionTypeMultipleMany,
    formatManyResponsesString,
} from '../../helpers/helpers';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | undefined;
    sessionId: number;
}

export const SmartRaceRecap: React.FC<IProps> = ({ sessionRecap, sessionId }) => {
    const { t, i18n } = useTranslation();

    const getRecap = () => {
        let recap: ISessionFourRecap | ISessionFiveRecap | undefined;
        switch (sessionId) {
            case 4:
                recap = sessionRecap as ISessionFourRecap;
                break;
            case 5:
                recap = sessionRecap as ISessionFiveRecap;
                break;
            default:
                return undefined;
        }
        return recap;
    };
    /**
     * @function
     * @name setSelectedResponseButtonText
     * @description In the race paragraph set the text strings for the response entries based on response type and if it has been answered
     */
    const setSelectedResponseButtonText = (
        question: IQuestion,
        responses: IResponse[],
        userResponses: IUserResponse[],
        raceNumber: string,
    ) => {
        let responseButtonText = '';
        const userResponse = userResponses[0];
        const existingResponse = responses.find(
            (response) => userResponse.responseId === response.id,
        );
        if (isQuestionTypeMultipleMany(question)) {
            const joinWord = t(`global.and`);
            const responseTextKeys = userResponses.map((userResponded) => {
                const existingResponsed = responses.find(
                    (response) => userResponded.responseId === response.id,
                );
                return existingResponsed?.textkey
                    ? t(
                          `sessionRecaps.RaceQuestions.${raceNumber}.${question.textkey}.${existingResponsed.textkey}`,
                      )
                    : '';
            });
            responseButtonText = formatManyResponsesString(responseTextKeys, joinWord);
        } else if (existingResponse && isResponseTypeInput(existingResponse)) {
            if (userResponse?.input) {
                responseButtonText = userResponse.input;
            }
        } else if (existingResponse && isResponseTypeKeyInput(existingResponse)) {
            if (userResponse?.input) {
                responseButtonText = t(
                    `sessionRecaps.RaceQuestions.${raceNumber}.${question.textkey}.${existingResponse.textkey}_answered`,
                    { response: userResponse.input },
                );
            } else {
                responseButtonText = t(
                    `sessionRecaps.RaceQuestions.${raceNumber}.${question.textkey}.${existingResponse.textkey}_unanswered`,
                );
            }
        } else if (existingResponse) {
            responseButtonText = t(
                `sessionRecaps.RaceQuestions.${raceNumber}.${question.textkey}.${existingResponse.textkey}`,
            );
        }
        return responseButtonText;
    };

    const smartRaceRecap = getRecap()?.smartRace;

    const raceArray = smartRaceRecap?.map((raceQuestion) => {
        let raceItem = '';
        const raceNumber = raceQuestion.textkey.split('_')[1];
        if (raceQuestion.response && raceQuestion.userResponse) {
            const raceResponse = setSelectedResponseButtonText(
                raceQuestion,
                raceQuestion.response,
                raceQuestion.userResponse,
                raceNumber,
            );
            const questionStart = i18n.exists(
                `sessionRecaps.RaceQuestions.${raceNumber}.${raceQuestion.textkey}.questionStart`,
            )
                ? t(
                      `sessionRecaps.RaceQuestions.${raceNumber}.${raceQuestion.textkey}.questionStart`,
                  )
                : '';

            const questionEnd = i18n.exists(
                `sessionRecaps.RaceQuestions.${raceNumber}.${raceQuestion.textkey}.questionEndt`,
            )
                ? t(`sessionRecaps.RaceQuestions.${raceNumber}.${raceQuestion.textkey}.questionEnd`)
                : '';
            raceItem = `${questionStart} ${raceResponse} ${questionEnd}`;
        }
        return raceItem;
    });
    let race = raceArray?.join(' ');
    const opener = t(`sessionRecaps.RaceQuestions.opener`);
    race = `${opener} ${race}`;
    if (smartRaceRecap?.[0]) {
        const endstatement = t(
            `sessionRecaps.RaceQuestions.${smartRaceRecap[0].textkey.split('_')[1]}.end_statement`,
        );
        race = `${race} ${endstatement}`;
    }
    return <SmartRecapText>{race} ;</SmartRecapText>;
};
