import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
    IBoosterBRecap,
} from '../../types/sessionsRecaps';

import {
    QAContainer,
    QBlock,
    ABlock,
    YesNoContainer,
    YesContainer,
    NoContainer,
    YesNoTitle,
    YesNoItem,
} from './styles';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | IBoosterBRecap
        | undefined;
    sessionId: number;
}

enum YesNo {
    Yes = 'Yes',
    No = 'No',
}

export const QABlock: React.FC<IProps> = ({ sessionRecap, sessionId }) => {
    const { t } = useTranslation();

    const sessionTwoRecap =
        sessionId === 2
            ? (sessionRecap as ISessionTwoRecap)
            : (([] as unknown) as ISessionTwoRecap);
    const sessionTwoRecapQuiz = (sessionTwoRecap.quiz as unknown) as IQARecap[];
    const sessionTwoRecapQA = (sessionTwoRecap.qa as unknown) as IQARecap[];
    const sessionThreeRecap =
        sessionId === 3
            ? ((sessionRecap as unknown) as IQARecap[])
            : (([] as unknown) as IQARecap[]);

    const sessionSixSmartFeedback =
        sessionId === 6
            ? ((sessionRecap as unknown) as IQARecap[])
            : (([] as unknown) as IQARecap[]);

    const sessionSixSmartWrapper =
        sessionId === 6
            ? ((sessionRecap as unknown) as IQARecap[])
            : (([] as unknown) as IQARecap[]);

    const session3Yes: string[] = [];
    const session3No: string[] = [];

    sessionThreeRecap.forEach((answers) => {
        if (answers[Object.keys(answers)[0]][0].includes(YesNo.Yes)) {
            session3Yes.push(Object.keys(answers)[0]);
        } else if (answers[Object.keys(answers)[0]][0].includes(YesNo.No)) {
            session3No.push(Object.keys(answers)[0]);
        }
    });
    const boosterARecap =
        sessionId === 7 ? (sessionRecap as IBoosterARecap) : (([] as unknown) as IBoosterARecap);

    const boosterARecapQA = (boosterARecap.qa as unknown) as IQARecap[];
    const boosterARecapSmartFeedback = (boosterARecap.smartFeedback as unknown) as IQARecap[];

    const boosterBRecap =
        sessionId === 8 ? (sessionRecap as IBoosterARecap) : (([] as unknown) as IBoosterBRecap);

    const boosterBRecapQA = (boosterBRecap.qa as unknown) as IQARecap[];

    return (
        <>
            {sessionId === 2 &&
                sessionTwoRecapQuiz.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.question`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
            {sessionId === 2 &&
                sessionTwoRecapQA.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.question`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
            {sessionId === 3 && (
                <YesNoContainer>
                    <YesContainer>
                        <YesNoTitle>{t(`sessionRecaps.Answer.yes`)}</YesNoTitle>
                        {session3Yes.map((answers) => {
                            return (
                                <YesNoItem>
                                    {t(`sessionRecaps.Question.${answers}.question`)}
                                </YesNoItem>
                            );
                        })}
                    </YesContainer>
                    <NoContainer>
                        <YesNoTitle>{t(`sessionRecaps.Answer.no`)}</YesNoTitle>
                        {session3No.map((answers) => {
                            return (
                                <YesNoItem>
                                    {t(`sessionRecaps.Question.${answers}.question`)}
                                </YesNoItem>
                            );
                        })}
                    </NoContainer>
                </YesNoContainer>
            )}
            {sessionId === 6 &&
                sessionSixSmartFeedback.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.question`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                }) &&
                sessionSixSmartWrapper.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.title`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
            {sessionId === 7 &&
                boosterARecapQA.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.title`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
            {sessionId === 7 &&
                boosterARecapSmartFeedback.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.question`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
            {sessionId === 8 &&
                boosterBRecapQA.map((answers) => {
                    return (
                        <QAContainer>
                            <QBlock>
                                {t(`sessionRecaps.Marks.question`)}
                                {t(`sessionRecaps.Question.${Object.keys(answers)[0]}.title`)}
                            </QBlock>
                            {(answers[Object.keys(answers)[0]] as string[]).map((answer) => {
                                if (typeof answer === 'string') {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {t(`sessionRecaps.Answer.${answer}`)}
                                        </ABlock>
                                    );
                                } else {
                                    return (
                                        <ABlock>
                                            {t(`sessionRecaps.Marks.answer`)}
                                            {answer}
                                        </ABlock>
                                    );
                                }
                            })}
                        </QAContainer>
                    );
                })}
        </>
    );
};
