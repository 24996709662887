import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { RootState } from 'store';

import { consumeSingleEvent, createSingleEvent, SingleEvent } from 'types';

import { ICreateUser, IUser } from 'types/users';

import { BACKEND_ROUTES } from 'user_management/constants/api_routes';
import { fetchUsers } from './usersSlice';

export interface ICreateUsersResponse {
    user: IUser;
}
interface FetchAdminsSuccess {
    admins?: Admin[];
}
export interface IApiError {
    message: string | null;
}

type Admin = Pick<IUser, 'id' | 'role' | 'firstName' | 'lastName'>;
export interface IUsersState {
    user: SingleEvent<IUser | null>;
    loading: boolean;
    errors: string | null;
    admins: Admin[];
}

const initialState: IUsersState = {
    user: createSingleEvent(null),
    loading: false,
    errors: null,
    admins: [],
};

export const createUser = createAsyncThunk(
    'user_management/createUser',
    async (params: { userData: ICreateUser }, { dispatch }) => {
        dispatch(setLoadingAction());

        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        };

        const body = { userData: params.userData };
        await axios
            .post<ICreateUsersResponse>(BACKEND_ROUTES.USERS, body, headers)
            .then((response) => {
                dispatch(setCreateUsersAction(response.data));

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                dispatch(fetchUsers());
            })
            .catch((error) => {
                let message: string | null = null;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.isAxiosError) {
                    message = (error as AxiosError<IApiError>).response?.data.message ?? null;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    message = error.message;
                }

                dispatch(setErrorsAction(message));
            });
    },
);

export const fetchAdmins = createAsyncThunk<{ admins?: Admin[] }, undefined>(
    'user_management/fetchAdmins',
    async (_, { rejectWithValue }) => {
        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        };
        try {
            const result = await axios.get<FetchAdminsSuccess>(BACKEND_ROUTES.ADMINS, headers);
            return result.data;
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const consumeCreateUserEvent = createAsyncThunk(
    'user_management/consumeCreateUserEvent',
    (_, { dispatch }) => {
        dispatch(consumeEvent());
    },
);

const slice = createSlice({
    name: 'user_management/createUser',
    initialState,
    reducers: {
        setCreateUsersAction(state, action: PayloadAction<ICreateUsersResponse>) {
            state.user = createSingleEvent(action.payload.user);
            state.loading = false;
            state.errors = null;
        },
        consumeEvent(state) {
            state.user = consumeSingleEvent(state.user);
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<string | null>) {
            state.user = createSingleEvent(null);
            state.loading = false;
            state.errors = action.payload;
        },
        setLoadingAction(state) {
            state.loading = true;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchAdmins.rejected, (state) => {
            state.admins = [];
        });
        builder.addCase(fetchAdmins.fulfilled, (state, { payload }) => {
            state.admins = payload.admins ?? [];
        });
    },
});

const { setCreateUsersAction, consumeEvent, setErrorsAction, setLoadingAction } = slice.actions;

export const selectAdmins = (state: RootState) => state.userManagementApp.createUser.admins;

export default slice.reducer;
