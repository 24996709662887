import React from 'react';
import { Button } from '@osedea/reactor';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';

import Icon from '../Icon';
import { Title, ButtonsContainer, IconContainer } from './styles';
import { ButtonStyles, DisabledButtonStyles } from '../../styles/common';
import { CloseButton } from '../AddUserModal/styles';
import { ROUTES } from '../../constants/routes';
import { deleteUser } from '../../slices/deleteUserSlice';
import ROLES from '../../constants/roles';

export interface IProps {
    open: boolean;
    onClose: () => void;
    role: string;
    userId: number;
    userName: string;
}

export const DeleteUserModal: React.FC<IProps> = ({ open, onClose, role, userId, userName }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = () => {
        onClose();
    };

    const handleDelete = () => {
        dispatch(deleteUser(userId));
        handleClose();
        const dashboardUrl = `${ROUTES.CHAMPS.DASHBOARD}`;
        history.push(dashboardUrl);
    };

    return (
        <Dialog open={open}>
            <CloseButton role="button" tabIndex={0} onClick={handleClose}>
                <Icon icon={'closeModal'} />
            </CloseButton>
            <Title>
                <IconContainer>
                    <Icon icon={'delete'} />
                </IconContainer>
                {role === ROLES.PARTICIPANT
                    ? t('components.DeleteUser.titleParticipant')
                    : t('components.DeleteUser.titleIntervenant')}
            </Title>
            <div>
                <Trans>
                    {role === ROLES.PARTICIPANT
                        ? t('components.DeleteUser.confirmationMessageParticipant', {
                              userName,
                          })
                        : t('components.DeleteUser.confirmationMessageIntervenant', {
                              userName,
                          })}
                </Trans>
            </div>
            <ButtonsContainer>
                <Button
                    onClick={handleClose}
                    style={{
                        ...DisabledButtonStyles,
                        margin: '0 8px',
                    }}
                >
                    {t('pages.UserDetail.cancel')}
                </Button>
                <Button
                    onClick={handleDelete}
                    style={{
                        ...ButtonStyles,
                        margin: '0 8px',
                    }}
                >
                    {t('components.AddUserLegacy.submit')}
                </Button>
            </ButtonsContainer>
        </Dialog>
    );
};
