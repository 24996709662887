import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@osedea/reactor';

import { useSelector, useDispatch } from 'react-redux';

import { InputEvent } from '@osedea/reactor/dist/types/index';

import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import { getIntervenants } from '../../slices/intervenantsSlice';
import { getParticipants } from '../../slices/participantsSlice';
import { getDashboardStats } from '../../slices/dashboardStatsSlice';

import { RootState } from '../../store';
import { Container } from '../../styles/common';
import ROLES from '../../constants/roles';

import StatusCard from '../../components/StatusCard';

import {
    Greeting,
    Welcome,
    StatusBox,
    TableContainer,
    TableNav,
    TabContainer,
    TableTitle,
    EmptyElement,
} from './styles';

import { IntervenantTable } from '../../components/IntervenantTable';
import { ParticipantTable } from '../../components/ParticipantTable';
import { useToggle } from '../../helpers/hooks';
import { AddUserModal } from '../../components/AddUserModal';

enum DashboardTabOption {
    intervenants = 'intervenants',
    participants = 'participants',
}

export enum TabLabelOptions {
    intervenants = 'Intervenants',
    participants = 'Participants',
}

interface IProps {}

type TabOption = {
    label: TabLabelOptions;
    value: DashboardTabOption;
};

export const Dashboard: React.FC<IProps> = () => {
    const [activeTab, setActiveTab] = useState<DashboardTabOption>(DashboardTabOption.participants);
    const [sortingOptions, setSortingOptions] = useState<SortOptions>({
        sortBy: 'id',
        sortOrder: 'ASC',
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showAddUserModal, toggleAddUserModal] = useToggle();

    const user = useSelector((state: RootState) => state.auth.user);
    const firstName = useSelector((state: RootState) => state.auth.user?.firstName);
    const intervenants = useSelector((state: RootState) => state.intervenants.intervenants);
    const participants = useSelector((state: RootState) => state.participants.participants);
    const intervenantsCount = useSelector(
        (state: RootState) => state.dashboardStats.stats?.intervenants,
    );
    const participantsCount = useSelector(
        (state: RootState) => state.dashboardStats.stats?.participants,
    );
    const interventionsInProgress = useSelector(
        (state: RootState) => state.dashboardStats.stats?.inProgress,
    );
    const interventionsCompleted = useSelector(
        (state: RootState) => state.dashboardStats.stats?.completed,
    );
    const intervenantsIsLoading = useSelector((state: RootState) => state.intervenants.loading);
    const participantsIsLoading = useSelector((state: RootState) => state.participants.loading);

    const isAdminOrSuperAdmin = user ? [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(user.role) : false;

    // Fetch dashboard stats data from backend
    useEffect(() => {
        dispatch(getDashboardStats([]));
    }, [dispatch]);

    // Fetch intervenants or participants table according to activeTab
    useEffect(() => {
        if (activeTab === DashboardTabOption.intervenants) {
            dispatch(getIntervenants(sortingOptions));
        } else {
            dispatch(getParticipants(sortingOptions));
            dispatch(getIntervenants(sortingOptions));
        }
    }, [dispatch, sortingOptions, activeTab]);

    // Tab options and handlers
    const tabOptions: TabOption[] = [
        {
            label: t('pages.Dashboard.participantsCard'),
            value: DashboardTabOption.participants,
        },
        {
            label: t('pages.Dashboard.intervenantsCard'),
            value: DashboardTabOption.intervenants,
        },
    ];

    const handleTabChange = (event: InputEvent) => {
        const target = event.target as HTMLInputElement;
        const value = (target.value as unknown) as DashboardTabOption;
        setActiveTab(value);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('pages.Dashboard.title')}</title>
            </Helmet>
            <Container className={showAddUserModal ? 'hidden-overflow' : ''}>
                <Greeting>{t('pages.Dashboard.greeting', { firstName })},</Greeting>
                <Welcome>{t('pages.Dashboard.welcome')}</Welcome>
                <StatusBox>
                    <StatusCard
                        icon={'participant'}
                        number={participantsCount}
                        title={t('pages.Dashboard.participantsCard')}
                        isLoading={participantsIsLoading}
                    />
                    {isAdminOrSuperAdmin && (
                        <StatusCard
                            icon={'intervenant'}
                            number={intervenantsCount}
                            title={t('pages.Dashboard.intervenantsCard')}
                            isLoading={intervenantsIsLoading}
                        />
                    )}
                    <StatusCard
                        icon={'inProgress'}
                        number={interventionsInProgress}
                        title={t('pages.Dashboard.inProgress')}
                        isLoading={participantsIsLoading}
                    />
                    <StatusCard
                        icon={'completed'}
                        number={interventionsCompleted}
                        title={t('pages.Dashboard.completed')}
                        isLoading={participantsIsLoading}
                    />
                </StatusBox>
                <TableContainer>
                    <TableNav>
                        <TableTitle>
                            {activeTab === DashboardTabOption.intervenants &&
                                t('pages.Intervenant.title')}
                            {activeTab === DashboardTabOption.participants &&
                                t('pages.Participant.title')}
                        </TableTitle>
                        {isAdminOrSuperAdmin && (
                            <TabContainer>
                                <Tabs
                                    options={tabOptions}
                                    value={activeTab}
                                    onChange={handleTabChange}
                                />
                            </TabContainer>
                        )}
                        <EmptyElement />
                    </TableNav>
                    {isAdminOrSuperAdmin && activeTab === DashboardTabOption.intervenants && (
                        <IntervenantTable
                            intervenants={intervenants}
                            onChangeSort={setSortingOptions}
                            sorting={sortingOptions}
                            authUserRole={user?.role}
                        />
                    )}
                    {activeTab === DashboardTabOption.participants && (
                        <ParticipantTable
                            participants={participants}
                            onChangeSort={setSortingOptions}
                            sorting={sortingOptions}
                            authUserRole={user?.role}
                        />
                    )}
                </TableContainer>
                <AddUserModal
                    show={showAddUserModal}
                    onClose={toggleAddUserModal}
                    role={
                        activeTab === DashboardTabOption.intervenants
                            ? ROLES.INTERVENANT
                            : ROLES.PARTICIPANT
                    }
                />
            </Container>
        </React.Fragment>
    );
};
