import styled from 'styled-components';

import { colours } from 'constants/colours';

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    background-color: ${colours.white};
    padding: 2rem;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
`;

export const Title = styled.h1`
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 1.625rem;
`;

export const AppPermissionWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const AppPermissionTitle = styled.h6`
    font-weight: 400;
    color: grey;
    margin: 0;
`;

export const Apps = styled.div`
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

interface IFeatureFlag {
    featureFlag?: boolean;
}

export const AppLogoWrapper = styled.div`
    pointer-events: ${({ featureFlag = true }: IFeatureFlag) => (featureFlag ? 'auto' : 'none')};
    cursor: pointer;
    display: inline-block;
    filter: grayscale(100%);
    opacity: 0.5;
    margin: 0 3rem;

    &:hover {
        filter: none;
        opacity: 1;
    }
`;
