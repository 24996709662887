import React from 'react';
import { useTranslation } from 'react-i18next';

import SessionInfo from '../../../components/SessionInfo';
import { SegmentResponsesBlock } from '../SegmentResponsesBlock';

import {
    SessionContainer,
    SessionHeader,
    SessionBody,
    SessionIcon,
    SessionTitle,
    SessionBlock,
    ColumnOne,
    ColumnTwo,
    QuizTitle,
} from './styles';

import CompleteIcon from '../../assets/session-complete-icon.svg';
import InProgressIcon from '../../assets/session-in-progress-icon.svg';
import BookedIcon from '../../assets/session-booked-icon.svg';

import { UserElement, RouteSwitch, SegmentName, UserSegment } from '../../types/segment';
import { colours } from '../../../constants/colours';

interface IProps {
    name: SegmentName;
    routeSwitch: RouteSwitch | null;
    startedAt?: string | null;
    completedAt: string | undefined;
    totalTime: number | undefined;
    segmentRecap?: UserElement[];
    shareData: boolean | undefined;
    version?: number;
    previousVersions?: UserSegment[] | null;
}

export const ICCParticipantSegmentsCard: React.FC<IProps> = ({
    name,
    startedAt,
    completedAt,
    segmentRecap,
    shareData,
    totalTime,
    routeSwitch,
    version,
    previousVersions,
}) => {
    const { t } = useTranslation();

    // Legacy Code from Champs is mostly used in this file as the functionality & UI is the same
    const getStatus = () => {
        let status;
        if (!completedAt && !startedAt) {
            status = t('components.SessionCard.notStartedTitle');
        } else if (!completedAt && startedAt) {
            status = t('components.SessionCard.inProgressTitle');
        } else if (completedAt) {
            status = t('components.SessionCard.completedTitle');
        }
        return status;
    };

    const getDuration = (segmentTotalTime: number) => {
        let duration = '-';

        if (segmentTotalTime) {
            const minutes = Math.floor(segmentTotalTime / 60000);
            const milliSeconds = segmentTotalTime - minutes * 60000;
            const seconds = Math.floor(milliSeconds / 1000);
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes - hours * 60;
            const secondsString = seconds > 9 ? seconds.toString() : `0${seconds}`;
            const remainingMinutesString =
                remainingMinutes > 9 ? remainingMinutes.toString() : `0${remainingMinutes}`;
            duration = t('components.SessionCard.HHmmSS', {
                hours,
                minutes: remainingMinutesString,
                seconds: secondsString,
            });
        }
        return duration;
    };

    return (
        <SessionContainer>
            {previousVersions && previousVersions.length >= 1 && (
                <>
                    {previousVersions.map((userSegment) => {
                        return (
                            <SessionHeader>
                                <ColumnOne>
                                    <SessionTitle style={{ color: colours.textGrey }}>
                                        {t(`icc.${name}.cardheader`)}
                                        {` Version ${userSegment.version}`}
                                    </SessionTitle>
                                    <SessionBlock>
                                        <SessionInfo
                                            sessionKey={t('components.SessionCard.startDate')}
                                            value={
                                                userSegment?.userSegmentTime?.[0]?.startTime
                                                    ? new Date(
                                                          userSegment.userSegmentTime[0].startTime,
                                                      ).toLocaleString('en-gb')
                                                    : '-'
                                            }
                                            previousVersion={true}
                                        />
                                        <SessionInfo
                                            sessionKey={t('components.SessionCard.endDate')}
                                            value={
                                                userSegment?.completedAt
                                                    ? new Date(
                                                          userSegment.completedAt,
                                                      ).toLocaleString('en-gb')
                                                    : '-'
                                            }
                                            previousVersion={true}
                                        />
                                        {userSegment?.userSegmentTime?.[0]?.totalTime && (
                                            <SessionInfo
                                                sessionKey={t('components.SessionCard.duration')}
                                                value={getDuration(
                                                    userSegment.userSegmentTime[0].totalTime,
                                                )}
                                                previousVersion={true}
                                            />
                                        )}
                                    </SessionBlock>
                                </ColumnOne>
                            </SessionHeader>
                        );
                    })}
                </>
            )}

            <SessionHeader>
                <ColumnOne>
                    <SessionTitle>
                        {t(`icc.${name}.cardheader`)}
                        {`  Version ${version} - ${getStatus()}`}
                    </SessionTitle>
                    <SessionBlock>
                        <SessionInfo
                            sessionKey={t('components.SessionCard.startDate')}
                            value={startedAt ? new Date(startedAt).toLocaleString('en-gb') : '-'}
                        />
                        <SessionInfo
                            sessionKey={t('components.SessionCard.endDate')}
                            value={
                                completedAt ? new Date(completedAt).toLocaleString('en-gb') : '-'
                            }
                        />
                        {startedAt && totalTime && (
                            <SessionInfo
                                sessionKey={t('components.SessionCard.duration')}
                                value={getDuration(totalTime)}
                            />
                        )}
                    </SessionBlock>
                </ColumnOne>
                <ColumnTwo>
                    {!completedAt && !startedAt && (
                        <SessionIcon alt="In Progress Icon" src={BookedIcon} />
                    )}
                    {!completedAt && startedAt && (
                        <SessionIcon alt="In Progress Icon" src={InProgressIcon} />
                    )}
                    {completedAt && <SessionIcon alt="Complete Icon" src={CompleteIcon} />}
                </ColumnTwo>
            </SessionHeader>
            {shareData && (
                <SessionBody>
                    {segmentRecap && (
                        <SegmentResponsesBlock
                            segmentRecap={segmentRecap}
                            segmentName={name}
                            routeSwitch={routeSwitch}
                        />
                    )}
                </SessionBody>
            )}
            {!shareData && (
                <SessionBody>
                    {!completedAt && !startedAt && t('components.SessionCard.notStarted')}
                    {!completedAt && startedAt && t('components.SessionCard.inProgress')}
                    {completedAt && (
                        <QuizTitle>{t('components.SessionCard.shareSensitiveDataFalse')}</QuizTitle>
                    )}
                </SessionBody>
            )}
        </SessionContainer>
    );
};
