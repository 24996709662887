import React from 'react';
import { Switch } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import { Dashboard } from './pages/Dashboard';

import PrivateRoute from '../components/PrivateRoute';
import { EditUserPage } from './pages/EditUser';

interface IProps {}

const UserManagementApp: React.FC<IProps> = () => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.USER_MANAGEMENT.DASHBOARD} Component={Dashboard} />
            <PrivateRoute path={ROUTES.USER_MANAGEMENT.EDIT_USER} Component={EditUserPage} />
        </Switch>
    );
};

export default UserManagementApp;
