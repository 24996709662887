import { Button, TextField } from '@osedea/reactor';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { TitleModal, BodyText, ButtonStyles } from '../../styles/common';
import { forgotPassword } from '../../slices/resetPasswordSlice';

interface IProps {
    dismissModal: () => void;
}

enum Step {
    FIRST,
    SECOND,
}

export const ForgotPasswordModal: React.FC<IProps> = ({ dismissModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>('');
    const [step, setStep] = useState<Step>(Step.FIRST);

    const handleClick = () => {
        dispatch(forgotPassword(email));
        setStep(Step.SECOND);
    };

    const renderStep = () => {
        switch (step) {
            case Step.FIRST:
                return (
                    <React.Fragment>
                        <TextFieldWrapper>
                            <TextField
                                name="email"
                                type="email"
                                style={{ width: '100%' }}
                                label={t('pages.Login.email')}
                                variant="outlined"
                                onChange={(e: React.FormEvent) =>
                                    setEmail((e.target as HTMLInputElement).value)
                                }
                                value={email}
                            />
                        </TextFieldWrapper>
                        <Button
                            onClick={handleClick}
                            style={{
                                ...ButtonStyles,
                                marginTop: '2.5rem',
                            }}
                        >
                            {t('pages.ForgotPassword.resetButton')}
                        </Button>
                    </React.Fragment>
                );
            case Step.SECOND:
                return (
                    <React.Fragment>
                        <CenteredBodyText>{t('pages.ForgotPassword.resetText')}</CenteredBodyText>
                        <Button
                            onClick={dismissModal}
                            style={{
                                ...ButtonStyles,
                                marginTop: '3rem',
                            }}
                        >
                            {t('global.close')}
                        </Button>
                    </React.Fragment>
                );
            default:
                return dismissModal();
        }
    };

    return (
        <Container>
            <Card>
                <TitleModal>{t('pages.ForgotPassword.title')}</TitleModal>
                {renderStep()}
            </Card>
        </Container>
    );
};

export const Container = styled.div`
    display: flex;
`;

export const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 48px;
    width: 40vw;
    border: 2px solid black;
    border-radius: 10px;
    background-color: ${colours.white};
`;

export const CenteredBodyText = styled(BodyText)`
    text-align: center;
`;

export const TextFieldWrapper = styled.div`
    width: 60%;
    margin-top: 1rem;
`;
