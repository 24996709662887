import Paper from '@material-ui/core/Paper';
import React from 'react';

// Constants
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import ChumStoreLogo from 'assets/logo-champs-store.png';
import IccStoreLogo from 'assets/logo-icc-store.png';

import Icon from './Icon';

export type AppType = 'champs' | 'icc' | 'user-management';

interface IProps {
    app: AppType;
    onClick?: (e: React.MouseEvent) => void;
}

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0rem;
    min-width: 250px;
    min-height: 160px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CardTitle = styled.h2`
    margin-bottom: 0px;
    margin-top: 6px;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
`;

interface IconWrapperProps {
    backgroundColor?: string;
}

const IconWrapper = styled(Icon)`
    background-color: ${(props: IconWrapperProps) => props.backgroundColor ?? 'none'};
`;

const AppCard: React.FC<IProps> = ({ app, onClick }) => {
    const { t } = useTranslation();

    const renderContent = () => {
        let icon: JSX.Element;
        let title: string;

        switch (app) {
            case 'champs':
                icon = <img src={ChumStoreLogo} alt={'Chum Store Logo'} width={64} height={64} />;
                title = t('components.AppCard.champs');
                break;
            case 'icc':
                icon = (
                    <img src={IccStoreLogo} alt={'iCanChange Store Logo'} width={64} height={64} />
                );
                title = t('components.AppCard.icc');
                break;
            case 'user-management':
            default:
                icon = <IconWrapper icon={'user'} size={64} />;
                title = t('components.AppCard.userManagement');
        }
        return (
            <Content>
                {icon}
                {title && <CardTitle>{title}</CardTitle>}
            </Content>
        );
    };

    return (
        <Container elevation={2} onClick={onClick} style={{ flex: 1, margin: '0 1rem' }}>
            {renderContent()}
        </Container>
    );
};

export default AppCard;
