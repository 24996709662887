import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../i18n';

import { AppType } from 'types';

import { ROUTES } from '../../constants/routes';

import { selectUser, logoutUser } from '../../slices/authSlice';

import ROLES from '../../constants/roles';

import { exportDatabase, exportCSV } from 'slices/databaseSlice';

// Assets
import ChumLogo from '../../assets/logo_crchum.jpg';

import {
    Container,
    RightNavContainer,
    ExitButton,
    ExportDatabaseButton,
    LanguageButton,
    HeaderIcon,
    MainLogo,
    UserIcon,
    UserName,
    Role,
} from './styles';

type HistoryWithState = { location: { state?: { app?: AppType } } };

const Header: React.FC = () => {
    const { t } = useTranslation();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const history = useHistory();

    const [exportApp, setExportApp] = useState<AppType | null>(null);

    useEffect(() => {
        const historyWithState = history as HistoryWithState;

        if (historyWithState.location.state && historyWithState.location.state.app) {
            setExportApp(historyWithState.location.state.app);
        } else {
            setExportApp(null);
        }
    }, [history]);

    const logoutHandler = () => {
        dispatch(logoutUser());
        history.push(ROUTES.LOGIN);
    };

    const exportData = () => {
        if (exportApp) {
            dispatch(exportDatabase({ database: exportApp }));
        }
    };
    const exportCSVFile = () => {
        if (exportApp) {
            dispatch(exportCSV({ database: exportApp }));
        }
    };
    const languageToggle = () => {
        if (i18n.language === 'en') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18n.changeLanguage('fr');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18n.changeLanguage('en');
        }
    };

    const roleText = React.useMemo(() => {
        switch (user?.role) {
            case ROLES.SUPER_ADMIN:
                return t('components.Header.superAdmin');
            case ROLES.ADMIN:
                return t('components.Header.admin');
            case ROLES.INTERVENANT:
                return t('components.Header.intervenant');
            default:
                return '';
        }
    }, [user, t]);

    return (
        <>
            {user && (
                <Container elevation={2} square={false}>
                    <NavLink to={ROUTES.HOME} exact>
                        <MainLogo alt="Chum Logo" src={ChumLogo} />
                    </NavLink>
                    <RightNavContainer>
                        {exportApp && (
                            <>
                                <ExportDatabaseButton onClick={exportData}>
                                    {t('components.Header.exportDatabase')}
                                    <HeaderIcon icon={'intervenant'} size={24} />
                                </ExportDatabaseButton>
                                <ExportDatabaseButton onClick={exportCSVFile}>
                                    {t('components.Header.exportCsv')}
                                    <HeaderIcon icon={'intervenant'} size={24} />
                                </ExportDatabaseButton>
                            </>
                        )}

                        <LanguageButton onClick={languageToggle}>
                            {t('components.Header.language')}
                        </LanguageButton>

                        <UserIcon icon={'user'} size={32} />
                        <div>
                            <UserName>{`${user.firstName} ${user.lastName}`}</UserName>
                            <Role>{roleText}</Role>
                        </div>
                        <ExitButton onClick={logoutHandler}>
                            <HeaderIcon icon={'logout'} size={24} />
                        </ExitButton>
                    </RightNavContainer>
                </Container>
            )}
        </>
    );
};

export default Header;
