import { ICC_API_URL } from '../../env';

export const BACKEND_ROUTES = {
    EXPORT_DB: `${ICC_API_URL}${'/database/export-db'}`,
    EXPORT_CSV: `${ICC_API_URL}${'/database/export-csv'}`,
    PARTICIPANTS: `${ICC_API_URL}${'/participants'}`,
    ASSIGN_INTERVENANTS_TO_PARTICIPANT: `${ICC_API_URL}${'/participants/link-intervenants'}`,
    INTERVENANTS: `${ICC_API_URL}${'/intervenants'}`,
    DASHBOARD_STATS: `${ICC_API_URL}${'/dashboard_stats'}`,
};
