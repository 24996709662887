import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const SessionTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${colours.black};
    margin: 0;
`;

export const SessionContainer = styled.div`
    border: solid 2px ${colours.black};
    border-radius: 5px;
    width: 100%;
    margin-top: 35px;
`;

export const SessionHeader = styled.div`
    background-color: ${colours.grey100};
    border-radius: 10px;
    width: 100%;
    padding: 28px 42px;
    display: flex;
    flex-direction: row;
`;

export const SessionBody = styled.div`
    background-color: ${colours.white};
    border-radius: 10px;
    width: 100%;
    padding: 30px 42px;
`;

export const BodySubheader = styled.h2`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colours.black};
    margin: 0;
`;

export const ColumnOne = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const ColumnTwo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0;
`;
export const SessionBlock = styled.div`
    display: flex;
`;

export const SessionIcon = styled.img`
    width: 55px;
    height: 55px;
`;

export const QuizTitle = styled.div`
    font-weight: bold;
    font-size: large;
`;
