import styled from 'styled-components';

export const QAContainer = styled.div`
    margin-bottom: 24px;
`;

export const ABlock = styled.div`
    line-height: 24px;
`;
export const RecessedBlock = styled.div`
    margin-bottom: 12px;
    margin-left: 24px;
    line-height: 24px;
`;
export const SelectedOrderBlock = styled.div`
    font-weight: 500;
    margin-bottom: 12px;
    margin-left: 24px;
    text-decoration: underline;
`;
