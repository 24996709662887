import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';
import { useHistory, useLocation } from 'react-router-dom';

import ProfileDetail from '../../components/ProfileDetail';

import { IntervenantDetailParticipantTable } from '../../components/IntervenantDetailParticipantTable';

import {
    ProfileContainer,
    ProfileFirstBlock,
    ProfileTitle,
    ProfileDetailsContainer,
    BackArrow,
    NoRelationshipsContainer,
    NewBackButton,
} from '../ParticipantDetail/styles';
import { Container, Title } from '../../styles/common';

import { getIntervenantDetails } from '../../slices/intervenantDetailsSlice';
import { AssignParticipantModal } from '../../components/AssignParticipantModal';

import ROLES from '../../constants/roles';

import { RootState } from '../../store';

import ArrowLeftImage from '../../assets/arrow-left.svg';
import Icon from '../../components/Icon';
import { TabLabelOptions } from '../Dashboard';
import { ProfileDetailContainer } from '../../components/ProfileDetail/styles';

import { useToggle } from '../../helpers/hooks';

interface StateType {
    intervenantId: number | null;
}

interface IProps {}

export const IntervenantDetail: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<StateType>();
    const intervenantId = location.state.intervenantId;

    useEffect(() => {
        dispatch(getIntervenantDetails(intervenantId));
    }, [dispatch, intervenantId]);

    const intervenant = useSelector((state: RootState) => state.intervenantDetails.intervenant);
    const userRole = useSelector((state: RootState) => state.auth.user?.role);
    const [sortingOptions, setSortingOptions] = useState<SortOptions>({
        sortBy: 'id',
        sortOrder: 'ASC',
    });

    const [showAssignParticipantModal, toggleAssignParticipantModal] = useToggle();

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('pages.UserDetail.title', {
                        role: t(`Roles.${ROLES.INTERVENANT}`),
                    })}
                    : {intervenant ? `${intervenant.firstName} ${intervenant.lastName}` : ''}
                </title>
            </Helmet>
            <>
                <Container>
                    {intervenant && (
                        <>
                            <ProfileContainer>
                                <NewBackButton onClick={history.goBack}>
                                    <BackArrow alt="arrow-left image" src={ArrowLeftImage} />
                                    {t('pages.UserDetail.back')}
                                </NewBackButton>
                                <ProfileFirstBlock>
                                    <ProfileTitle>
                                        {t('pages.UserDetail.title', {
                                            role: t(`Roles.${ROLES.INTERVENANT}`),
                                        })}
                                    </ProfileTitle>
                                </ProfileFirstBlock>

                                <ProfileDetailsContainer>
                                    <ProfileDetail
                                        title={t('pages.UserDetail.firstName')}
                                        value={intervenant.firstName}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.lastName')}
                                        value={intervenant.lastName}
                                    />
                                    {userRole === ROLES.SUPER_ADMIN ? (
                                        <ProfileDetail
                                            title={t('global.admin')}
                                            value={`${intervenant.admin?.firstName} ${intervenant.admin?.lastName}`}
                                        />
                                    ) : (
                                        <ProfileDetail title={''} value={''} />
                                    )}
                                    <ProfileDetail
                                        title={t('pages.UserDetail.email')}
                                        value={intervenant.email}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.phone')}
                                        value={intervenant.phone ?? '-'}
                                    />
                                    <ProfileDetail
                                        title={t('pages.UserDetail.extension')}
                                        value={intervenant.extension?.toString() ?? '-'}
                                    />
                                </ProfileDetailsContainer>
                                <ProfileDetailContainer
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <ProfileTitle>
                                        {t('pages.UserDetail.TableTitle', {
                                            role1: TabLabelOptions.participants,
                                            role2: t(`Roles.${ROLES.INTERVENANT}`),
                                        })}
                                    </ProfileTitle>
                                </ProfileDetailContainer>
                                {intervenant.participants.length > 0 && (
                                    <IntervenantDetailParticipantTable
                                        participants={intervenant.participants}
                                        onChangeSort={setSortingOptions}
                                        sorting={sortingOptions}
                                    />
                                )}
                                {!intervenant.participants.length && (
                                    <NoRelationshipsContainer>
                                        <Icon icon={'noRelationship'}></Icon>
                                        <ProfileTitle>
                                            {t('pages.UserDetail.noRelationship', {
                                                role: t(`Roles.${ROLES.PARTICIPANT}`),
                                            })}
                                        </ProfileTitle>
                                    </NoRelationshipsContainer>
                                )}
                            </ProfileContainer>
                        </>
                    )}
                    {showAssignParticipantModal && intervenant && (
                        <AssignParticipantModal
                            show={showAssignParticipantModal}
                            intervenant={intervenant}
                            onClose={toggleAssignParticipantModal}
                        />
                    )}
                    {!intervenant && (
                        <ProfileContainer>
                            <Title>{t('pages.UserDetail.error')}</Title>
                        </ProfileContainer>
                    )}
                </Container>
            </>
        </React.Fragment>
    );
};
