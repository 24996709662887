import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';

import { SelectedIntervenant } from 'types/users';

export interface IProps {
    intervenant: SelectedIntervenant;
    onClick?: (intervenant: SelectedIntervenant) => void;
    disabled?: boolean;
}

const SelectableIntervenantButton: React.FC<IProps> = ({
    intervenant,
    onClick,
    disabled = false,
}) => {
    return (
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
                color="primary"
                component="span"
                onClick={() => onClick?.(intervenant)}
                disabled={disabled}
                disableRipple={true}
            >
                {intervenant.selectedIndex ? <CheckIcon /> : <AddCircleIcon />}
            </IconButton>
            <span>
                <p style={{ margin: 0 }}>
                    {intervenant.selectedIndex ?? <span>&nbsp;&nbsp;&nbsp;</span>}
                </p>
            </span>
        </span>
    );
};

export default SelectableIntervenantButton;
