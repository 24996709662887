import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackContainer, GridContainer, ResponseBlock, Answer, Count } from './styles';

import {
    IQARecap,
    ISessionOneRecap,
    ISessionTwoRecap,
    ISessionFourRecap,
    ISessionFiveRecap,
    ISessionSixRecap,
    IBoosterARecap,
} from '../../types/sessionsRecaps';
import { FeedbackDetail } from '../FeedbackDetail';

interface IProps {
    sessionRecap:
        | ISessionOneRecap
        | ISessionTwoRecap
        | IQARecap
        | ISessionFourRecap
        | ISessionFiveRecap
        | ISessionSixRecap
        | IBoosterARecap
        | undefined;
}

export const FeedbackBlock: React.FC<IProps> = ({ sessionRecap }) => {
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [answerToDetail, setAnswerToDetail] = useState<string>('');
    const [questionsToDetail, setQuestionsToDetail] = useState<string[] | []>([]);
    const { t } = useTranslation();

    const sessionRecapArray = sessionRecap ? Object.entries(sessionRecap) : [];

    const getMostAnswered = () => {
        const values: number[] = [];
        sessionRecapArray.forEach((answer: [string, string[]]) => {
            values.push(answer[1].length);
        });
        const maxValue: number = values.length > 0 ? values.reduce((a, b) => Math.max(a, b)) : 0;
        return maxValue;
    };
    const hundredPercent = getMostAnswered();

    const openDetail = (index: number) => {
        setAnswerToDetail(sessionRecapArray[index][0]);
        setQuestionsToDetail(sessionRecapArray[index][1] as string[]);
        setShowDetailModal(true);
    };

    return (
        <FeedbackContainer>
            <GridContainer>
                {sessionRecapArray.map((answerArray, index) => {
                    const questionsCount: number[] = [];
                    const answer = answerArray[0];
                    const questions = answerArray[1] as string[];
                    questionsCount.push(questions.length);

                    return (
                        questions.length > 0 && (
                            <>
                                <ResponseBlock
                                    percentage={questions.length / hundredPercent}
                                    onClick={() => openDetail(index)}
                                    key={index}
                                >
                                    <Answer>{t(`sessionRecaps.Answer.${answer}`)}</Answer>
                                    <Count>{questions.length}</Count>
                                </ResponseBlock>
                            </>
                        )
                    );
                })}
            </GridContainer>
            {showDetailModal && (
                <FeedbackDetail
                    answer={answerToDetail}
                    questions={questionsToDetail}
                    onCloseButton={setShowDetailModal}
                />
            )}
        </FeedbackContainer>
    );
};
