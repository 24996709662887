import React, { useCallback, useContext, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { Button, Table } from '@osedea/reactor';
import { useTranslation } from 'react-i18next';
import { Header } from '@osedea/reactor/dist/compounds/table/types';
import { useDispatch, useSelector } from 'react-redux';

import { Title } from 'styles/common';
import { RootState } from 'store';
import { IntervenantWithOrder } from 'icc/types/user';
import {
    assignIntervenants,
    resetAssignIntervenantsState,
} from 'icc/slices/assignIntervenantSlice';
import { ParticipantDetailsContext } from 'icc/constants/context';
import { API_STATUS } from 'helpers/request';
import { ReactComponent as PlusLogo } from 'assets/icons/plus_icon.svg';

interface IProps {
    open: boolean;
    handleClose: () => void;
    participantIntervenants?: IntervenantWithOrder[];
}

export const AssignIntervenantsDialog: React.FC<IProps> = ({
    open,
    handleClose,
    participantIntervenants,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allIntervenants = useSelector(
        (state: RootState) => state.iccApp.intervenants.intervenants,
    );

    const assignIntervenantStatus = useSelector(
        (state: RootState) => state.iccApp.assignIntervenants.status,
    );

    const [selectedIntervenants, setSelectedIntervenants] = React.useState(
        participantIntervenants ?? [],
    );
    const participant = useContext(ParticipantDetailsContext);

    const onClose = React.useCallback(() => {
        dispatch(resetAssignIntervenantsState());
        handleClose();
    }, [dispatch, handleClose]);

    useEffect(() => {
        setSelectedIntervenants(participantIntervenants ?? []);
    }, [open, setSelectedIntervenants, participantIntervenants]);

    useEffect(() => {
        if (open && assignIntervenantStatus === API_STATUS.SUCCEEDED) {
            onClose();
        }
    }, [open, assignIntervenantStatus, onClose]);

    const headers: Header[] = [
        {
            id: 'firstName',
            label: t('global.firstName'),
        },
        {
            id: 'lastName',
            label: t('global.lastName'),
        },
        {
            id: 'email',
            label: t('global.email'),
        },
        {
            id: 'controls',
            label: '',
        },
    ];

    const handleOnClick = useCallback(
        (intervenantId: number) => {
            // 1. Check if it exists;
            const intervenantIndex = selectedIntervenants.findIndex(
                (intervenant) => intervenant.id === intervenantId,
            );

            let temp = [...selectedIntervenants];

            if (intervenantIndex !== -1) {
                // Exits. Remove it.
                const removedIntervenantOrder = selectedIntervenants[intervenantIndex].order;
                temp.splice(intervenantIndex, 1);

                temp = temp.map((intervenant) => {
                    if (intervenant.order > removedIntervenantOrder) {
                        const updatedIntervenant = { ...intervenant };
                        updatedIntervenant.order -= 1;
                        return updatedIntervenant;
                    }
                    return intervenant;
                });
            } else {
                // Does not exits. Add it.
                const intervenant = allIntervenants.find(
                    (intervenant) => intervenant.id === intervenantId,
                );
                if (intervenant) {
                    temp.push({
                        ...intervenant,
                        order: selectedIntervenants.length + 1,
                    });
                }
            }

            setSelectedIntervenants(temp);
        },
        [allIntervenants, selectedIntervenants],
    );

    const getOrderNumber = React.useCallback(
        (intervenantId: number) => {
            const targetIntervenant = selectedIntervenants.find(
                (intervenant) => intervenant.id === intervenantId,
            );
            return targetIntervenant?.order;
        },
        [selectedIntervenants],
    );

    const intervenantRows = React.useMemo(() => {
        return allIntervenants.map((intervenant) => {
            const order = getOrderNumber(intervenant.id);
            return {
                id: intervenant.id,
                firstName: intervenant.firstName,
                lastName: intervenant.lastName,
                email: intervenant.email,
                controls: (
                    <Button variant="circular" onClick={() => handleOnClick(intervenant.id)}>
                        {order ?? <PlusLogo fill={'white'} />}
                    </Button>
                ),
            };
        });
    }, [allIntervenants, getOrderNumber, handleOnClick]);

    const handleSubmit = () => {
        if (participant) {
            dispatch(assignIntervenants({ participantId: participant.id, selectedIntervenants }));
        }
    };

    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth={false}>
            <div style={{ margin: '2rem 5rem' }}>
                <Title>{t('components.AssignIntervenantsDialog.title')}</Title>
                <Table headers={headers} rows={intervenantRows} />
                <div
                    style={{
                        margin: '3rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        disabled={assignIntervenantStatus === API_STATUS.LOADING}
                        onClick={handleSubmit}
                    >
                        {t('global.saveChanges')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
