import React from 'react';

import { ProfileDetailContainer, ProfileDetailTitle, ProfileDetailValue } from './styles';

export interface IProps {
    title: string;
    value?: string;
}

const ProfileDetail: React.FC<IProps> = ({ title, value = '-' }) => {
    return (
        <ProfileDetailContainer>
            <ProfileDetailTitle>{title}</ProfileDetailTitle>
            <ProfileDetailValue>{value}</ProfileDetailValue>
        </ProfileDetailContainer>
    );
};

export default ProfileDetail;
