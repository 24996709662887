import { Table } from '@osedea/reactor';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import { Row, SortOptions } from '@osedea/reactor/dist/compounds/table/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

import { useHistory } from 'react-router';

import { RootState, useAppDispatch } from 'store';
import {
    Container,
    TableContainer,
    SubTitleContainer,
    SubTitle,
    SubTitleSpan,
    PrimaryButton,
} from './styles';
import { deleteUserById, fetchUsers } from 'user_management/slices/usersSlice';
import { colours } from 'constants/colours';
import { Title } from 'styles/common';
import AddUserDialog from 'user_management/components/AddUserDialog';

import LogoChamps from 'assets/logo-champs-round.png';
import LogoIcc from 'assets/logo-icc-round.png';
import { ERoleType } from 'types';
import { ROUTES } from 'constants/routes';
import { IUser } from 'types/users';
import { consumeCreateUserEvent } from 'user_management/slices/createUserSlice';
import { authenticationService } from 'helpers/authenticationService';

import ROLES from 'constants/roles';

interface IProps {}

export const Dashboard: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const appDispatch = useAppDispatch();

    const history = useHistory();
    const authUser = authenticationService.currentUserValue;
    const users = useSelector((state: RootState) => state.userManagementApp.users.users);
    const stats = useSelector((state: RootState) => state.userManagementApp.users.stats);
    const successfullyCreated = useSelector(
        (state: RootState) => state.userManagementApp.createUser.user,
    );
    const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
    const [currentSortingOptions, setCurrentSortingOptions] = useState<SortOptions>();
    const [snackBar, setSnackBar] = useState({
        showSnackBar: false,
        message: 'missingTranslation',
    });

    const [showDialog, setShowDeleteDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState<IUser | null>(null);

    useEffect(() => {
        if (!successfullyCreated.hasBeenHandled && successfullyCreated.content) {
            dispatch(consumeCreateUserEvent());
            setSnackBar({
                showSnackBar: true,
                message: t('pages.UserManagement.addUserSuccess'),
            });
        }
    }, [dispatch, successfullyCreated, t]);

    const headers = React.useMemo(() => {
        if (authUser?.role === ERoleType.SUPER_ADMIN) {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                    sortable: true,
                },
                {
                    id: 'adminName',
                    label: t('global.admin'),
                    sortable: false,
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    id: 'extension',
                    label: t('global.extension'),
                },
                {
                    id: 'role',
                    label: t('global.role'),
                    sortable: true,
                },
                {
                    id: 'appPermission',
                    label: 'Apps',
                },
                {
                    id: 'controls',
                    label: '',
                },
            ];
        } else {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                    sortable: true,
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    id: 'extension',
                    label: t('global.extension'),
                },
                {
                    id: 'role',
                    label: t('global.role'),
                    sortable: true,
                },
                {
                    id: 'appPermission',
                    label: 'Apps',
                },
                {
                    id: 'controls',
                    label: '',
                },
            ];
        }
    }, [t, authUser]);

    const handleEditUser = useCallback(
        (user: IUser) => {
            history.push(ROUTES.USER_MANAGEMENT.EDIT_USER, { user });
        },
        [history],
    );

    const openDeleteUserPopup = (user: IUser) => {
        setShowDeleteDialog(true);
        setUserToDelete(user);
    };

    const deleteUser = () => {
        if (userToDelete !== null) {
            appDispatch(
                deleteUserById({ userId: userToDelete.id, sortOptions: currentSortingOptions }),
            )
                .then(unwrapResult)
                .then(() => {
                    setUserToDelete(null);
                    setShowDeleteDialog(false);
                    setSnackBar({
                        showSnackBar: true,
                        message: t('pages.UserManagement.deleteUserSuccess'),
                    });
                })
                .catch((e: Error) => {
                    setUserToDelete(null);
                    setShowDeleteDialog(false);
                    setSnackBar({
                        showSnackBar: true,
                        message: t('pages.UserManagement.deleteUserFail'),
                    });
                });
        } else {
            if (__DEV__) {
                console.error(`userToDelete is null in deleteUser`);
            }
        }
    };

    const userRows: Row[] = useMemo(() => {
        return users.map((user) => {
            let adminName = 'N/A';

            if (user.admin?.firstName && user.admin.lastName) {
                adminName = `${user.admin.firstName} ${user.admin.lastName}`;
            }

            const row = {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                adminName,
                email: (
                    <span>
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                    </span>
                ),
                ...(user.phone && { phone: user.phone }),
                ...(user.extension && { extension: user.extension }),
                role: t(`global.${user.role.toLowerCase()}`),
                appPermission: (
                    <span>
                        {(user.role === ERoleType.ADMIN || user.champs) && (
                            <img
                                src={LogoChamps}
                                width={48}
                                height={48}
                                alt={'Round Logo Champs'}
                            />
                        )}
                        {(user.role === ERoleType.ADMIN || user.icc) && (
                            <img
                                src={LogoIcc}
                                width={48}
                                height={48}
                                alt={'Round Logo iCanChange'}
                            />
                        )}
                    </span>
                ),
                controls: (
                    <span>
                        <IconButton onClick={() => handleEditUser(user)}>
                            <EditIcon style={{ color: colours.global.blue }} />
                        </IconButton>
                        {(user.role !== ROLES.ADMIN ||
                            (authUser?.role === ROLES.SUPER_ADMIN &&
                                user.role !== ROLES.ADMIN)) && (
                            <IconButton onClick={() => openDeleteUserPopup(user)}>
                                <DeleteIcon style={{ color: colours.global.blue }} />
                            </IconButton>
                        )}
                    </span>
                ),
            };
            return row;
        });
    }, [users, t, authUser, handleEditUser]);

    useEffect(() => {
        dispatch(fetchUsers({ sortOptions: currentSortingOptions }));
    }, [dispatch, currentSortingOptions]);

    return (
        <Container>
            <Title>{t('pages.UserManagement.introTitle')}</Title>
            <SubTitleContainer>
                <div>
                    <SubTitle>
                        {t('pages.UserManagement.totalAdmins')}{' '}
                        <SubTitleSpan>{stats.admin}</SubTitleSpan>
                    </SubTitle>
                    <SubTitle>
                        {t('pages.UserManagement.totalIntervenants')}{' '}
                        <SubTitleSpan>{stats.intervenants}</SubTitleSpan>
                    </SubTitle>
                    <SubTitle>
                        {t('pages.UserManagement.totalParticipants')}{' '}
                        <SubTitleSpan>{stats.participants}</SubTitleSpan>
                    </SubTitle>
                </div>
                <div>
                    <PrimaryButton onClick={() => setOpenAddUserDialog(true)}>
                        {t('pages.UserManagement.addUser')}
                    </PrimaryButton>
                </div>
            </SubTitleContainer>

            <TableContainer>
                <Table
                    headers={headers}
                    rows={userRows}
                    emptyCellValue={'N/A'}
                    onSort={(sortOptions: SortOptions) => {
                        setCurrentSortingOptions(sortOptions);
                    }}
                />
            </TableContainer>
            {openAddUserDialog && (
                <AddUserDialog
                    open={openAddUserDialog}
                    handleClose={() => setOpenAddUserDialog(false)}
                />
            )}
            <Snackbar
                open={snackBar.showSnackBar}
                autoHideDuration={4000}
                onClose={() => setSnackBar({ ...snackBar, showSnackBar: false })}
                message={snackBar.message}
            />
            {
                <Dialog
                    onClose={() => setShowDeleteDialog(false)}
                    open={showDialog}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <div
                        style={{
                            margin: '2rem 5rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                        }}
                    >
                        {t('pages.UserManagement.deleteUserDialog', {
                            userFullName: `${userToDelete?.firstName} ${userToDelete?.lastName}`,
                        })}

                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <PrimaryButton onClick={() => setShowDeleteDialog(false)}>
                                {t('global.cancel')}
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={() => deleteUser()}
                                style={{ marginLeft: '10px' }}
                            >
                                {t('global.ok')}
                            </PrimaryButton>
                        </div>
                    </div>
                </Dialog>
            }
        </Container>
    );
};
