import React, { useState, FormEvent } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, Button } from '@osedea/reactor';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { ROUTES } from '../../constants/routes';

import {
    Container,
    FormContainer,
    Title,
    FormLogin,
    forgotPasswordButtonStyles,
    Logo,
    TextLink,
    PaperContainer,
} from './styles';
import { loginUser, setErrorsAction } from '../../slices/authSlice';
import { GenericModal } from '../../components/Generic/Modal/GenericModal';
import { ForgotPasswordModal } from '../../components/ForgotPasswordModal';

// Assets
import ChumLogo from '../../assets/logo_crchum.jpg';

interface IProps {}

export const Login = withRouter((props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const authError = useSelector((state: RootState) => state.auth.errors);
    const [forgotPasswordVisiblity, setForgotPasswordVisiblity] = useState(false);

    const handleChangeEmail = (value: string) => {
        if (authError) {
            dispatch(setErrorsAction(null));
        }
        setEmail(value);
    };

    const toPrivacyPolicy = () => {
        history.push(ROUTES.PRIVACY_POLICY);
    };

    const handleChangePass = (value: string) => {
        if (authError) {
            dispatch(setErrorsAction(null));
        }
        setPassword(value);
    };

    // If user is authenticated, redirect to home, if intervenant, redirect to details page
    useSelector((state: RootState) => {
        if (state.auth.user) {
            history.push(ROUTES.HOME);
        }
    });

    const canFormSubmit = () => email.trim() && password.trim();

    const authHandler = (e: FormEvent) => {
        e.preventDefault();
        submitForm();
    };

    const submitForm = () => {
        if (canFormSubmit()) {
            dispatch(loginUser({ email, password }));
        }
    };

    const handleForgotPassword = () => {
        setForgotPasswordVisiblity(true);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitForm();
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('pages.Login.title')}</title>
            </Helmet>
            <Container>
                <PaperContainer elevation={2}>
                    <FormLogin onSubmit={authHandler}>
                        <Logo alt="Chum Logo" src={ChumLogo} />
                        <Title>{t('pages.Login.header')}</Title>
                        <FormContainer>
                            <TextField
                                name="email"
                                type="email"
                                label={t('pages.Login.email')}
                                variant="outlined"
                                hasError={authError !== null}
                                assistiveText={
                                    authError ? t('pages.Login.invalidCredentials') : undefined
                                }
                                onChange={(e: React.FormEvent) =>
                                    handleChangeEmail((e.target as HTMLInputElement).value)
                                }
                                value={email}
                                inputProps={{ onKeyDown: (e: KeyboardEvent) => handleKeyDown(e) }}
                            />
                            <TextField
                                name="password"
                                type="password"
                                label={t('pages.Login.password')}
                                variant="outlined"
                                hasError={authError !== null}
                                assistiveText={
                                    authError ? t('pages.Login.invalidCredentials') : undefined
                                }
                                onChange={(e: React.FormEvent) =>
                                    handleChangePass((e.target as HTMLInputElement).value)
                                }
                                value={password}
                                inputProps={{ onKeyDown: (e: KeyboardEvent) => handleKeyDown(e) }}
                            />
                            <Button onClick={authHandler}>{t('pages.Login.submit')}</Button>
                        </FormContainer>
                    </FormLogin>
                    <Button
                        variant="text"
                        style={forgotPasswordButtonStyles}
                        onClick={handleForgotPassword}
                    >
                        {t('pages.Login.forgotPassword')}
                    </Button>
                    <TextLink onClick={toPrivacyPolicy}>{t('pages.Login.privacyPolicy')}</TextLink>
                </PaperContainer>
                <GenericModal
                    isVisible={forgotPasswordVisiblity}
                    dismissCallback={() => setForgotPasswordVisiblity(false)}
                >
                    <ForgotPasswordModal dismissModal={() => setForgotPasswordVisiblity(false)} />
                </GenericModal>
            </Container>
        </>
    );
});
