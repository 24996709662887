import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 31.25rem;
    min-width: 21.875rem;
    > * {
        margin-bottom: 1.25rem;
    }

    @media (max-width: 768px) {
        min-width: 16rem;
    }
`;

export const TextCard = styled.div({
    textAlign: 'center',
    backgroundColor: 'white',
    padding: '2rem 3rem',
    borderRadius: '10px',
    border: `solid 2px ${colours.black}`,
});

export const FormResetPassword = styled.form`
    text-align: center;
    background-color: white;
    padding: 2rem 5rem;
    border-radius: 10px;
    border: solid 2px ${colours.black};
    @media (max-width: 768px) {
        padding: 2rem 1rem;
    }
`;

export const Title = styled.h2``;

export const Text = styled.p`
    margin: 0;
    margin-top: 0.8rem;
    font-style: 'normal';
    font-weight: 500;
    font-size: 1rem;
    line-height: 28px;
`;
interface LogoProps {
    alt: string;
    src: string;
}

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 30px;
    margin-top: 30px;
    object-fit: cover;
    alt: ${(props: LogoProps) => props.alt};
    src: ${(props: LogoProps) => props.src};
`;

export interface resetButtonProps {
    readonly disabled?: boolean;
}

export const ResetButton = styled.button`
    backgroundColor: ${(props: resetButtonProps) =>
        props.disabled ? colours.error : colours.activeButton},
    color: ${colours.black},
    border: solid 2px ${colours.black},
    margin: '0 auto',
`;
