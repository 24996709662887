import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_STATUS, authHeader } from 'helpers/request';
import { Intervenant } from 'icc/types/user';
import { RootState } from 'store';
import { BACKEND_ROUTES } from '../constants/api_routes';

export interface IIntervenantsState {
    intervenant?: Intervenant;
    status: API_STATUS;
    error: string | null;
}

export const initialState: IIntervenantsState = {
    intervenant: undefined,
    status: API_STATUS.IDLE,
    error: null,
};

export interface IntervenantResponseSuccess {
    intervenant: Intervenant;
}

export const getAnIntervenant = createAsyncThunk(
    'icc/get/intervenants',
    (params: { intervenantId: number }, { dispatch, getState }) => {
        dispatch(setLoadingAction());
        const { intervenantId } = params;

        const state = getState() as RootState;
        const currentId = state.iccApp.intervenant.intervenant?.id;

        if (currentId !== intervenantId) {
            dispatch(clearAction());
        }

        const url = `${BACKEND_ROUTES.INTERVENANTS}/${intervenantId}`;

        return axios
            .get<IntervenantResponseSuccess>(url, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch(setIntervenantAction(response.data));
            })
            .catch((e: Error) => {
                console.log('intervenantsSlice error', e);
                dispatch(setErrorAction(e.message));
            });
    },
);

export const slice = createSlice({
    name: 'icc-an-intervenant',
    initialState,
    reducers: {
        setIntervenantAction(state, action: PayloadAction<IntervenantResponseSuccess>) {
            state.intervenant = action.payload.intervenant;
            state.status = API_STATUS.SUCCEEDED;
            state.error = null;
        },
        setErrorAction(state, action: PayloadAction<string>) {
            state.status = API_STATUS.FAILED;
            state.error = action.payload;
        },
        setLoadingAction(state) {
            state.status = API_STATUS.LOADING;
            state.error = null;
        },
        resetAction(state) {
            state.intervenant = undefined;
            state.status = API_STATUS.IDLE;
            state.error = null;
        },
        clearAction(state) {
            state.intervenant = undefined;
            state.error = null;
        },
    },
});

export const {
    setIntervenantAction,
    setErrorAction,
    setLoadingAction,
    resetAction,
    clearAction,
} = slice.actions;

export default slice.reducer;
