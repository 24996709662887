import { useReducer } from 'react';
import { useLocation } from 'react-router-dom';

export function useToggle(initialValue = false) {
    // Returns the tuple [state, dispatch]
    // Normally with useReducer you pass a value to dispatch to indicate what action to
    // take on the state, but in this case there's only one action.
    return useReducer((state) => !state, initialValue);
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
