import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../champs/constants/api_routes';

export interface IDeleteUserSuccessResponse {
    data: string;
}

export interface IDeleteUserTypeState {
    data: IDeleteUserSuccessResponse | null;
    error: string | null | unknown;
    loading: boolean;
}

export const initialState: IDeleteUserTypeState = {
    data: null,
    loading: false,
    error: null,
};

export const deleteUser = createAsyncThunk<
    IDeleteUserSuccessResponse,
    unknown,
    { rejectValue: string }
>('delete/user', async (userId, { rejectWithValue }) => {
    try {
        const response = await axios.delete<IDeleteUserSuccessResponse>(
            `${BACKEND_ROUTES.USERS}/${userId}`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('api_token')}`,
                },
            },
        );
        return response.data;
    } catch (e) {
        const error = e as AxiosError<string>;
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error.message);
    }
});

export const slice = createSlice({
    name: 'deleteUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(deleteUser.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteUser.rejected, (state, { payload }) => {
            if (payload) {
                state.data = null;
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export default slice.reducer;
