import {
    ElementType,
    ResponseType,
    SectionName,
    SegmentName,
    UserResponse,
    Response,
    SpecialSegments,
} from '../types/segment';

export const getQuestionUID = (
    section: SectionName,
    segment: SegmentName,
    elementType: ElementType,
    activeQuestion: number,
) => {
    return `${section}_${segment}_${elementType}_question${activeQuestion}`;
};

export const getElementTypeFromQuestionUid = (questionUid: string) => {
    return questionUid.split('_')[2];
};

export const userResponseCustomInput = (userResponses: UserResponse[]) => {
    const userResponseWithInput = userResponses.find((response) => response.input);
    const input = userResponseWithInput?.input ?? null;
    return input;
};

export const getExistingCustomInput = (responseOrder: number, userResponses: UserResponse[]) => {
    const userResponseWithInput = userResponses.find(
        (response) => response.order === responseOrder && response.input,
    );
    const input = userResponseWithInput?.input ?? null;
    return input;
};

export const getExistingCustomInputFromMultipleQuestions = (
    responseOrder: number,
    userResponses: UserResponse[],
    questionNumber: number,
) => {
    const userResponseWithInput = userResponses.find(
        (response) =>
            response.order === responseOrder &&
            response.input &&
            questionNumber === response.questionNumber,
    );
    const input = userResponseWithInput?.input ?? null;
    return input;
};

export const isResponseTypeWithCustomInput = (response: Response) => {
    return Boolean(
        response.type === ResponseType.INPUT || response.type === ResponseType.KEY_INPUT,
    );
};

export const isResponseTypeInput = (response: Response) => {
    return Boolean(response.type === ResponseType.INPUT);
};

export const isResponseTypeKeyInput = (response: Response) => {
    return Boolean(response.type === ResponseType.KEY_INPUT);
};

export const isOneQuestionQuiz = (element: ElementType) => {
    return Boolean(
        element === ElementType.QUIZ ||
            element === ElementType.MODIFIED_QUIZ ||
            element === ElementType.PRE_QUIZ ||
            element === ElementType.OBJECTIVE_REVIEW_INTRO ||
            element === ElementType.UPDATE_ANSWERS_QUIZ ||
            element === ElementType.WHAT_IF ||
            element === ElementType.PATH_CHOICE ||
            element === ElementType.PATH_CHOICE_2 ||
            element === ElementType.FIXED_NUMBER_ANSWERS_QUIZ,
    );
};

export const isMultiQuestionQuiz = (element: ElementType) => {
    return Boolean(
        element === ElementType.SCORED_QUIZ ||
            element === ElementType.PSYCHOTIC_SYMPTOMS_QUIZ ||
            element === ElementType.SCENARIOS_INTRO ||
            element === ElementType.SCENARIOS_CARDS ||
            element === ElementType.SEVRAGES_SYMPTOMES ||
            element === ElementType.PATH_MULTI_Q_QUIZ ||
            element === ElementType.THUMBS_UP_DOWN_QUIZ ||
            element === ElementType.A_OR_B_QUIZ_CATEGORIES ||
            element === ElementType.A_OR_B_QUIZ ||
            element === ElementType.FORMS_TO_FILL ||
            element === ElementType.EMOJI_QUIZ ||
            element === ElementType.YES_NO_QUIZ,
    );
};
export const isMultiQuestionTrueFalseQuiz = (element: ElementType) => {
    return element === ElementType.THUMBS_UP_DOWN_QUIZ;
};
export const isRace = (element: ElementType) => {
    return element === ElementType.PATH_RACE;
};
export const isEmojiQuiz = (element: ElementType) => {
    return element === ElementType.EMOJI_QUIZ;
};
export const isDependencyScore = (element: ElementType) => {
    return element === ElementType.DEPENDENCY_SCORE;
};
export const isModuleFinalQuiz = (element: ElementType, segmentName: SegmentName) => {
    return element === ElementType.QUIZ && segmentName === SpecialSegments.FINAL_MODULE;
};

export const hasSelectedOrder = (element: ElementType, userResponses: UserResponse[]) => {
    if (
        element === ElementType.FIXED_NUMBER_ANSWERS_QUIZ &&
        !userResponses.some(
            (userResponse) =>
                userResponse.selectedOrder === null || userResponse.selectedOrder === undefined,
        )
    ) {
        return true;
    } else {
        return false;
    }
};
