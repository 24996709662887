import React from 'react';

import { LoadingDots } from '@osedea/reactor';

import { StatusCardContainer, IconContainer, CardNumber, CardText } from './styles';

import Icon, { IconType } from '../Icon';

export interface IProps {
    icon: IconType;
    number: number | undefined;
    title: string;
    isLoading: boolean;
}

const StatusCard: React.FC<IProps> = (props: IProps) => {
    const { icon, number, title, isLoading } = props;

    return (
        <StatusCardContainer>
            {isLoading ? (
                <LoadingDots />
            ) : (
                <>
                    <IconContainer>
                        <Icon icon={icon} />
                    </IconContainer>
                    <div>
                        <CardNumber>{number}</CardNumber>
                        <CardText>{title}</CardText>
                    </div>
                </>
            )}
        </StatusCardContainer>
    );
};

export default StatusCard;
