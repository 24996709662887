import React from 'react';
import { Switch } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import { Dashboard } from '../pages/Dashboard';
import { IntervenantDetail } from '../pages/IntervenantDetail';
import { ParticipantDetail } from '../pages/ParticipantDetail';
import PrivateRoute from '../components/PrivateRoute';

interface IProps {}

const ChampsApp: React.FC<IProps> = (props) => {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.CHAMPS.DASHBOARD} Component={Dashboard} />
            <PrivateRoute path={ROUTES.CHAMPS.INTERVENANT} Component={IntervenantDetail} />
            <PrivateRoute path={ROUTES.CHAMPS.PARTICIPANT} Component={ParticipantDetail} />
        </Switch>
    );
};

export default ChampsApp;
