import styled from 'styled-components';

import { colours } from '../../../constants/colours';

export const Seperator = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    border: solid 2px ${colours.black};
    border-radius: 5px;
    width: 100%;
    height: 1px;
`;
export const QAContainer = styled.div`
    margin-bottom: 24px;
`;

export const TitleBlock = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
    text-decoration: underline;
`;

export const SubTitleBlock = styled.div`
    line-height: 24px;
    margin-bottom: 24px;
`;
