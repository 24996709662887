import React, { useState, FormEvent, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';
import { Dialog } from '@material-ui/core';
import { Button } from '@osedea/reactor';

import Icon from '../Icon';
import { ManageIntervenantTable } from '../ManageIntervenantTable';
import { getIntervenants } from '../../slices/intervenantsSlice';
import {
    manageParticipantRelationships,
    ParticipantDetailsType,
    ParticipantIntervenantType,
} from '../../slices/participantDetailsSlice';
import { RootState } from '../../store';

import { IntervenantsSelectionObject } from '../AddUserModal';
import { CloseButton, IntervenantTableContainer, Title } from '../AddUserModal/styles';
import { ButtonStyles } from '../../styles/common';
import { TableTitle } from './styles';

export interface IProps {
    open: boolean;
    onClose: () => void;
    participant: ParticipantDetailsType;
}
interface SimplifiedIntervenant {
    intervenantId: number;
    order: number;
}
export const AssignIntervenantModal: React.FC<IProps> = ({ open, onClose, participant }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [sortingOptions, setSortingOptions] = useState<SortOptions>({
        sortBy: 'id',
        sortOrder: 'ASC',
    });

    const intervenants = useSelector((state: RootState) => state.intervenants.intervenants);
    const [intervenantsSelection, setIntervenantsSelection] = useState<
        IntervenantsSelectionObject[] | []
    >([]);

    const participantIntervenants = participant.intervenants;

    const intervenantsSelectionObject = useCallback(() => {
        let existingIntervenantsSelection: SimplifiedIntervenant[] | [] = [];
        if (participantIntervenants.length) {
            existingIntervenantsSelection = (participantIntervenants as ParticipantIntervenantType[]).map(
                (intervenant) => {
                    const intervenantId = intervenant.id;
                    const order = intervenant.order;
                    return { intervenantId, order };
                },
            );
        }
        setIntervenantsSelection(existingIntervenantsSelection);
    }, [participantIntervenants]);
    const resetState = () => {
        setIntervenantsSelection([]);
    };
    useEffect(() => {
        intervenantsSelectionObject();
    }, [participant, intervenantsSelectionObject]);

    // Dispatches the selection to the backend and clean the state
    const manageIntervenantsHandler = (e: FormEvent) => {
        e.preventDefault();
        dispatch(
            manageParticipantRelationships({
                participantId: participant.id,
                intervenantsSelection,
            }),
        );
        setTimeout(function () {
            dispatch(
                getIntervenants({
                    sortBy: 'id',
                    sortOrder: 'DESC',
                }),
            );
        }, 3000);
        resetState();
        onClose();
    };

    // Clean state before closing the modal
    const handleClose = () => {
        resetState();
        onClose();
    };

    const handleOnSelect = (intervenantId: number) => {
        const intervenantsSelectionCopy = [...intervenantsSelection];

        if (
            intervenantsSelectionCopy.some(
                (intervenantsSelectionObject) =>
                    intervenantsSelectionObject.intervenantId === intervenantId,
            )
        ) {
            const intervenantsSelectionFilter = intervenantsSelectionCopy.filter(
                (intervenantsSelectionObject) =>
                    intervenantsSelectionObject.intervenantId !== intervenantId,
            );
            intervenantsSelectionFilter.forEach((object, index) => (object.order = index + 1));
            setIntervenantsSelection(intervenantsSelectionFilter);
        } else {
            intervenantsSelectionCopy.push({
                intervenantId,
                order: intervenantsSelectionCopy.length + 1,
            });
            setIntervenantsSelection(intervenantsSelectionCopy);
        }
    };

    return (
        <Dialog open={open} maxWidth={false}>
            <div style={{ margin: '2rem 5rem', display: 'flex', flexDirection: 'column' }}>
                <CloseButton role="button" tabIndex={0} onClick={handleClose}>
                    <Icon icon={'closeModal'} />
                </CloseButton>
                <Title>{`${participant.firstName} ${participant.lastName}`}</Title>
                <IntervenantTableContainer>
                    <TableTitle>{t('components.AddUserLegacy.intervenantsList')}</TableTitle>
                    <ManageIntervenantTable
                        intervenants={intervenants}
                        onChangeSort={setSortingOptions}
                        sorting={sortingOptions}
                        onSelect={handleOnSelect}
                        intervenantsSelection={intervenantsSelection}
                    />
                </IntervenantTableContainer>
                <Button
                    onClick={manageIntervenantsHandler}
                    style={{
                        ...ButtonStyles,
                        margin: '0 auto',
                    }}
                >
                    {t('components.AddUserLegacy.submit')}
                </Button>
            </div>
        </Dialog>
    );
};
