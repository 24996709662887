import styled from 'styled-components';

import { colours } from 'constants/colours';
import { dropShadow } from 'styles/common';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 3rem 3rem;
    background-color: ${colours.global.lightGrey};
`;

export const TableContainer = styled.div`
    margin: 3rem 0;
    width: 100%;
`;

export const ProfileContainer = styled.div`
    background-color: ${colours.white};
    box-shadow: ${dropShadow};
    border-radius: 10px;
    width: 100%;
    margin-top: 35px;
    padding: 35px 35px;
`;

export const ProfileTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
`;

export const TableTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
    flex: 1;
    margin: 2rem 0;
`;
