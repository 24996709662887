import { Table } from '@osedea/reactor';
import { Header } from '@osedea/reactor/dist/compounds/table/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectedIntervenant } from 'types/users';
import SelectableIntervenantButton from '../SelectableIntervenantButton';

interface IProps {
    intervenants: SelectedIntervenant[];
}

const SelectedIntervenantsTable: React.FC<IProps> = ({ intervenants }) => {
    const { t } = useTranslation();

    const headers: Header[] = [
        {
            id: 'firstName',
            label: t('global.firstName'),
        },
        {
            id: 'lastName',
            label: t('global.lastName'),
        },
        {
            id: 'participantsCount',
            label: '# Participants',
        },
        {
            id: 'controls',
            label: '',
        },
    ];

    const rows = intervenants.map((intervenant) => {
        return {
            id: intervenant.id,
            firstName: intervenant.firstName,
            lastName: intervenant.lastName,
            participantsCount: `${intervenant.participantsCount}`,
            controls: <SelectableIntervenantButton intervenant={intervenant} disabled={true} />,
        };
    });

    return (
        <>
            <Table headers={headers} rows={rows} emptyCellValue={'N/A'} />
        </>
    );
};

export default SelectedIntervenantsTable;
