import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@osedea/reactor';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';

import { ParticipantDetailsContext } from 'icc/constants/context';

interface IProps {}

export const IntervenantWithOrderTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    const participant = useContext(ParticipantDetailsContext);

    const headers: Header[] = [
        {
            id: 'order',
            label: t('global.order'),
        },
        {
            id: 'firstName',
            label: t('global.firstName'),
        },
        {
            id: 'lastName',
            label: t('global.lastName'),
        },
        {
            id: 'email',
            label: t('global.email'),
        },
        {
            id: 'phone',
            label: t('global.phone'),
        },
    ];

    const rows: Row[] = useMemo(() => {
        return (
            participant?.intervenants.map((participant) => {
                return {
                    id: participant.id,
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                    order: participant.order,
                    phone: participant.phone ?? '',
                };
            }) ?? []
        );
    }, [participant]);

    return <Table headers={headers} rows={rows} emptyCellValue={'N/A'} />;
};
