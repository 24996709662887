import styled from 'styled-components';

import { colours } from 'constants/colours';
import { dropShadow } from 'styles/common';

export const ProfileContainer = styled.div`
    background-color: ${colours.white};
    box-shadow: ${dropShadow};
    border-radius: 10px;
    width: 100%;
    margin-top: 35px;
    padding: 35px 35px;
`;

export const ProfileFirstBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ProfileTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
    flex: 1;
`;

export const ProfileDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;
