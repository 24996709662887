import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const CloseButton = styled.div`
    cursor: pointer;
    z-index: 1;
`;

export const Title = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    flex: 1;
    transform: translate(23px, 0px);
    z-index: 0;
`;

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 21px;
    background-color: ${colours.feedbackBarBlue};
    margin: 15px;
    border: solid 2px ${colours.black};
    border-radius: 5px;
`;

export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const QuestionsContainer = styled.div`
    padding: 21px 0;
`;

export const Questions = styled.li`
    padding: 5px 0;
`;
