import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { IUser } from 'types/users';

import { BACKEND_ROUTES } from 'user_management/constants/api_routes';
import { fetchUsers } from './usersSlice';

export interface IUpdateUsersResponse {
    user: IUser;
}
export interface IApiError {
    message: string | null;
}

export interface IUsersState {
    user: IUser | null;
    loading: boolean;
    errors: string | null;
}

const initialState: IUsersState = {
    user: null,
    loading: false,
    errors: null,
};

export const editUser = createAsyncThunk(
    'user_management/editUser',
    async (params: { userId: number; updatedFields: { [key: string]: any } }, { dispatch }) => {
        dispatch(setLoadingAction());

        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('api_token')}`,
            },
        };

        const body = { userId: params.userId, updatedFields: params.updatedFields };

        await axios
            .put<IUpdateUsersResponse>(BACKEND_ROUTES.USERS, body, headers)
            .then((response) => {
                dispatch(setUpdateUsersAction(response.data));
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                dispatch(fetchUsers());
            })
            .catch((error) => {
                let message: string | null = null;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.isAxiosError) {
                    message = (error as AxiosError<IApiError>).response?.data.message ?? null;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    message = error.message;
                }

                dispatch(setErrorsAction(message));
            });
    },
);

export const resetEditUser = createAsyncThunk(
    'user_management/resetEditUser',
    (_, { dispatch }) => {
        dispatch(reset());
    },
);

const slice = createSlice({
    name: 'user_management/editUser',
    initialState,
    reducers: {
        setUpdateUsersAction(state, action: PayloadAction<IUpdateUsersResponse>) {
            state.user = action.payload.user;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<string | null>) {
            state.user = null;
            state.loading = false;
            state.errors = action.payload;
        },
        setLoadingAction(state) {
            state.loading = true;
        },
        reset(state) {
            state.user = initialState.user;
            state.loading = initialState.loading;
            state.errors = initialState.errors;
        },
    },
});

const { setUpdateUsersAction, setErrorsAction, setLoadingAction, reset } = slice.actions;

export default slice.reducer;
