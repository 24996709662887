import React from 'react';

import {
    IModalContainerProps,
    CenteredModalContainer,
    ModalContent,
    DummyContainer,
} from '../../../styles/common';

interface IProps {
    isVisible?: boolean;
    dismissCallback?: () => void;
}

export const GenericModal: React.FC<IProps & IModalContainerProps> = ({
    children,
    isVisible = false,
    dismissCallback,
}) => {
    const handleClick = () => {
        if (dismissCallback) {
            dismissCallback();
        }
    };

    const handleChildClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return isVisible ? (
        <CenteredModalContainer onClick={handleClick}>
            <DummyContainer onClick={(e: React.MouseEvent) => handleChildClick(e)}>
                <ModalContent>{children}</ModalContent>
            </DummyContainer>
        </CenteredModalContainer>
    ) : null;
};
