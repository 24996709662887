import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

import { colours } from '../../constants/colours';

export const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor: colours.global.lightGrey,
});
export const Title = styled.h2``;

export const Subtitle = styled.h4``;

export const UnderlineSubtitle = styled.h4`
    text-decoration: underline;
`;
export const BoldText = styled.span`
    font-weight: 500;
`;

export const IndentedContainer = styled.div`
    margin: 0 48px;
`;

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 6rem;
    margin: 3rem 12rem;
    min-width: 80%;
`;

export const PolicyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 22rem;
`;

interface LogoProps {
    alt: string;
    src: string;
}

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 30px;
    margin-top: 30px;
    object-fit: cover;
    src: (props: LogoProps) => props.src;
    alt: (props: LogoProps) => props.alt;
`;
export const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const AppLogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
