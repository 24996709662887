import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@osedea/reactor';

import { Breadcrumbs, Link } from '@material-ui/core';

import { Container, ProfileContainer, TableTitle } from './styles';
import { getAParticipant } from 'icc/slices/participantSlice';
import { ParticipantProfile } from 'icc/components/ParticipantProfile';
import { RootState } from 'store';
import { ParticipantDetailsContext } from 'icc/constants/context';
import { ICCParticipantSegmentsCard } from '../../components/ICCParticipantSegmentsCard';
import ROLES from '../../../constants/roles';
import { ParticipantWeeklyJournals } from 'icc/components/ParticipantWeeklyJournals';
import { authenticationService } from 'helpers/authenticationService';
import { CollapsibleSection } from 'icc/components/CollapsibleSection';
import { IntervenantWithOrderTable } from 'icc/components/IntervenantWithOrderTable';
import { AssignIntervenantsDialog } from 'icc/components/AssignIntervenantsDialog';

interface IProps {}
interface NavState {
    participantId: number;
}

export const ParticipantDetailsPage: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<NavState>();
    const participant = useSelector((state: RootState) => state.iccApp.participant.participant);

    const userIsSuperAdminOrAdmin = [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(
        authenticationService.currentUserValue?.role ?? '',
    );
    const [isAssignIntervenantDialogOpen, setIsAssignIntervenantDialogOpen] = React.useState(false);

    const shareData = useMemo(() => {
        if (participant) {
            return userIsSuperAdminOrAdmin || participant.shareSensitiveData;
        }
        return false;
    }, [participant, userIsSuperAdminOrAdmin]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!location.state.participantId) {
            history.goBack();
        } else {
            dispatch(getAParticipant({ participantId: location.state.participantId }));
        }
    }, [dispatch, history, location]);

    const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.goBack();
    };

    const sectionOne = useMemo(() => {
        if (participant?.sections) {
            return (
                <>
                    {participant.sections.sectionOne.segments.map((segment) => {
                        return (
                            <ProfileContainer key={segment.name}>
                                <ICCParticipantSegmentsCard
                                    name={segment.name}
                                    routeSwitch={segment.path ?? null}
                                    startedAt={segment?.time?.startTime ?? null}
                                    completedAt={segment.completedAt}
                                    totalTime={segment?.time?.totalTime ?? 0}
                                    segmentRecap={segment.userElements}
                                    key={segment.name}
                                    shareData={shareData}
                                    version={segment.version}
                                    previousVersions={segment?.previousVersions ?? null}
                                />
                            </ProfileContainer>
                        );
                    })}
                </>
            );
        }
        return null;
    }, [participant, shareData]);

    const sectionTwo = useMemo(() => {
        if (participant?.sections) {
            return (
                <>
                    {participant.sections.sectionTwo.segments.map((segment) => {
                        return (
                            <ProfileContainer key={segment.name}>
                                <ICCParticipantSegmentsCard
                                    name={segment.name}
                                    routeSwitch={segment.path ?? null}
                                    startedAt={segment?.time?.startTime ?? null}
                                    completedAt={segment.completedAt}
                                    totalTime={segment?.time?.totalTime ?? 0}
                                    segmentRecap={segment.userElements}
                                    key={segment.name}
                                    shareData={shareData}
                                    version={segment.version}
                                    previousVersions={segment?.previousVersions ?? null}
                                />
                            </ProfileContainer>
                        );
                    })}
                </>
            );
        }
        return null;
    }, [participant, shareData]);

    const sectionModuleFinale = useMemo(() => {
        if (participant?.sections) {
            return (
                <>
                    {participant.sections.finalModule.segments.map((segment) => {
                        return (
                            <ProfileContainer key={segment.name}>
                                <ICCParticipantSegmentsCard
                                    name={segment.name}
                                    routeSwitch={segment.path ?? null}
                                    startedAt={segment?.time?.startTime ?? null}
                                    completedAt={segment.completedAt}
                                    totalTime={segment?.time?.totalTime ?? 0}
                                    segmentRecap={segment.userElements}
                                    key={segment.name}
                                    shareData={shareData}
                                    version={segment.version}
                                    previousVersions={segment?.previousVersions ?? null}
                                />
                            </ProfileContainer>
                        );
                    })}
                </>
            );
        }
        return null;
    }, [participant, shareData]);

    const sectionObjectiveReview = useMemo(() => {
        if (participant?.sections) {
            return (
                <>
                    {participant.sections.objectiveReview.segments.map((segment) => {
                        return (
                            <ProfileContainer key={segment.name}>
                                <ICCParticipantSegmentsCard
                                    name={segment.name}
                                    routeSwitch={segment.path ?? null}
                                    startedAt={segment?.time?.startTime ?? null}
                                    completedAt={segment.completedAt}
                                    totalTime={segment?.time?.totalTime ?? 0}
                                    segmentRecap={segment.userElements}
                                    key={segment.name}
                                    shareData={shareData}
                                    version={segment.version}
                                    previousVersions={segment?.previousVersions ?? null}
                                />
                            </ProfileContainer>
                        );
                    })}
                </>
            );
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant]);
    const booster = useMemo(() => {
        if (participant?.sections) {
            return (
                <>
                    {participant.sections.booster.segments.map((segment) => {
                        return (
                            <ProfileContainer key={segment.name}>
                                <ICCParticipantSegmentsCard
                                    name={segment.name}
                                    routeSwitch={segment.path ?? null}
                                    startedAt={segment?.time?.startTime ?? null}
                                    completedAt={segment.completedAt}
                                    totalTime={segment?.time?.totalTime ?? 0}
                                    segmentRecap={segment.userElements}
                                    key={segment.name}
                                    shareData={shareData}
                                    version={segment.version}
                                    previousVersions={segment?.previousVersions ?? null}
                                />
                            </ProfileContainer>
                        );
                    })}
                </>
            );
        }
        return null;
    }, [participant, shareData]);

    const ManageIntervenantDialog = React.useMemo(() => {
        return (
            <AssignIntervenantsDialog
                open={isAssignIntervenantDialogOpen}
                handleClose={() => setIsAssignIntervenantDialogOpen(false)}
                participantIntervenants={participant?.intervenants}
            />
        );
    }, [participant, isAssignIntervenantDialogOpen]);

    const capitalize = (string: string) => {
        const raw = string.toLowerCase();
        return raw[0].toUpperCase() + raw.slice(1);
    };

    return (
        <ParticipantDetailsContext.Provider value={participant}>
            <Container>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" onClick={handleNavClick}>
                        {'\u2190 '}
                        {t('global.back')}
                    </Link>
                </Breadcrumbs>
                <ParticipantProfile>
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: '2rem',
                                alignItems: 'center',
                            }}
                        >
                            <TableTitle>
                                {t('global.roleToRoleTableTitle', {
                                    role1: capitalize(t(`Roles.${ROLES.INTERVENANT}`)),
                                    role2: t(`Roles.${ROLES.PARTICIPANT}`),
                                })}
                            </TableTitle>

                            {userIsSuperAdminOrAdmin && (
                                <Button
                                    onClick={() => setIsAssignIntervenantDialogOpen(true)}
                                    style={{ maxHeight: '3rem' }}
                                >
                                    {t('pages.UserDetail.manageRelationship', {
                                        role: t(`Roles.${ROLES.INTERVENANT}`),
                                    })}
                                </Button>
                            )}
                        </div>
                        <IntervenantWithOrderTable />
                    </>
                </ParticipantProfile>
                <CollapsibleSection title={t('pages.ICC.ParticipantProfile.modules')}>
                    {sectionOne}
                </CollapsibleSection>
                <CollapsibleSection title={t('pages.ICC.ParticipantProfile.strategies')}>
                    {sectionTwo}
                </CollapsibleSection>
                <CollapsibleSection title={t('pages.ICC.ParticipantProfile.objective')}>
                    {sectionObjectiveReview}
                </CollapsibleSection>
                <CollapsibleSection title={t('pages.ICC.ParticipantProfile.moduleFinale')}>
                    {sectionModuleFinale}
                </CollapsibleSection>
                <CollapsibleSection title={t('pages.ICC.ParticipantProfile.booster')}>
                    {booster}
                </CollapsibleSection>
                <ParticipantWeeklyJournals shareData={shareData} />
            </Container>
            {ManageIntervenantDialog}
        </ParticipantDetailsContext.Provider>
    );
};

// ---------------------------------
// REQUIREMENTS:

// Case Manager

// Notification status -Yes
// Date of start (first module)
// Number of completed modules
// Access to the objectives (full access to the objectives) ???
// Access to strategies (memory cards and summary of the modules) ???
// Number of journal entries BUT not the content (frequency, quantity cost)

// Recaps for modules including answers

// Admin

// Super admin (data generated by Akufen from the application):
// All responses provided by participants in each module.
// If a module is completed more than once :
//      The last information should be stored.
//      Date when module / strategy was started, completed and time needed to complete
// All information from the profile ???
// Date and time the profile was accessed **(NOT DOING)**
// Date and time the menu “First aide” was accessed and what components **(NOT DOING)**
// All information from the journal (quantity; frequency and cost)
// Date when a module or strategy was accessed (but not completed) ???
// Number of attempts to complete a module
// Date when a module or strategy was completed
// Time needed to complete a module
// Date and time of booster completion.

// ---------------------------------

// ---------------------------------
// Top Profile Card:
// ---------------------------------
//      Both:
//          - Basic Info (Name, email, phone, etc..)
//          - Notifications
//          - Start Date
//          - Completed Modules
//      Admin:
//          ??

// ---------------------------------
// Journal Section:
// ---------------------------------
//       Both:
//          - Number of journal entries
//       Admin:
//          - All information from the journal (quantity; frequency and cost)

// ---------------------------------
// Module/Strategies/Boosters Section:
// ---------------------------------
//  Both:
//      - Display latest info.
//      - Summary of segment
//  Admin:
//      - Date when module / strategy was started, completed and time needed to complete.
//      - Number of attempts to complete a module
//      - Date when a module or strategy was completed
//      - Time needed to complete a module
//      - Date and time of booster completion.
