import React, { useState, FormEvent, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@osedea/reactor';

import Icon from '../Icon';
import { ManageParticipantTable } from '../ManageParticipantTable';
import { getIntervenants } from '../../slices/intervenantsSlice';
import {
    manageIntervenantRelationships,
    IntervenantDetailsType,
} from '../../slices/intervenantDetailsSlice';
import { RootState } from '../../store';

import { ParticipantType } from '../../slices/participantsSlice';
import { CloseButton, IntervenantTableContainer, Title } from '../AddUserModal/styles';
import { ButtonStyles } from '../../styles/common';
import { TableTitle } from './styles';
import { AlternateModal } from '../Common/AlternateModal';

export interface IProps {
    show: boolean;
    onClose: () => void;
    intervenant: IntervenantDetailsType;
}
export interface ParticipantsSelectionObject {
    participantId: number;
    selected: boolean;
}

export const AssignParticipantModal: React.FC<IProps> = ({ show, onClose, intervenant }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const participants = useSelector((state: RootState) => state.participants.participants);
    const [participantsUnassigned, setParticipantsUnassigned] = useState<
        ParticipantsSelectionObject[] | []
    >([]);
    const [participantsSelection, setParticipantsSelection] = useState<
        ParticipantsSelectionObject[] | []
    >([]);

    const intervenantParticipants = intervenant.participants;

    const participantsSelectionObject = useCallback(() => {
        let existingParticipantsSelection: ParticipantsSelectionObject[] | [] = [];
        if (intervenantParticipants.length >= 1) {
            existingParticipantsSelection = (intervenantParticipants as ParticipantType[]).map(
                (participant) => {
                    const participantId = participant.id;
                    return { participantId, selected: true };
                },
            );
        }
        setParticipantsSelection(existingParticipantsSelection);
    }, [intervenantParticipants]);

    const participantsUnnassignedObject = useCallback(() => {
        let unassignedParticipantsSelection: ParticipantsSelectionObject[] | [] = [];
        const unassignedParticipants = participants.filter(
            (participant) => !participant.intervenant,
        );
        if (unassignedParticipants.length >= 1) {
            unassignedParticipantsSelection = (unassignedParticipants as ParticipantType[]).map(
                (participant) => {
                    const participantId = participant.id;
                    return { participantId, selected: false };
                },
            );
        }

        setParticipantsUnassigned(unassignedParticipantsSelection);
    }, [participants]);

    const resetState = () => {
        setParticipantsSelection([]);
        setParticipantsUnassigned([]);
    };
    useEffect(() => {
        participantsSelectionObject();
        participantsUnnassignedObject();
    }, [intervenant, participantsSelectionObject, participantsUnnassignedObject]);

    // Dispatches the selection to the backend and clean the state
    const manageIntervenantsHandler = (e: FormEvent) => {
        e.preventDefault();
        dispatch(
            manageIntervenantRelationships({
                intervenantId: intervenant.id,
                participantsSelection,
            }),
        );
        setTimeout(function () {
            dispatch(
                getIntervenants({
                    sortBy: 'id',
                    sortOrder: 'DESC',
                }),
            );
        }, 3000);
        resetState();
        onClose();
    };

    // Clean state before closing the modal
    const handleClose = () => {
        resetState();
        onClose();
    };

    const handleOnSelect = (participantId: number) => {
        const participantsSelectionCopy = [...participantsSelection];

        if (
            participantsSelectionCopy.some(
                (participantsSelectionObject) =>
                    participantsSelectionObject.participantId === participantId,
            )
        ) {
            const participantsSelectionFilter = participantsSelectionCopy.filter(
                (participantsSelectionObject) =>
                    participantsSelectionObject.participantId !== participantId,
            );
            participantsSelectionFilter.forEach((object, index) => (object.selected = true));
            setParticipantsSelection(participantsSelectionFilter);
        } else {
            participantsSelectionCopy.push({
                participantId,
                selected: true,
            });
            setParticipantsSelection(participantsSelectionCopy);
        }
    };

    return (
        <AlternateModal show={show}>
            <CloseButton role="button" tabIndex={0} onClick={handleClose}>
                <Icon icon={'closeModal'} />
            </CloseButton>
            <Title>{`${intervenant.firstName} ${intervenant.lastName}`}</Title>
            <IntervenantTableContainer>
                <TableTitle>{t('components.AddUserLegacy.intervenantsList')}</TableTitle>
                <ManageParticipantTable
                    participants={participants}
                    unassignedParticipants={participantsUnassigned}
                    sorting={{
                        sortBy: 'selected',
                        sortOrder: 'ASC',
                    }}
                    onSelect={handleOnSelect}
                    participantsSelection={participantsSelection}
                />
            </IntervenantTableContainer>
            <Button
                onClick={manageIntervenantsHandler}
                style={{
                    ...ButtonStyles,
                    margin: '0 auto',
                }}
            >
                {t('components.AddUserLegacy.submit')}
            </Button>
        </AlternateModal>
    );
};
