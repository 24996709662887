import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Table } from '@osedea/reactor';
import { Header, Row } from '@osedea/reactor/dist/compounds/table/types';

import { RootState } from 'store';
import { getParticipants } from 'icc/slices/participantsSlice';
import { ROUTES } from 'constants/routes';
import { Participant } from 'icc/types/user';
import { authenticationService } from 'helpers/authenticationService';
import { ERoleType } from 'types';

interface IProps {}

export const ParticipantTable: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getParticipants());
    }, [dispatch]);

    const authUser = authenticationService.currentUserValue;

    const participants = useSelector((state: RootState) => state.iccApp.participants.participants);

    const headers: Header[] = React.useMemo(() => {
        if (authUser?.role === ERoleType.SUPER_ADMIN) {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                },
                {
                    id: 'adminName',
                    label: t('global.admin'),
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        } else {
            return [
                {
                    id: 'name',
                    label: t('global.name'),
                },
                {
                    id: 'email',
                    label: t('global.email'),
                },
                {
                    id: 'phone',
                    label: t('global.phone'),
                },
                {
                    label: '',
                    id: 'controls',
                },
            ];
        }
    }, [t, authUser]);

    const navigateToUserDetails = useCallback(
        (user: Participant) => {
            history.push(ROUTES.ICC.PARTICIPANT_DETAILS, {
                participantId: user.id,
            });
        },
        [history],
    );

    const rows: Row[] = useMemo(() => {
        return participants.map((participant) => {
            return {
                id: participant.id,
                name: `${participant.firstName} ${participant.lastName}`,
                adminName: `${participant.admin?.firstName} ${participant.admin?.lastName}`,
                email: participant.email,
                phone: participant.phone ?? '',
                controls: (
                    <span>
                        <Button onClick={() => navigateToUserDetails(participant)}>
                            {t('components.ParticipantTable.detail')}
                        </Button>
                    </span>
                ),
            };
        });
    }, [participants, navigateToUserDetails, t]);

    return <Table headers={headers} rows={rows} emptyCellValue={'N/A'} />;
};
